import React from 'react';
import '../../assets/css/form.css';
import { Button, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProfilePage = () => {
  const Navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.user);

  console.log('user details at profile component', userInfo);

  const EditProfile = (id) => {
    Navigate(`/dashboard/editprofile?id=${id}`);
  };

  return (
    <>
      <section className="profileparent">
        <div className="container py-2 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-lg-6 mb-4 mb-lg-0">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className=" editparent gradient-custom ">
                    {userInfo.role.role === 'rcu-manager' ? null : (
                      <Button className="editbuttonChild">
                        <Tooltip title="Ediit Profile">
                          <EditIcon onClick={() => EditProfile(userInfo._id)} />
                        </Tooltip>
                      </Button>
                    )}

                    <img
                      src={userInfo.userimage?.url}
                      alt="Avatar"
                      className="img-fluid my-3"
                      style={{ height: '120px', width: '120px', borderRadius: '50%' }}
                    />
                    <h5>
                      {' '}
                      {userInfo.name
                        ? userInfo.name
                          .split(' ')
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')
                        : ''}
                    </h5>
                  </div>

                  {userInfo.role.role === 'rcu-manager' ? (
                    <div className="col-md-12 ">
                      <div className="row">
                        <div className="col-6 ">
                          <div className="card-body p-4">
                            <h6>Information</h6>
                            <hr className="mt-0 mb-4" />

                            <h6>Name</h6>
                            <p className="text-muted">
                              {userInfo.name && userInfo.name.charAt(0).toUpperCase() + userInfo.name.slice(1)}
                            </p>
                            <hr />

                            <h6>Role</h6>
                            <p className="text-muted">
                              {userInfo.role.role &&
                                userInfo.role.role.charAt(0).toUpperCase() + userInfo.role.role.slice(1)}
                            </p>

                            <hr />
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="card-body p-4">
                            <h6>Information</h6>
                            <hr className="mt-0 mb-4" />

                            <h6>Email</h6>
                            <p className="text-muted">
                              {userInfo.email && userInfo.email.charAt(0).toUpperCase() + userInfo.email.slice(1)}
                            </p>

                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12 ">
                      <div className="row">
                        <div className="col-6 ">
                          <div className="card-body p-4">
                            <h6>Information</h6>
                            <hr className="mt-0 mb-4" />

                            <h6>Name</h6>
                            <p className="text-muted">
                              {userInfo.name && userInfo.name.charAt(0).toUpperCase() + userInfo.name.slice(1)}
                            </p>
                            <hr />

                            <h6>Date Of Birth</h6>
                            <p className="text-muted">01/01/2000</p>
                            <hr className="mt-0 mb-4" />

                            <h6>Personal Number</h6>
                            <p className="text-muted">{userInfo.mobilenumber}</p>
                            <hr className="mt-0 mb-4" />

                            <h6>Father Name</h6>
                            <p className="text-muted">
                              {userInfo.fathername &&
                                userInfo.fathername.charAt(0).toUpperCase() + userInfo.fathername.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>Adhar Card Number</h6>
                            <p className="text-muted">{userInfo.adharnumber}</p>
                            <hr className="mt-0 mb-4" />

                            <h6>Qualification</h6>
                            <p className="text-muted">
                              {userInfo.qualification &&
                                userInfo.qualification.charAt(0).toUpperCase() + userInfo.qualification.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>Base Location</h6>
                            <p className="text-muted">
                              {userInfo.baselocation &&
                                userInfo.baselocation.charAt(0).toUpperCase() + userInfo.baselocation.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>State</h6>
                            <p className="text-muted">
                              {userInfo.state && userInfo.state.charAt(0).toUpperCase() + userInfo.state.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>City</h6>
                            <p className="text-muted">
                              {userInfo.city && userInfo.city.charAt(0).toUpperCase() + userInfo.city.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>District</h6>
                            <p className="text-muted">
                              {userInfo.district && userInfo.district.charAt(0).toUpperCase() + userInfo.district.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>Rate / Salary</h6>
                            <p className="text-muted">{userInfo.salary}</p>
                            <hr className="mt-0 mb-4" />

                            <h6>Name on PhonePe</h6>
                            <p className="text-muted">
                              {userInfo.phonePe_name && userInfo.phonePe_name.charAt(0).toUpperCase() + userInfo.phonePe_name.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>Name on GooglePe</h6>
                            <p className="text-muted">
                              {userInfo.googlePe_name && userInfo.googlePe_name.charAt(0).toUpperCase() + userInfo.googlePe_name.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>Any Legal Compliant against you</h6>
                            <p className="text-muted">
                              {userInfo.any_Legal_Compliant_against_you && userInfo.any_Legal_Compliant_against_you.charAt(0).toUpperCase() + userInfo.any_Legal_Compliant_against_you.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="card-body p-4">
                            <h6>Information</h6>
                            <hr className="mt-0 mb-4" />

                            <h6>Email</h6>
                            <p className="text-muted">
                              {userInfo.email && userInfo.email.charAt(0).toUpperCase() + userInfo.email.slice(1)}
                            </p>

                            <hr />
                            <h6>Role</h6>
                            <p className="text-muted">
                              {userInfo.role.role &&
                                userInfo.role.role.charAt(0).toUpperCase() + userInfo.role.role.slice(1)}
                            </p>

                            <hr />
                            <h6>Family Number</h6>
                            <p className="text-muted">{userInfo.familynumber}</p>

                            <hr className="mt-0 mb-4" />
                            <h6>Mother Name</h6>
                            <p className="text-muted">
                              {userInfo.mothername &&
                                userInfo.mothername.charAt(0).toUpperCase() + userInfo.mothername.slice(1)}
                            </p>

                            <hr className="mt-0 mb-4" />

                            <h6>Pan Card</h6>
                            <p className="text-muted">{userInfo.pancardnumber}</p>
                            <hr className="mt-0 mb-4" />

                            <h6>City</h6>
                            <p className="text-muted">
                              {userInfo.city && userInfo.city.charAt(0).toUpperCase() + userInfo.city.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>Current Address</h6>
                            <p className="text-muted">
                              {userInfo.address && userInfo.address.charAt(0).toUpperCase() + userInfo.address.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>Tehsil</h6>
                            <p className="text-muted">
                              {userInfo.tehsil && userInfo.tehsil.charAt(0).toUpperCase() + userInfo.tehsil.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>Village</h6>
                            <p className="text-muted">
                              {userInfo.village && userInfo.village.charAt(0).toUpperCase() + userInfo.village.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            {/* <h6>Current Address</h6>
                            <p className="text-muted">Dummy 
                              {userInfo.currentaddress && userInfo.currentaddress.charAt(0).toUpperCase() + userInfo.currentaddress.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" /> */}

                            <h6>Per Kms Rate</h6>
                            <p className="text-muted">{userInfo.Per_Kms_Rate}</p>
                            <hr className="mt-0 mb-4" />

                            <h6>Current agency</h6>
                            <p className="text-muted">
                              {userInfo.working_with_current_agency && userInfo.working_with_current_agency.charAt(0).toUpperCase() + userInfo.working_with_current_agency.slice(1)}
                            </p>
                            <hr className="mt-0 mb-4" />

                            <h6>PhonePe Number</h6>
                            <p className="text-muted">{userInfo.phonePe_number}</p>
                            <hr className="mt-0 mb-4" />

                            <h6>GooglePe Number</h6>
                            <p className="text-muted">{userInfo.googlePe_number}</p>
                            <hr className="mt-0 mb-4" />

                            <h6>Total Work Exprince</h6>
                            <p className="text-muted">{userInfo.workexperience}</p>
                            <hr className="mt-0 mb-4" />

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 ">
                    <div className="row">
                      <div className="col-6 ">
                        <div className="card-body p-4">

                          <h6>Account Holder Name</h6>
                          <p className="text-muted">
                            {userInfo.acoountholdername &&
                              userInfo.acoountholdername.charAt(0).toUpperCase() +
                              userInfo.acoountholdername.slice(1)}
                          </p>
                          <hr className="mt-0 mb-4" />

                          <h6>Bank Name </h6>
                          <p className="text-muted">
                            {userInfo.bankname &&
                              userInfo.bankname.charAt(0).toUpperCase() + userInfo.bankname.slice(1)}
                          </p>
                          <hr className="mt-0 mb-4" />

                        </div>
                      </div>
                      <div className="col-6 ">
                        <div className="card-body p-4">

                          <h6> Account Number</h6>
                          <p className="text-muted">{userInfo.accountnumber}</p>
                          <hr className="mt-0 mb-4" />

                          <h6>IFSC Code</h6>
                          <p className="text-muted">{userInfo.ifsccode}</p>
                          <hr className="mt-0 mb-4" />
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfilePage;
