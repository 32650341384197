import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Button } from '@mui/material';
import PropTypes from 'prop-types'; 
import Iconify from '../iconify';

function DynamicExcelUser({ data }) {
    const exportToExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('User List');

        if (data.length > 0) {
            const userFields = [
                'name',
                'email',
                'mobilenumber',
                'address',
                'city',
                'offerLetter',
                'userimage.url',
                'role',
                'Per_Kms_Rate',
                'accountnumber',
                'acoountholdername',
                'any_Legal_Compliant_against_you',
                'bankname',
                'baselocation',
                'district',
                'familynumber',
                'fathername',
                'googlePe_name',
                'googlePe_number',
                'ifsccode',
                'mothername',
                'pancardnumber',
                'phonePe_name',
                'phonePe_number',
                'qualification',
                'salary',
                'state',
                'tehsil',
                'village',
                'workexperience',
                'working_with_current_agency',
            ];
            const columnName = [
                'Name',
                'Email',
                'Mobile Number',
                'Address',
                'City',
                'Offer Letter',
                'Image',
                'Role',
                'Per Kms Rate',
                'Account Number',
                'Acoount Holder Name',
                'Any legal compliant against you',
                'Bankname',
                'Base location',
                'District',
                'Family Number',
                'Father Name',
                'GooglePe Name',
                'GooglePe Number',
                'IFSC Code',
                'Mother Name',
                'Pan Card Number',
                'PhonePe Name',
                'PhonePe Number',
                'Qualification',
                'Salary',
                'State',
                'Tehsil',
                'Village',
                'Work Experience',
                'Working With Current Agency',
            ];
            const boldFont = { bold: true };

            worksheet.addRow(columnName).eachCell((cell) => {
                cell.font = boldFont;
            });
            
            data.forEach((user) => {
                const row = userFields.map((field) => {
                    if (field === 'userimage.url') {
                        return user.userimage.url;
                    }
                    if (field === 'role') {
                        return user.role.role || '';
                    }
                    return user[field];
                });
                worksheet.addRow(row);
            });

            // Auto-size columns
            userFields.forEach((field, index) => {
                const column = worksheet.getColumn(index + 1);
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const cellLength = cell.value ? cell.value.toString().length : 0;
                    column.width = Math.min(30, Math.max(column.width || 0, cellLength + 2));
                });
            });

            // Generate and save the Excel file
            workbook.xlsx.writeBuffer().then((blob) => {
                saveAs(new Blob([blob]), 'user_list.xlsx');
            });
        }
    };

    return (
        <Button variant="contained" startIcon={<Iconify icon="vscode-icons:file-type-excel" />} onClick={exportToExcel}>
            Export User List to Excel
        </Button>
    );
}

export default DynamicExcelUser;
