import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Stack, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import makeApiRequest from '../../api/commonApi';
import Loader from '../Loader';

const Type = () => {

  // total count
  const [TotalCaseOfDocument, setTotalCaseOfDocument] = useState();
  const [TotalCaseOfField, setTotalCaseOfField] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const CaseTypeDwtails = async (page) => {
    try {
      setIsLoading(true);
      const documentdata = await makeApiRequest('get', `/document-case`);
      const Fielddata = await makeApiRequest('get', `/field-case`);
      setTotalCaseOfDocument(documentdata.data.TotalCase);
      setTotalCaseOfField(Fielddata.data.TotalCase);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // values
  const [documnetsubtype, setDocumnetsubtype] = useState('');
  const [Fieldsubtype, SetFieldsubtype] = useState('');

  const isFormTypeValid = (documnetsubtype) => {
    return documnetsubtype.trim() !== '';
    }

  const isprofileTypeValid = (Fieldsubtype) => {
    return Fieldsubtype.trim() !== '';
  }
  
  const handleAddDocumentSubtype = async () => {
    try {

      if (!isFormTypeValid(documnetsubtype)) {
        toast.error('Please enter valid document subtype');
        return;
      }

      const response = await makeApiRequest('post', '/document-case', { casename: documnetsubtype });
      console.log('response--', response);
      if (response.status === 201) {
        toast.success('Document subtype added successfully!');
        handleCloseDocumentPopup();
      } else if (response.status === 400) {
        toast.error('Bank with the same name and branch already exists.');
      } else {
        console.error('Failed to add document subtype');
      }
    } catch (error) {
      console.error('Error adding document subtype:', error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const handleAddFieldSubtype = async () => {
    try {

      if (!isprofileTypeValid(Fieldsubtype)) {
        toast.error('Please enter valid profile subtype');
        return;
      }

      const response = await makeApiRequest('post', '/field-case', { casename: Fieldsubtype });

      if (response.status === 201) {
        toast.success('Profile subtype added successfully!');
        setOpenPopupField();
      }
      if (response.status === 400) {
        toast.error('Bank with the same name and branch already exists.');
      } else {
        console.error('Failed to add field subtype');
      }
    } catch (error) {
      console.error('Error adding field subtype:', error);
      toast.error(error.response.data.message);
    }
  };

  // popup
  const [openPopupDocument, setOpenPopupDocument] = useState(false);
  const [openPopupField, setOpenPopupField] = useState(false);

  const handleOpenDocumentPopup = () => {
    setOpenPopupDocument(true);
    SetFieldsubtype('');
    setDocumnetsubtype('');
  };

  const handleCloseDocumentPopup = () => {
    setOpenPopupDocument(false);
    SetFieldsubtype('');
    setDocumnetsubtype('');
  };
  const handleOpenFieldPopup = () => {
    setOpenPopupField(true);
    SetFieldsubtype('');
    setDocumnetsubtype('');
  };

  const handleCloseFieldPopup = () => {
    setOpenPopupField(false);
    SetFieldsubtype('');
    setDocumnetsubtype('');
  };

  useEffect(() => {
    CaseTypeDwtails();
  }, [documnetsubtype, Fieldsubtype]);
  return (
    <>
      <div>
        <Helmet>
          <title> Type | Quick Support </title>
        </Helmet>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Case Type
          </Typography>
        </Stack>

        <TableContainer component={Paper} className="TableParent">
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow className="TableheadStyle">
                <TableCell className="HeadCellStyle">S.No</TableCell>
                <TableCell className="HeadCellStyle textCentre">Case</TableCell>
                <TableCell className="HeadCellStyle textCentre">Total Subtype</TableCell>
                <TableCell className="HeadCellStyle textCentre">View All Subtype</TableCell>
                <TableCell className="HeadCellStyle textCentre">Add Subtype</TableCell>
                <TableCell className="HeadCellStyle textCentre">Froms</TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <Loader />
            ) : (
              <TableBody>
                <TableRow className="TableRowStyle">
                  <TableCell component="th" scope="row" className="textCentre">
                    1
                  </TableCell>
                  <TableCell className="textCentre">Document</TableCell>
                  <TableCell className="textCentre"> {TotalCaseOfDocument} </TableCell>
                  <TableCell className="textCentre">
                    <Link to="/dashboard/view-sub-type-details/document-case">
                      <Button>View</Button>
                    </Link>
                  </TableCell>
                  <TableCell className="textCentre">
                    <Button onClick={handleOpenDocumentPopup}>Add</Button>
                  </TableCell>
                  <TableCell className="textCentre">
                    <Link to="/dashboard/Case-forms/caseformlayout">
                      <button className="btn btn-primary">Subtype Forms</button>
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow className="TableRowStyle">
                  <TableCell component="th" scope="row" className="textCentre">
                    2
                  </TableCell>
                  <TableCell className="textCentre">Profile</TableCell>
                  <TableCell className="textCentre"> {TotalCaseOfField} </TableCell>
                  <TableCell className="textCentre">
                    <Link to="/dashboard/view-sub-type-details/field-case">
                      <Button>View</Button>
                    </Link>
                  </TableCell>
                  <TableCell className="textCentre">
                    <Button onClick={handleOpenFieldPopup}>Add</Button>
                  </TableCell>
                  <TableCell className="textCentre">
                    <Link to="/dashboard/Case-forms/case-profile-formlayout">
                      <button className="btn btn-primary">Subtype Forms</button>
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {/* popup for documnet */}
        <Dialog open={openPopupDocument} onClose={handleCloseDocumentPopup}>
          <DialogTitle>Add Document Subtype</DialogTitle>
          <DialogContent className="p-2">
            <TextField
              label="Document Subtype"
              variant="outlined"
              fullWidth
              name='documnetsubtype'
              value={documnetsubtype}
              onChange={(e) => setDocumnetsubtype(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDocumentPopup} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddDocumentSubtype} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {/* popup for Field */}
        <Dialog open={openPopupField} onClose={handleCloseDocumentPopup}>
          <DialogTitle>Add Profile Subtype</DialogTitle>
          <DialogContent className="p-2">
            <TextField
              label="Profile Subtype"
              variant="outlined"
              fullWidth
              name='Fieldsubtype'
              value={Fieldsubtype}
              onChange={(e) => SetFieldsubtype(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFieldPopup} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddFieldSubtype} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Type;
