import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import './formview.css';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import '../../assets/css/form.css';
import makeApiRequest from '../../api/commonApi';
import { compressImage } from '../../utils/commonFunction';

const FormViwer = () => {
  // State variables
  const { bankId } = useParams();
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [openPopup, setOpenPopup] = useState(false);
  const [fields, setFields] = useState([]);
  // const [formData, setFormData] = useState('');
  const [formData, setFormData] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const stepname = "1"
  const [selectedBankId, setSelectedBankId] = useState('');
  const [lodedBankName, setLoadedBankName] = useState('');
  const [bank, setBank] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const [loginUser, setloginUser] = useState('');
  const [caseIDValue, SetCaseIDValue] = useState('');
  // Fetch layouts on component mount
  useEffect(() => {
    const fetchLayouts = async () => {
      try {
        const response = await makeApiRequest('get', '/bankformlayout');
        const rolesList = await makeApiRequest('get', '/role-for-create-product');
        const RolesListNotShowinReportPage = await makeApiRequest('get', '/role');

        if (response.status === 200) {
          const { data } = response.data;
          setLayouts(data);
          const roleOptions = rolesList.data.data?.map((role) => role.role);
          setUserRoles(roleOptions);
        } else {
          console.error('Failed to fetch layouts');
        }
      } catch (error) {
        console.error('Error fetching layouts:', error);
      }
    };

    fetchLayouts();
  }, []);
  // login user deatils
  useEffect(() => {
    const userDetails = async () => {
      try {
        const MyDetails = await makeApiRequest('get', '/me');
        if (MyDetails.status === 200) {
          // console.log(MyDetails.data.user._id);
          setloginUser(MyDetails.data.user);
        } else {
          console.error('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching layouts:', error);
      }
    };
    userDetails();
  }, []);

  // Handle loading a layout
  const handleLoadLayout = async (layoutId) => {
    setIsLoading(true);

    try {
      const response = await makeApiRequest('get', `/bankformlayout/${bankId}`);

      const loadedLayout = response.data.data;

      setLoadedBankName(loadedLayout.bankId.bankname);
      // console.log(loadedLayout.bankId)
      setBank(loadedLayout.bankId);
      setSelectedBankId(loadedLayout.bankId?._id || '');
      const loadedFields = loadedLayout.steps.find((step) => step.stepNumber === stepname).fileds;
      setFields(loadedFields);
    } catch (error) {
      console.error('Error loading layout:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleLoadLayout();
  }, []);

  // Fetch layout on selected bank or step change
  useEffect(() => {
    const fetchLayout = async () => {
      if (selectedBankId && stepname) {
        await handleLoadLayout(selectedBankId);
      }
    };

    fetchLayout();
  }, [selectedBankId]);

  const handleInputChange = (fieldname, value, FieldAccess) => {
    const updatedFormData = {
      fieldname,
      value,
      FieldAccess,
    };

    setFormData((prevFormData) => {
      const fieldIndex = prevFormData.findIndex((field) => field.fieldname === fieldname);

      if (fieldIndex !== -1) {
        const updatedFields = [...prevFormData];
        updatedFields[fieldIndex] = updatedFormData;
        return updatedFields;
      }

      return [...prevFormData, updatedFormData];
    });
  };



  const handleSaveData = async () => {
    try {
      if (caseIDValue.length === 0) {
        toast.error('Please Add Case Id');
        return;
      }
      const dataToSave = {
        formData,
      };
        setIsSubmitting(true);
        await makeApiRequest('post', '/formdata-from-form', { formData: dataToSave, bankId: bank });
        setOpenPopup(true);
        setIsSubmitting(false);
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    localStorage.removeItem('layouts');
    // window.location.reload();
    history('/dashboard/allrequest/all');
  };

  const [uploadProgress, setUploadProgress] = useState(0);



  const handleImageUpload = async (label, event, FieldAccess) => {
    try {
      const file = event.target.files[0];
      // avoid video file
      if (file.type.startsWith('video/')) {
        toast.error('video not allow');
        return;
      }
      if (file.type.startsWith('image/')) {

        const compressedFile = await compressImage(file);

        // Set size of compressed file
        if (compressedFile.size > 5 * 1024 * 1024) {
          toast.error('Please upload a file under 5 MB');
          return;
        }

        const data = new FormData();
        data.append('file', compressedFile);
        data.append('upload_preset', 'acpienfk');

        await axios
          .post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`, data, {
            onUploadProgress: (progressEvent) => {
              // ((current uploaded / total size) * 100 )
              const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              setUploadProgress(progress);
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const imageURL = response.data.url;
              handleInputChange(label, imageURL, FieldAccess);
            }
          });
      } else {
        if (file.size > 5 * 1024 * 1024) {
          toast.error('Please upload a file under 5 MB');
          return;
        }
        // Upload other file types without compression
        const data = new FormData();
        data.append('file', file);
        data.append('upload_preset', 'acpienfk');

        await axios
          .post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`, data, {
            onUploadProgress: (progressEvent) => {
              // ((current uploaded / total size) * 100 )
              const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              setUploadProgress(progress);
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const imageURL = response.data.url;
              handleInputChange(label, imageURL, FieldAccess);
            }
          });
      }
    } catch (error) {
      console.log('image upload error', error);
    } finally {
      setUploadProgress(0);
    }
  };

  useEffect(() => {
    setFormData([]);
  }, []);

  // const currentDate = new Date();
  // const dateString = currentDate.toLocaleDateString()
  const currentDate = new Date();
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const dateString = currentDate.toLocaleDateString(undefined, options);

  // const dateString = "9/10/2023"

  // Render the form fields
  const renderFields = () => {

    return fields.map((field, index) => (
      <div key={index} className="form-field">
        <label htmlFor="input-filed">{field.label}</label>
        {renderInput(field, index, field.label)}
      </div>
    ));
  };

  // Render input based on field type
  const renderInput = (field, index, label) => {
    const fieldData = formData.find((data) => data.fieldname === label);
    switch (field.type) {
      case 'input-text':
        return (
          <input
            type="text"
            name={label}
            placeholder={field.placeholder || ''}
            className="form-control inputTextSize"
            value={fieldData ? fieldData.value : ''}
            onChange={(event) => {
              handleInputChange(label, event.target.value, field.FieldAccess);
            }}
          />
        );
      case 'input-number':
        return (
          <input
            type="number"
            name={label}
            placeholder={field.placeholder || ''}
            className="form-control inputTextSize"
            value={fieldData ? fieldData.value : ''}
            onChange={(event) => handleInputChange(label, event.target.value, field.FieldAccess)}
          />
        );

      case 'input-image':
        return (
          <input
            type="file"
            name={label}
            multiple={true}
            placeholder={field.placeholder || ''}
            className="form-control"
            onChange={(event) => {
              handleImageUpload(label, event, field.FieldAccess);
            }}
          />
        );
      case 'input-audio':
        return (
          <input
            type="file"
            name={label}
            multiple={true}
            placeholder={field.placeholder || ''}
            className="form-control"
            onChange={(event) => {
              handleImageUpload(label, event, field.FieldAccess);
            }}
          />
        );
      case 'input-dropdown':
        return (
          <div key={index} className="form-field dropdown-container">
            {/* <label>Input Dropdown</label> */}
            <select
              className="form-control"
              name={label}
              value={fieldData ? fieldData.value : ''}
              placeholder={field.placeholder || ''}
              onChange={(event) => handleInputChange(label, event.target.value, field.FieldAccess)}
            >
              <option>----Please select value----</option>
              {field.options?.map((option, optionIndex) => (
                <option key={optionIndex} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );

      case 'input-date':
        return (
          <input
            type="date"
            name={label}
            value={fieldData ? fieldData.value : ''}
            className="form-control inputTextSize"
            onChange={(event) => handleInputChange(label, event.target.value, field.FieldAccess)}
          />
        );
      case 'input-radio':
        return (
          <FormControl component="fieldset" key={index}>
            <RadioGroup
              name={label}
              className="p-2 inputTextSize"
              onChange={(event) => handleInputChange('input-dropdown', event.target.value, field.FieldAccess)}
            >
              {field.options?.map((option, optionIndex) => (
                <FormControlLabel key={optionIndex} value={option} control={<Radio />} label={<div>{option}</div>} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      default:
        return null;
    }
  };

  const formContainerClass = openPopup ? 'form-container animated shake' : 'form-container';
  return (
    <>
      <div className="form-builder container row align-items-center  ">
        <>
          {uploadProgress > 0 && (
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              style={{ backgroundColor: 'lightgray' }}
              classes={{ bar: 'custom-progress-bar' }}
            />
          )}
        </>
        <div className="text-center">
          <h5>{lodedBankName}</h5>
        </div>
        <div className="text-center">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div className={`${formContainerClass} col-12 border-warning w-100 p-4  mainFrom`}>
              <>
                <>
                  <span>Case Id </span>
                  <input
                    type="text"
                    name="case_id"
                    required
                    className="form-control m-1"
                    value={formData.find((data) => data.fieldname === 'case_id')?.value || ''}
                    onChange={(event) => {
                      handleInputChange('case_id', event.target.value.trim(), userRoles);
                      handleInputChange('status', 'received', userRoles);
                      handleInputChange('Case Received By', loginUser._id, userRoles);
                      SetCaseIDValue(event.target.value);
                    }}
                  />
                  <span>Product </span>
                  <input
                    type="text"
                    name="product"
                    className="form-control m-1"
                    value={formData.find((data) => data.fieldname === 'product')?.value || ''}
                    onChange={(event) => {
                      handleInputChange('product', event.target.value, userRoles);
                    }}
                  />
                  <span>Applicant name </span>
                  <input
                    type="text"
                    name="applicant name"
                    className="form-control m-1"
                    value={formData.find((data) => data.fieldname === 'applicant name')?.value || ''}
                    onChange={(event) => {
                      handleInputChange('applicant name', event.target.value, userRoles);
                    }}
                  />
                  <span>Case Received Date </span>
                  <input
                    type="date"
                    name="Case Received Date"
                    className="form-control m-1"
                    value={formData.find((data) => data.fieldname === 'Case Received Date')?.value || ''}
                    onChange={(event) => {
                      handleInputChange('Case Received Date', event.target.value, userRoles);
                    }}
                  />
                </>
              </>

              {renderFields()}
            </div>
          )}
        </div>
        <Dialog open={openPopup} onClose={handleClosePopup}>
          <DialogTitle>Data Saved</DialogTitle>
          <DialogContent>Case Received successfully.</DialogContent>
          <DialogActions>
            {/* <Link to="/dashboard/app"> */}
            <Button onClick={handleClosePopup} color="primary" autoFocus>
              OK
            </Button>
            {/* </Link> */}
          </DialogActions>
        </Dialog>

        <div className="d-flex justify-content-around align-items-center">
          <div>
            {isSubmitting ? (
              <button disabled className="btn btn-success mt-3">
                loading...
              </button>
            ) : (
              <button onClick={handleSaveData} disabled={caseIDValue.length === 0 || uploadProgress} className="btn btn-success mt-3">
                Save Data
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormViwer;
