import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import './step.css';

import FormBuilderView from './FormView';
import makeApiRequest from '../../api/commonApi';

function StepData() {
  localStorage.setItem('activeStep', 1);

  return (
    <>
      <div className="container p-2  ">
        <div className="pb-4">
          <Link to={`/dashboard/forms`}>
            <ArrowBackIcon />
          </Link>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="FormStyle">
            <FormBuilderView />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          {/* {activeStep > 0 && (
            <button className="m-4 btn btn-outline-danger" onClick={handleBackStep}>
              Back Step
            </button>
          )} */}
          {/* {activeStep < allSteps.length - 1 && (
            <button className="m-4 btn btn-outline-success" onClick={handleNextStep}>
              Next Step
            </button>
          )} */}
        </div>
      </div>
    </>
  );
}

export default StepData;
