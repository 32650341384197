import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// @mui
import { Stack, IconButton, InputAdornment, TextField, CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import makeApiRequest from '../../../api/commonApi';
// components
import Iconify from '../../../components/iconify';
import { fetchUserInfo } from '../../../redux/user/userSlice';
// import Typography from '../../../theme/overrides/Typography';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleClick = async () => {
    setEmailError('');
    setPasswordError('');

    // Email validation
    if (!email || !emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    // Password validation
    if (!password || password.length < 4) {
      setPasswordError('Password must be at least 8 character long');
      return;
    }

    try {
      setIsloading(true);

      const response = await makeApiRequest('post', '/login', { email, password });
      if (response.status === 200) {
        navigate('/dashboard', { replace: true });
        const token = response?.data?.token;
        localStorage.setItem('token', token);
        dispatch(fetchUserInfo());
        toast.success(response?.data?.message);
      }
    } catch (error) {
      if (error.response && [401, 400].includes(error.response.status)) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.message);
      }
      console.log("login error", error);
    } finally {
      setIsloading(false);
    }

  };

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h4" gutterBottom sx={{ my: 3 }}>
          Sign in
        </Typography>
        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={Boolean(emailError)}
          helperText={emailError}
          variant='standard'
        />

        <TextField
          name="password"
          label="Password"
         
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={Boolean(passwordError)}
          helperText={passwordError}
          variant='standard'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>



      <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
        <Link style={{ textDecoration: "none" }} variant="subtitle2" underline="none" to="/forgotPassword" onClick={(e) => handleClick()} >
          Forgot password?
        </Link>
      </Stack>

      {
        isLoading ? (
          <div style={{ textAlign: 'center', margin: '16px 0' }}>
            <CircularProgress size={24} />
          </div>
        )
          : (
            <LoadingButton className='mt-4' fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
              Login
            </LoadingButton >
          )
      }

    </>
  );
}
