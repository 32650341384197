import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import {
  MenuItem,
  IconButton,
  Stack,
  Typography,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import delteFunction from '../../api/deleteFunction';
import makeApiRequest from '../../api/commonApi';
import Iconify from '../iconify/Iconify';
import Loader from '../Loader';

const BankList = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [popoverOpen, setPopoverOpen] = useState([]);
  const [userData, setUserData] = useState([]);
  const [bankLength, setBankLength] = useState(0);
  const [Search, SetSearch] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const AllDetails = async (page) => {
    try {
      setIsLoading(true);
      const data = await makeApiRequest('get', `/bank?page=${page + 1}&searchTerm=${Search}&limit=${rowsPerPage}`);
      setUserData(data.data.data);
      setBankLength(data.data.TotalBank);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const CreateNewBank = () => {
    navigate('/dashboard/addbank');
  };

  const EditUserDetails = (id) => {
    navigate(`/dashboard/editbank?id=${id}`);
    console.log('User ID after click: ', id);
  };

  const DeleteUser = (user, index) => {
    handleCloseMenu(index);
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await delteFunction('/bank', userToDelete);
      const updatedUserData = userData.filter((user) => user._id !== userToDelete);
      setUserData(updatedUserData);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Delete error:', error);
    }
  };

  const handleOpenMenu = (event, index) => {
    const newPopoverOpen = [...popoverOpen];
    newPopoverOpen[index] = event.currentTarget;
    setPopoverOpen(newPopoverOpen);
  };

  const handleCloseMenu = (index) => {
    const newPopoverOpen = [...popoverOpen];
    newPopoverOpen[index] = null;
    setPopoverOpen(newPopoverOpen);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const calculateSerialNumber = (index) => page * rowsPerPage + index + 1;

  useEffect(() => {
    AllDetails(page);
    /* eslint-disable */
  }, [page, Search, rowsPerPage]);

  return (
    <>
      <div>
        <Helmet><title> Banks | Quick Support </title></Helmet>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Banks
          </Typography>
          {userInfo.role.role === 'admin' || 'super-admin' ? (
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={CreateNewBank}>
              New Bank
            </Button>
          ) : null}
        </Stack>

        <Grid className="mb-3">
          <TextField
            type="search"
            autoComplete="off"
            id="search"
            label="Search"
            value={Search}
            onChange={(e) => {
              setPage(0);
              SetSearch(e.target.value)
            }}
          />
        </Grid>

        <TableContainer component={Paper} className="TableParent">
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow className="TableheadStyle">
                <TableCell className="HeadCellStyle">S.No</TableCell>
                <TableCell className="HeadCellStyle textCentre">Bank Name</TableCell>
                <TableCell className="HeadCellStyle textCentre">Branch</TableCell>
                <TableCell className="HeadCellStyle textCentre">Address</TableCell>
                {/* <TableCell className="HeadCellStyle textCentre">Bank Email</TableCell> */}
                {userInfo.role.role === 'admin' || 'super-admin' ? (
                  <TableCell className="HeadCellStyle textCentre">Action</TableCell>
                ) : null}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {userData.map((item, index) => (
                    <TableRow className="TableRowStyle " key={item._id}>
                      <TableCell component="th" scope="row" className="textCentre">
                        {calculateSerialNumber(index)}
                      </TableCell>
                      <TableCell className="textCentre">
                        {item.bankname
                          ? item.bankname
                            .split(' ')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')
                          : ''}
                      </TableCell>
                      <TableCell className="textCentre">
                        {' '}
                        {item.branch && item.branch.charAt(0).toUpperCase() + item.branch.slice(1)}
                      </TableCell>

                      <TableCell className="textCentre">
                        {' '}
                        {item.address && item.address.charAt(0).toUpperCase() + item.address.slice(1)}
                      </TableCell>
                      {/* <TableCell className="textCentre">
                        {item.bankemail && item.bankemail.charAt(0).toUpperCase() + item.bankemail.slice(1)}
                      </TableCell> */}
                      {userInfo.role.role === 'admin' || 'super-admin' ? (
                        <TableCell className="textCentre">
                          <>
                            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, index)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                            <Popover
                              open={Boolean(popoverOpen[index])}
                              anchorEl={popoverOpen[index]}
                              onClose={() => handleCloseMenu(index)}
                              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  width: 140,
                                  '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <MenuItem onClick={() => EditUserDetails(item._id)}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>
                              <MenuItem sx={{ color: 'error.main' }} onClick={() => DeleteUser(item._id , index)}>
                                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                Delete
                              </MenuItem>
                            </Popover>
                          </>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}

                  {userData.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={12} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={bankLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
          labelRowsPerPage="Rows per page"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this bank ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BankList;