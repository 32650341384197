import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import SvgColor from '../svg-color/SvgColor';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection() {
  const icon = (name) => (
    <SvgColor
      src={`/assets/icons/navbar/${name}.svg`}
      sx={{ width: 1, height: 1, color: 'var(--navbar-icon-color)' }}
    />
  ); // icon color ::value_from_root_variable

  const userInfo = useSelector((state) => state.user.userInfo);

  const navConfig = [

    ...(['admin', 'super-admin'].includes(userInfo.role.role.toLowerCase())
      ? [
        { title: 'dashboard', path: '/dashboard/app', icon: icon('ic_analytics') },
        { title: 'All Cases', path: '/dashboard/allrequest/all', icon: icon('ic_report') },
        { title: 'Users', path: '/dashboard/user', icon: icon('ic_user') },
        { title: 'Forms', path: '/dashboard/forms', icon: icon('ic_forms') },
        { title: 'Case Types', path: '/dashboard/type', icon: icon('ic_type') },
        { title: 'Banks', path: '/dashboard/banklist', icon: icon('ic_bank') },
        // { title: 'Roles', path: '/dashboard/createrole', icon: icon('ic_role') },
        // { title: 'Reports', path: '/dashboard/report', icon: icon('ic_report') },
        // { title: 'WhatApp', path: '/dashboard/whatapp', icon: icon('ic_whatsapp') },
      ]
      : []),

    ...(userInfo.role.role.toLowerCase() === 'backend-executive'
      ? [
        // { title: 'Users', path: '/dashboard/user', icon: icon('ic_user') },
        { title: 'dashboard', path: '/dashboard/app', icon: icon('ic_analytics') },
        { title: 'All Cases', path: '/dashboard/allrequest/all', icon: icon('ic_report') },
        { title: 'Forms', path: '/dashboard/forms', icon: icon('ic_forms') },
        // { title: 'Banks', path: '/dashboard/banklist', icon: icon('ic_bank') },
        // { title: 'Reports', path: '/dashboard/report', icon: icon('ic_report') },
      ]
      : []),

    ...(userInfo.role.role.toLowerCase() === 'verifier'
      ? [
        // { title: 'Users', path: '/dashboard/user', icon: icon('ic_user') },
        // { title: 'All Cases', path: '/dashboard/allrequest/all', icon: icon('ic_report') },
        // { title: 'Forms', path: '/dashboard/forms', icon: icon('ic_forms') },
        { title: 'dashboard', path: '/dashboard/app', icon: icon('ic_analytics') },
        { title: 'My Cases', path: '/dashboard/verifierCases/all', icon: icon('ic_report') },
      ]
      : []),

    ...(userInfo.role.role.toLowerCase() === 'rcu-manager'
      ? [
        { title: 'dashboard', path: '/dashboard/app', icon: icon('ic_analytics') },
        { title: 'All Cases', path: '/dashboard/allrequest/all', icon: icon('ic_report') },
      ]
      : []),
    ...(userInfo.role.role.toLowerCase() === 'agency-manager'
      ? [
        { title: 'dashboard', path: '/dashboard/app', icon: icon('ic_analytics') },
        { title: 'Users', path: '/dashboard/user', icon: icon('ic_user') },
        { title: 'All Cases', path: '/dashboard/allrequest/all', icon: icon('ic_report') },
        { title: 'Forms', path: '/dashboard/forms', icon: icon('ic_forms') },
        { title: 'Banks', path: '/dashboard/banklist', icon: icon('ic_bank') },
        // { title: 'Reports', path: '/dashboard/report', icon: icon('ic_report') },
      ]
      : []),
  ];

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => {
          return <NavItem key={item.title} item={item} />;
        })}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'var(--navbar-active-tab-color)', // active tabs color :value_
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
} 