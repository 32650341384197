import { toast } from 'react-toastify';
import makeApiRequest from './commonApi';

const delteFunction = async (endpoint, itemId) => {
  try {
    const response = await makeApiRequest('delete', `${endpoint}/${itemId}`);
    if (response.status === 200) {
      toast.success('Deleted  successfully!');
      console.log('response data deleted', response);
      return response.data; 
    } 

    throw new Error('Failed to delete Item');
  } catch (error) {
    if (error.response.data.message === "Bank cannot be deleted because there is a related form layout") {
      toast.error('Bank cannot be deleted because there is a related form layout');
    }else{
      toast.error('Something went wrong!');
    }
    throw new Error('Failed to delete item');
  }
};

export default delteFunction;
