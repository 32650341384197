import React from 'react';
// import "./FormBuilder.css"
import '../DaynamicForm/FormBuilder.css';

const MainSidebar = () => {
  const handleDragStart = (event, field) => {
    event.dataTransfer.setData('field', field);
  };

  return (
    <div className="d-flex p-3">
      <div className="border sidebarwidth d-flex flex-column forpointer sticky-sidebar ">
        <div
          draggable
          onDragStart={(event) => handleDragStart(event, 'input-text')}
          className="firstbtncle inputTextSize "
          style={{
            padding: '10px',
            marginBottom: '10px',
          }}
        >
          Input Text
        </div>
        <div
          draggable
          onDragStart={(event) => handleDragStart(event, 'input-number')}
          className="firstbtncle inputTextSize"
          style={{
            padding: '10px',
            marginBottom: '10px',
          }}
        >
          Input Number
        </div>
        <div
          draggable
          onDragStart={(event) => handleDragStart(event, 'input-dropdown')}
          className="firstbtncle inputTextSize"
          style={{
            padding: '10px',
            marginBottom: '10px',
          }}
        >
          Dropdown
        </div>
        <div
          draggable
          onDragStart={(event) => handleDragStart(event, 'input-image')}
          className="firstbtncle inputTextSize"
          style={{
            padding: '10px',
            marginBottom: '10px',
          }}
        >
          Input Image
        </div>
        <div
          draggable
          onDragStart={(event) => handleDragStart(event, 'input-audio')}
          className="firstbtncle inputTextSize"
          style={{
            padding: '10px',
            marginBottom: '10px',
          }}
        >
          Input Audio
        </div>
        <div
          draggable
          onDragStart={(event) => handleDragStart(event, 'input-date')}
          className="firstbtncle inputTextSize"
          style={{
            padding: '10px',
            marginBottom: '10px',
          }}
        >
          Input Date
        </div>
      </div>
    </div>
  );
};

export default MainSidebar;
