import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, TextField, InputAdornment } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet-async';
import makeApiRequest from '../../api/commonApi';
import Iconify from '../iconify/Iconify';
import Loader from '../Loader';

const EditBankDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [userData, setUserData] = useState({
    bankname: '',
    branch: '',
    address: '',
  });

  const isBankNameVaid = (bankname) =>{
    const bankRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/
    return bankRegex.test(bankname)
  }

  const isBranchkNameVaid = (branch) =>{
    const brankRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/
    return brankRegex.test(branch)
  }

  const isAddressVaid = (address) =>{
    const addressRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/
    return addressRegex.test(address)
  }

  useEffect(() => {
    const getAllDetailsById = async () => {
      try {
        setIsLoading(true);
        const details = await makeApiRequest('get', `/bank/${id}`);
        setUserData(details.data.data);
        console.log('bank details', details.data.data);
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    };
    getAllDetailsById();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const backtoMain = () => {
    navigate('/dashboard/banklist');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!isBankNameVaid(userData.bankname)){
      toast.error('Please enter a valid bank name and do not enter unwanted space.');
      return;
    }

    if(!isBranchkNameVaid(userData.branch)){
      toast.error('Please enter a valid branch name and do not enter unwanted space.');
      return;
    }


    if(!isAddressVaid(userData.address)){
      toast.error('Please enter a valid address and do not enter unwanted space.');
      return;
    }

    try {
      setIsLoading2(true);
      await makeApiRequest('put', `/bank/${id}`, userData);
      console.log('Profile Updated successfully!');
      toast.success('Details Updated  successfully!');
      backtoMain();
    } catch (error) {
      console.log(error);
      console.log('Something gone wrong!');
    }finally {
      setIsLoading2(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> EditBank | Quick Support </title>
      </Helmet>

      {isLoading ? (
        <div className=" d-flex justify-content-center align-items-center ">
          <Loader />
        </div>
      ) : (


      <Grid className="p-3">
        <Paper elevation={20} className="paperStyle">
          <div className="CrossIconParent">
            <CloseIcon onClick={backtoMain} className="iconCross" />
          </div>
          <Grid align="center">
            <h2 className="headerStyle">Edit Bank Details</h2>
          </Grid>

          <div className="container">
            <form className="row " onSubmit={handleSubmit}>
              <Grid>
                <TextField
                  label="Bank Name"
                  variant="standard"
                  value={userData.bankname || ''}
                  fullWidth
                  type="text"
                  name="bankname"
                  autoComplete="off"
                  className="my-2"
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid>
                <TextField
                  label="Branch"
                  variant="standard"
                  value={userData.branch || ''}
                  fullWidth
                  type="text"
                  name="branch"
                  autoComplete="off"
                  className="my-2"
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid>
                <TextField
                  label="Address"
                  variant="standard"
                  value={userData.address || ''}
                  fullWidth
                  type="text"
                  name="address"
                  autoComplete="off"
                  className="my-2"
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <div className="text-center my-4 mt-5">
              {isLoading2 ? (
                    <div className=" d-flex justify-content-center align-items-center ">
                      <Loader />
                    </div>
                  ) : (
                <Button variant="contained" type="submit">
                  Update
                </Button>
                  )}
              </div>
            </form>
          </div>
        </Paper>
      </Grid>

      )}
      
    </>
  );
};

export default EditBankDetails;
