import React, { useState, useRef, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Stack, MenuItem, InputLabel, Typography, CircularProgress, DialogActions, Dialog, Select, DialogContent, LinearProgress } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import Iconify from '../iconify/Iconify';
import '../../assets/css/table.css';
import makeApiRequest from '../../api/commonApi';
import { compressImage } from '../../utils/commonFunction';

const Reports = () => {

  const { caseId } = useParams();
  const loanId = caseId;
  const [formData, setFieldData] = useState([]);
  const [fields, setFields] = useState([]);
  const [showDialogs, setShowDialogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // get all data
  const handleFetchFormData = async () => {
    try {
      setIsLoading(true);
      const response = await makeApiRequest('get', `/formdata-for-report/${caseId}`);
      const data = response.data.data;
      setFieldData(data);

      const columns = Object.keys(data[0]).filter((fieldName) => fieldName !== 'FieldAccess');
      setFields(columns);

      setShowDialogs(new Array(data.length).fill(false));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // testing

  // handelfileChange

  const [applicantname, setapplicantname] = useState('');
  useEffect(() => {
    formData.forEach((item, rowIndex) => {
      if (item?.fieldname.includes('applicant name')) {
        setapplicantname(item.value);
      }
    });
  }, [formData]);
  useEffect(() => {
    handleFetchFormData();
  }, []);
  // removing word

  const [showfilteredData, setshowFilteredDataa] = useState([]);
  // report result
  const [FinalResut, setFinalResult] = useState('');
  const logFormDataWithTestFieldname = () => {
    setIsLoading(true);
    // remove word
    const filteredData = formData
      .filter((item) => item.fieldname.includes('STRINGTOBEREMOVE'))
      .map((item) => ({
        fieldname: item.fieldname.replace('STRINGTOBEREMOVE', ''),
        value: item.value,
        Remark: item.verificationRemark,
      }));
    setIsLoading(false);

    if (filteredData.some((item) => item.value === 'NEGATIVE')) {
      setFinalResult('NEGATIVE');
    } else {
      setFinalResult('POSITIVE');
    }
    setshowFilteredDataa(filteredData);
    // const updatedFormData = formData.map((item) => {
    // if (item.fieldname.includes('STRINGTOBEREMOVE')) {
    // item.fieldname = item.fieldname.replace('STRINGTOBEREMOVE', '');

    // }

    // return item;
    // });
  };
  useEffect(() => {
    logFormDataWithTestFieldname();
  }, [formData]);

  // downlode
  const toDownload = useRef();

  const downloadPDF = useReactToPrint({
    content: () => toDownload.current,
    documentTitle: `${loanId}_${applicantname}`,
  });

  // Testing
  // useEffect(() => {
  //   const uniqdata = async () => {
  //     const uniqueCasenameSet = new Set();

  //     await formData.forEach((item) => {
  //       if (item.casename) {
  //         uniqueCasenameSet.add(item.casename);
  //       }
  //     });

  //     const uniqueCasenameArray = Array.from(uniqueCasenameSet);

  //     SetshowLalabel(uniqueCasenameArray);
  //   };
  //   uniqdata();
  // }, [formData]);
  // const handleLoadLayout1 = () => {
  //   const filteredData = formData.filter((item) => showLalabel.includes(item.casename));
  //   Setfilterdata(filteredData);
  // };
  // useEffect(() => {
  //   handleLoadLayout1();
  // }, [formData]);
  // open popup with index
  const toggleDialog = (rowIndex) => {
    const newShowDialogs = [...showDialogs];
    newShowDialogs[rowIndex] = !newShowDialogs[rowIndex];
    setShowDialogs(newShowDialogs);
  };
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    const fetchLayouts = async () => {
      try {
        const rolesList = await makeApiRequest('get', '/role-for-create-product');

        if (rolesList.status === 200) {
          const roleOptions = rolesList.data.data?.map((role) => role.role);
          setUserRoles(roleOptions);
        } else {
          console.error('Failed to fetch role');
        }
      } catch (error) {
        console.error('Error fetching role:', error);
      }
    };

    fetchLayouts();
  }, []);

  // final status
  const handelselectResult = (value, name) => {
    handleInputChange(`OVER ALL RCU STATUS`, value, userRoles);
  };
  const handleInputChange = (fieldname, value, FieldAccess) => {
    const updatedFormData = {
      fieldname,
      value,
      FieldAccess,
    };

    setFieldData((prevFormData) => {
      const fieldIndex = prevFormData.findIndex((field) => field.fieldname === fieldname);

      if (fieldIndex !== -1) {
        const updatedFields = [...prevFormData];
        updatedFields[fieldIndex] = updatedFormData;
        return updatedFields;
      }

      return [...prevFormData, updatedFormData];
    });
  };

  const [showdropdown, setshowdropdown] = useState(true);
  const handleSaveData = async () => {
    try {
      // if (stepname === '1') {
      const dataToSave = {
        formData,
        closeCase: true
      };
      const response = await makeApiRequest('put', `/formdata/${loanId}`, dataToSave);
      if (response.status === 200) {
        toast.success('Report Status Save...');
      }
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  };
  const [overAllRCUStatus, setOverAllRCUStatus] = useState('');

  const findOverAllRCUStatus = () => {
    const statusItem = formData.find((item) => item.fieldname === 'OVER ALL RCU STATUS');
    if (statusItem) {
      // if (statusItem.value === 'POSITIVE' || statusItem.value === 'NEGATIVE') {
      setOverAllRCUStatus(statusItem.value);
      setshowdropdown(false);
      // }
    }
  };

  useEffect(() => {
    findOverAllRCUStatus();
  }, [formData]);

  const handleImageUpload = async (label, event) => {
    try {
      const files = event.target.files;

      // Validate each file
      for (const file of files) {
        if (
          file.type.startsWith('video/') ||
          file.type.startsWith('audio/') ||
          file.type === 'application/pdf'
        ) {
          toast.error('Video, audio, and PDF files are not allowed');
          return;
        }

        if (file.type.startsWith('image/')) {
          // Compress the image
          const compressedFile = await compressImage(file);

          // Set size of compressed file
          if (compressedFile.size > 5 * 1024 * 1024) {
            toast.error('Please upload a file under 5 MB');
            return;
          }
        } else if (file.size > 5 * 1024 * 1024) {
          toast.error('Please upload a file under 5 MB');
          return;
        }
      }

      const uploadPromises = Array.from(files).map(async (file) => {
        const data = new FormData();
        if (file.type.startsWith('image/')) {
          const compressedFile = await compressImage(file);
          data.append('file', compressedFile);
        } else {
          data.append('file', file);
        }
        data.append('upload_preset', 'acpienfk');

        return axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`, data, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadProgress(progress);
          },
        });
      });

      const responses = await Promise.all(uploadPromises);

      const imageUrls = responses.map((response) => response.data.url);

      handleInputChange(label, imageUrls, userRoles);

      console.log("imageUrls", imageUrls);
    } catch (error) {
      console.log('image upload error', error);
    } finally {
      setUploadProgress(0);
    }
  };


  return (
    <>
      <div className="mb-3">
        <Link style={{ color: 'blue' }} to={`/dashboard/reportgenerate/${caseId}`}>
          <ArrowBackIcon />
        </Link>
      </div>
      <div>
        <Helmet>
          <title> Final Reports </title>
        </Helmet>
        {isLoading ? (
          <div className=" d-flex justify-content-center align-items-center ">
            <CircularProgress />
          </div>
        ) : (
          <div ref={toDownload} style={{ width: '100%', pageBreakInside: 'avoid' }} className="FirstTableHead ReportParent">
            <div className="p-4 ReportHeading">
              <h4 className="textCentre"> RCU REPORT - QUICK SUPPORT SERVICES</h4>
            </div>
            <TableContainer component={Paper} className="TableParentSecond">
              <Table sx={{ minWidth: 650 }} aria-label="grid table">
                <TableHead>
                  {/* <h2>Hello</h2> */}
                </TableHead>
                <TableBody>
                  {formData
                    .filter((item) => !item.fieldname.includes('STRINGTOBEREMOVE') &&
                      !item.fieldname.includes('OVER ALL RCU STATUS') &&
                      !item.fieldname.includes('Case Received By') &&
                      !item.fieldname.includes('status')
                    )
                    .map((item, rowIndex) => (
                      <TableRow key={rowIndex} >
                        {fields.map((fieldName, columnIndex) => (
                          <TableCell key={fieldName} className={columnIndex % 2 === 0 ? 'left-side' : 'right-side'}>
                            {typeof item[fieldName] === 'string' && item[fieldName].includes('cloudinary') ? (
                              <>
                                {item[fieldName].includes('image') ? (
                                  <>
                                    <img src={item[fieldName]} alt="images" className="img-fluid" width={180} />
                                  </>
                                ) : item[fieldName].includes('video') ? (
                                  <>
                                    <button className="btn firstbtncle " onClick={() => toggleDialog(rowIndex)}>
                                      View Media
                                    </button>
                                    <Dialog
                                      className="dialog-w-100"
                                      open={showDialogs[rowIndex]}
                                      onClose={() => toggleDialog(rowIndex)}
                                      fullWidth
                                      maxWidth="md"
                                    >
                                      <DialogContent className="text-center">
                                        <video controls width="90%">
                                          <source src={item[fieldName]} type="video/mp4" />
                                          <track kind="captions" src="" label="English" />{' '}
                                        </video>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button onClick={() => toggleDialog(rowIndex)} color="primary">
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </>
                                ) : (
                                  item[fieldName]
                                    .split(' ')
                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')
                                )}
                              </>
                            ) : (
                              (typeof item[fieldName] === 'string' && item[fieldName] !== 'ReportImages') &&
                              item[fieldName]
                                .split(' ')
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')
                            )
                            }
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>


            <TableContainer component={Paper} className="TableParent">
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow className="TableheadStyle">
                    <TableCell className="HeadCellStyle">S.No</TableCell>
                    <TableCell className="HeadCellStyle">Verification Type</TableCell>
                    <TableCell className="HeadCellStyle">Count</TableCell>
                    <TableCell className="HeadCellStyle">Verification Remarks</TableCell>
                    <TableCell className="HeadCellStyle">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showfilteredData.map((item, index) => {
                    return (
                      <TableRow key={index} className="TableRowStyle my-custom-table">
                        <TableCell component="th" scope="row" className="textCentre">
                          {index + 1}
                        </TableCell>
                        <TableCell className="textCentre"> {item.fieldname.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</TableCell>
                        <TableCell className="textCentre">1</TableCell>
                        <TableCell className="textCentre">{item.Remark} </TableCell>
                        <TableCell className="textCentre">{item.value}</TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow className="TableRowStyle">
                    <TableCell component="th" scope="row" className="textCentre">
                      {/* {showfilteredData.length + 1} */}
                    </TableCell>
                    <TableCell className="textCentre TableLogo">
                      <b>Agency Seal & Signature</b>
                    </TableCell>
                    <TableCell className="textCentre"> </TableCell>
                    <TableCell className="textCentre">
                      <b> OVER ALL RCU STATUS </b>
                    </TableCell>
                    <TableCell className="textCentre"> </TableCell>
                  </TableRow>
                  <TableRow className="TableRowStyle">
                    <TableCell component="th" scope="row" className="textCentre">
                      {/* {showfilteredData.length + 2} */}
                    </TableCell>
                    <TableCell className="textCentre TableLogo">
                      <img
                        src="/assets/tablelogo.png"
                        style={{ height: 'auto' }}
                        className="img-fluid"
                        alt="Verification"
                      />
                    </TableCell>
                    <TableCell className="textCentre"> </TableCell>
                    <TableCell className="textCentre">
                      <p>{overAllRCUStatus}</p>
                    </TableCell>
                    <TableCell className="textCentre"> </TableCell>
                  </TableRow>
                  <TableRow className="TableRowStyle" >
                    {formData
                      .filter(item => item.fieldname === "ReportImages")
                      .map((imageData, index) => {
                        return imageData.value.map((url, imageIndex) => {
                          if (imageIndex < 5) {
                            return (
                              <TableCell key={imageIndex} className="textCentre" style={{  width: '100px', object:"fill" }}>
                                <img
                                  src={url}
                                  style={{  width: '150px', object:"fill" }}
                                  className="img-fluid"
                                  alt={`Uploaded Image ${imageIndex + 1}`}
                                />
                              </TableCell>
                            );
                          }
                          return null; // Skip rendering if index is greater than or equal to 5
                        });
                      })
                    }
                  </TableRow>
                  <TableRow className="TableRowStyle">
                    {formData
                      .filter(item => item.fieldname === "ReportImages")
                      .map((imageData, index) => {
                        return imageData.value.map((url, imageIndex) => {
                          if (imageIndex >= 5 && imageIndex < 10) {
                            return (
                              <TableCell key={imageIndex} className="textCentre">
                                <img
                                  src={url}
                                  style={{ width: '150px' }}
                                  className="img-fluid"
                                  alt={`Uploaded Image ${imageIndex + 1}`}
                                />
                              </TableCell>
                            );
                          }
                          return null; // Skip rendering if index is less than 5
                        });
                      })
                    }
                  </TableRow>
                  <TableRow className="TableRowStyle">
                    {formData
                      .filter(item => item.fieldname === "ReportImages")
                      .map((imageData, index) => {
                        return imageData.value.map((url, imageIndex) => {
                          if (imageIndex >= 10) {
                            return (
                              <TableCell key={imageIndex} className="textCentre">
                                <img
                                  src={url}
                                  style={{  width: '150px' }}
                                  className="img-fluid"
                                  alt={`Uploaded Image ${imageIndex + 1}`}
                                />
                              </TableCell>
                            );
                          }
                          return null; // Skip rendering if index is less than 5
                        });
                      })
                    }
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}

        <div className=" d-flex justify-content-between w-75 ">
          <div className='p-3 w-25'>
            <InputLabel>Report Status</InputLabel>
            <Select
              className="ms-2 w-100 "
              placeholder="Verifier"
              value={formData.find((data) => data.fieldname === 'OVER ALL RCU STATUS')?.value || ''}
              onChange={(e) => {
                handelselectResult(e.target.value);
              }}
            >
              <MenuItem value="Positive">Positive</MenuItem>
              <MenuItem value="Negative">Negative</MenuItem>
              <MenuItem value="CNV">CNV</MenuItem>
              <MenuItem value="Credit Refer">Credit Refer</MenuItem>
              <MenuItem value="Suspect">Suspect</MenuItem>
              <MenuItem value="Caution">Caution</MenuItem>
              <MenuItem value="Risk Alert">Risk Alert</MenuItem>
              <MenuItem value="Fraud">Fraud</MenuItem>
              <MenuItem value="Decline">Decline</MenuItem>
              <MenuItem value="Recommended">Recommended</MenuItem>
              <MenuItem value="Not Recommended">Not Recommended</MenuItem>
            </Select>
          </div>

          <div className='w-25 p-3'>
            <span>Case Closed Date </span>
            <input
              type="date"
              name="Case close Date"
              className="form-control m-1"
              value={formData.find((data) => data.fieldname === 'Case close Date')?.value || ''}
              onChange={(event) => {
                handleInputChange('Case close Date', event.target.value, userRoles);
              }}
            />
          </div>
          <div className='w-100' style={{paddingTop:"44px"}}>
            {uploadProgress > 0 && (
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                style={{ backgroundColor: 'lightgray' }}
                classes={{ bar: 'custom-progress-bar' }}
              />
            )}
            <input
              type="file"
              name={"ReportImages"}
              multiple={true}
              placeholder={'test'}
              className="form-control"
              onChange={(event) => {
                handleImageUpload("ReportImages", event);
              }}
            />
          </div>
          <button onClick={handleSaveData} className="btn btn-primary m-5">
            save
          </button>
        </div>


      </div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className="mt-4">
        <Typography variant="h4" gutterBottom>
          {' '}
        </Typography>
        <Button onClick={() => downloadPDF()} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
          Download
        </Button>
      </Stack>
    </>
  );
};

export default Reports;
