import React, { useState } from 'react'
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify'
import makeApiRequest from '../../../api/commonApi';
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
const ResetPassword = () => {
    const [password, setPassword] = useState('')
    const [confirmpassword, setconfrimPassword] = useState('')
    const [passwordError, setPasswordError] = useState('');


    const navigate = useNavigate()
    const id = useSelector(state => state.user.id)

    const resetPassword = async () => {
        setPasswordError('');


        // Password validation
        if (!password || password.length < 4) {
            setPasswordError('Password must be at least 8 character long');
            return;
        }
        try {
            const Response = await makeApiRequest('post', `/auth/resetPassword/${id}`, { password: password, confirm_password: confirmpassword });

            // const Response = await axios.post(`http://localhost:5001/auth/resetPassword/${id}`, { password: password, confirm_password: confirm_password })
            console.log(Response.status)
            if (Response.status == '200') {
                toast.success(Response.data.message)
                navigate('/')
            }

        } catch (error) {
            if (error.response && [401, 400, 404].includes(error.response.status)) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error(error?.message);
            }
        }

    }


    return (
        <>
            <Stack spacing={3}>
                <Typography variant="h4" gutterBottom sx={{ my: 3 }}>
                    Reset Password
                </Typography>
                <TextField
                    name="password"
                    label="passsword"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                    variant='standard'
                />

                <TextField
                    name="password"
                    label="confirm password"
                    value={confirmpassword}

                    onChange={(e) => setconfrimPassword(e.target.value)}

                    variant='standard'

                />
            </Stack>



            <LoadingButton className='mt-4' fullWidth size="large" type="submit" variant="contained" onClick={resetPassword} >
                ResetPassword
            </LoadingButton >



        </>
    )
}

export default ResetPassword