import React, { useEffect, useState } from 'react';
import { Grid, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import makeApiRequest from '../api/commonApi';
import Loader from '../components/Loader';

export default function DashboardAppPage() {
  const { userInfo } = useSelector((state) => state.user);

  const [userData, setUserData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const AllDetails = async () => {
    try {
      setIsLoading(true);
      const data = await makeApiRequest('get', '/summary');
      console.log("data",data);
      setUserData(data.data);
      setUserDetails(data.data.casedata);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    AllDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Quick Support </title>
      </Helmet>
      {isLoading ? (
        <div className=" d-flex justify-content-center align-items-center ">
          <Loader />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            {userInfo.role.role === 'verifier' ? ( 
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Link to="/dashboard/verifierCases/all" style={{ textDecoration: 'none' }}>
                    <AppWidgetSummary
                      title="My Cases "
                      total={userDetails ? `${userDetails.initiated}` : ''}
                      color="warning"
                      icon={'clarity:assign-user-line'}
                    />
                  </Link>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6} md={2.4}>
                  <Link
                    to="/dashboard/allrequest/all"
                    style={{ textDecoration: 'none' }}
                  >
                    <AppWidgetSummary
                      title="Total Case"
                      total={userData?.AllCases || '0'}
                      icon={'solar:case-bold'}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={2.4}>
                  <Link to="/dashboard/allrequest/initiated" style={{ textDecoration: 'none' }}>
                    <AppWidgetSummary
                      title="Total Initiated "
                      total={userDetails ? `${userDetails.initiated}` : ''}
                      color="warning"
                      icon={'clarity:assign-user-line'}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={2.4}>
                  <Link to="/dashboard/allrequest/received" style={{ textDecoration: 'none' }}>
                    <AppWidgetSummary
                      title="Total Received"
                      total={`${userDetails?.received}`}
                      color="success"
                      icon={'solar:card-recive-bold'}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={2.4}>
                  <Link to="/dashboard/allrequest/closed" style={{ textDecoration: 'none' }}>
                    <AppWidgetSummary
                      title="Total Closed"
                      total={userDetails ? `${userDetails.closed}` : ''}
                      color="error"
                      icon={'material-symbols:close'}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={2.4}>
                  <Link to="/dashboard/allrequest/verified" style={{ textDecoration: 'none' }}>
                    <AppWidgetSummary
                      title="Total Verified"
                      total={`${userDetails?.verified}`}
                      icon={'uil:comment-verify'}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={2.4}>
                  <Link to="/dashboard/allrequest/reinitiated" style={{ textDecoration: 'none' }}>
                    <AppWidgetSummary
                      title="Total Reinitiated"
                      total={`${userDetails?.reinitiated}`}
                      icon={'fluent-mdl2:assign'}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={2.4}>
                  <Link to="/dashboard/allrequest/reopened" style={{ textDecoration: 'none' }}>
                    <AppWidgetSummary
                      title="Total Reopened"
                      color="warning"
                      total={`${userDetails?.reopened}`}
                      icon={'material-symbols:reopen-window'}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={2.4}>
                  <Link to="/dashboard/allrequest/resubmitted" style={{ textDecoration: 'none' }}>
                    {' '}
                    <AppWidgetSummary
                      title="Total Resubmitted"
                      color="success"
                      total={`${userDetails?.resubmitted}`}
                      icon={'iconoir:submit-document'}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={2.4}>
                  <Link to="/dashboard/allrequest/reverified" style={{ textDecoration: 'none' }}>
                    {' '}
                    <AppWidgetSummary
                      title="Total Reverified"
                      total={`${userDetails?.reverified}`}
                      color="error"
                      icon={'material-symbols:verified-outline'}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={2.4}>
                  <Link to="/dashboard/allrequest/submitted" style={{ textDecoration: 'none' }}>
                    {' '}
                    <AppWidgetSummary
                      title="Total Submitted"
                      total={`${userDetails?.submitted}`}
                      color="warning"
                      icon={'formkit:submit'}
                    />
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      )}
    </>
  );
}