
import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { Stepper, Step, StepLabel } from '@mui/material';

import Updatesidebar from './updatesidebar';
import UpdateForm from "./UpdateForm"
import makeApiRequest from '../../api/commonApi';

function StepForm() {

    localStorage.setItem('Step',"1");
    localStorage.setItem('activeStep',  1)

  return (
    <>
      <div className="container">
        <div className="pb-4">
          <Link to={`/dashboard/forms`}>
            <ArrowBackIcon />
          </Link>
        </div>
        
        <div className="d-flex">
          <div className="col-3"><Updatesidebar /></div>
          <div className="col-9 ">
            <UpdateForm  />
          </div>
        </div>

        {/* {activeStep > 0 && (
          <button className="m-4 btn btn-outline-primary" onClick={handleBackStep}>
            Back Step
          </button>
        )} */}
        {/* {activeStep < allSteps.length - 1 && (
        <button
          className="m-4 btn btn-outline-success"
          onClick={handleNextStep}
        >
          Next Step
        </button>
        )} */}
      </div>
    </>
  );
}

export default StepForm;
