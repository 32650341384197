import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { fetchUserInfo } from '../../redux/user/userSlice';
import makeApiRequest from '../../api/commonApi';
import Loader from '../Loader';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const EditProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [previmageFile, setPrevImageFile] = useState(null);
  const [userData, setUserData] = useState({
    name: '',
    address: '',
    city: '',
    baselocation: '',
    mobilenumber: '',
    familynumber: '',
    fathername: '',
    adharnumber: '',
    qualification: '',
    village: '',
    tehsil: '',
    state: '',
    mothername: '',
    pancardnumber: '',
    workexperience: '',
    acoountholdername: '',
    bankname: '',
    accountnumber: '',
    ifsccode: '',
    dob: '',
    district: '', 
    Per_Kms_Rate: '', 
    salary: '', 
    working_with_current_agency: '',
    phonePe_name: '', 
    phonePe_number: '', 
    googlePe_name: '', 
    googlePe_number: '', 
    any_Legal_Compliant_against_you: '',
  });

  const [validationError, setValidationError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getAllDetailsById = async () => {
      try {
        setIsLoading(true);
        const details = await makeApiRequest('get', `/userbyId/${id}`);
        setUserData(details.data.user);
        setPrevImageFile(details.data.user.userimage)
        console.log('details', details.data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getAllDetailsById();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'any_Legal_Compliant_against_you') {
      setUserData((prevUserData) => ({
        ...prevUserData,
        any_Legal_Compliant_against_you: value,
      }));
    } else {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
    }
  };

  const backtoMain = () => {
    navigate('/dashboard/profile');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    if (imageFile && imageFile[0]) {
      if (!imageFile[0].type.startsWith('image/')) {
        toast.error('Please select an image file.');
        return;
      }
      formData.append('file', imageFile[0]);
    }

    const fieldsToExclude = previmageFile ? ['role', 'userimage'] : ['role'];

    const filteredUserData = Object.keys(userData)
      .filter((key) => !fieldsToExclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = userData[key];
        return obj;
      }, {});

    Object.entries(filteredUserData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (!/^\d{10}$/.test(userData.mobilenumber)) {
      setValidationError(true);
      return;
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      setIsLoading2(true);
      await makeApiRequest('put', `/updateusers/${id}`, formData, headers);
      dispatch(fetchUserInfo());
      toast.success('Details Updated  successfully!');
      backtoMain();
    } catch (error) {
      console.log(error);
      console.log('Something gone wrong!');
    } finally {
      setIsLoading2(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> EditProfile | Quick Support </title>
      </Helmet>

      {isLoading ? (
        <div className=" d-flex justify-content-center align-items-center ">
          <Loader />
        </div>
      ) : (
        <Grid className="p-3">
          <Paper elevation={20} className="paperStyle">
            <div className="CrossIconParent">
              <CloseIcon onClick={backtoMain} className="iconCross" />
            </div>
            <Grid align="center">
              <h2 className="headerStyle">Edit Profile</h2>
            </Grid>

            <div className="container">
              <form className="row mb-3" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid>
                      <TextField
                        label="Number"
                        variant="standard"
                        value={userData.mobilenumber}
                        fullWidth
                        type="number"
                        name="mobilenumber"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                        error={validationError}
                        helperText={validationError ? 'Mobile number must be 10 digits' : ''}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Name"
                        variant="standard"
                        value={userData.name}
                        fullWidth
                        type="text"
                        name="name"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Father Name"
                        variant="standard"
                        value={userData.fathername}
                        fullWidth
                        type="text"
                        name="fathername"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Base Location"
                        variant="standard"
                        value={userData.baselocation}
                        fullWidth
                        type="text"
                        name="baselocation"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="State"
                        variant="standard"
                        value={userData.state}
                        fullWidth
                        type="text"
                        name="state"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="City"
                        variant="standard"
                        value={userData.city}
                        fullWidth
                        type="text"
                        name="city"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="District"
                        variant="standard"
                        value={userData.district}
                        fullWidth
                        type="text"
                        name="district"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Rate / Salary"
                        variant="standard"
                        value={userData.salary}
                        fullWidth
                        type="text"
                        name="salary"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Total Exprience"
                        variant="standard"
                        value={userData.workexperience}
                        fullWidth
                        type="text"
                        name="workexperience"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="PhonePee Name"
                        variant="standard"
                        value={userData.phonePe_name}
                        fullWidth
                        type="text"
                        name="phonePe_name"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Google Pe Name"
                        variant="standard"
                        value={userData.googlePe_name}
                        fullWidth
                        type="text"
                        name="googlePe_name"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Adhar Number"
                        variant="standard"
                        value={userData.adharnumber}
                        fullWidth
                        type="number"
                        name="adharnumber"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    

                    <Grid>
                      <TextField
                        label="Account Holder Name"
                        variant="standard"
                        value={userData.acoountholdername}
                        fullWidth
                        type="text"
                        name="acoountholdername"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Bank Name"
                        variant="standard"
                        value={userData.bankname}
                        fullWidth
                        type="text"
                        name="bankname"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Qualification"
                        variant="standard"
                        value={userData.qualification}
                        fullWidth
                        type="text"
                        name="qualification"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    {userData.userImage && <img src={userData.userImage} alt="User" className="user-image" />}
                    <Grid>
                      <input
                        accept="image/*"
                        type="file"
                        // accept="image/*"
                        className="my-3"
                        onChange={(e) => setImageFile(e.target.files)}
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="DOB"
                        variant="standard"
                        value={userData.dob}
                        fullWidth
                        type="date"
                        name="ifsccode"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Mother Name"
                        variant="standard"
                        value={userData.mothername || ''}
                        fullWidth
                        type="text"
                        name="mothername"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Address"
                        variant="standard"
                        value={userData.address}
                        fullWidth
                        type="text"
                        name="address"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Tehsil"
                        variant="standard"
                        value={userData.tehsil}
                        fullWidth
                        type="text"
                        name="tehsil"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Village"
                        variant="standard"
                        value={userData.village}
                        fullWidth
                        type="text"
                        name="village"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Per Kms Rate"
                        variant="standard"
                        value={userData.Per_Kms_Rate}
                        fullWidth
                        type="text"
                        name="Per_Kms_Rate"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Current Company"
                        variant="standard"
                        value={userData.working_with_current_agency}
                        fullWidth
                        type="text"
                        name="working_with_current_agency"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Phone Pee Number"
                        variant="standard"
                        value={userData.phonePe_number}
                        fullWidth
                        type="number"
                        name="phonePe_number"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Google Pee Number"
                        variant="standard"
                        value={userData.googlePe_number}
                        fullWidth
                        type="number"
                        name="googlePe_number"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>


                    <Grid>
                      <TextField
                        label="Pan Card Number"
                        variant="standard"
                        value={userData.pancardnumber}
                        fullWidth
                        type="text"
                        name="pancardnumber"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Family Number"
                        variant="standard"
                        value={userData.familynumber}
                        fullWidth
                        type="number"
                        name="familynumber"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="Account Number"
                        variant="standard"
                        value={userData.accountnumber}
                        fullWidth
                        type="number"
                        name="accountnumber"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                    <Grid>
                      <TextField
                        label="IFSC Code"
                        variant="standard"
                        value={userData.ifsccode}
                        fullWidth
                        type="text"
                        name="ifsccode"
                        autoComplete="off"
                        className="my-2"
                        onChange={handleInputChange}
                      // required
                      />
                    </Grid>

                     <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">Any Legal Compliant against you</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="any_Legal_Compliant_against_you"
                        value={userData.any_Legal_Compliant_against_you}
                        onChange={handleInputChange}
                      >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <div className="text-center my-2 mt-4">
                  {isLoading2 ? (
                    <div className=" d-flex justify-content-center align-items-center ">
                      <Loader />
                    </div>
                  ) : (
                    <Button variant="contained" type="submit" className="m-2">
                      Update
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default EditProfile;
