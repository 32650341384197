import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { toast } from 'react-toastify';
import { PDFViewer, Document, Page, Text, View, pdf, Image } from '@react-pdf/renderer';
import { MenuItem, IconButton, Stack, Typography, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import makeApiRequest from '../api/commonApi';
import delteFunction from '../api/deleteFunction';
import Iconify from '../components/iconify';
import Loader from '../components/Loader';
import '../assets/css/offferletter.css'
import DynamicExcelUser from '../components/createExcelSheet/ExportToUserExcel';

const Forms = () => {
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [popoverOpen, setPopoverOpen] = useState([]);
  const [userData, setUserData] = useState([]);
  const [usersToBeExport, setUsersToBeExport] = useState([]);
  const [userLength, setUserLength] = useState(0);
  const [Search, SetSearch] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [pdfBase64, setPdfBase64] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, SetUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offerLetterLoading, setOffereLetterLoading] = useState(false);

  const styles = {
    page: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      padding: 20,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    header: {
      fontSize: 20,
      textAlign: 'center',
      marginBottom: 10,
    },
    bodyText: {
      fontSize: 12,
      textAlign: 'left',
      marginBottom: 10,
    },
    signature: {
      fontSize: 14,
      textAlign: 'left',
      marginTop: 20,
    },
    logo: {
      height: 50,
      width: 50,
    },
    offerHeader: {
      textAlign: 'center',
      fontSize: 15,
      marginTop: 20,
      marginBottom: 20,
    }
  };

  const triggerOffereLetter = async (id, index) => {
    try {
      const details = await makeApiRequest('get', `/userbyId/${id}`);
      SetUserDetails(details.data.user);
      handleCloseMenu(index);
      setIsModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>

          <Text style={styles.header}>Job Offer Letter</Text>

          <Text style={styles.bodyText}>Date: {new Date().toLocaleDateString()}</Text>

          <Text style={styles.bodyText}>Dear {userDetails.name},</Text>

          <Text style={styles.bodyText}>
            We are pleased to offer you the position of jobTitle at companyName. This offer is contingent upon the
            successful completion of a background check and any other pre-employment requirements.
          </Text>

          <Text style={styles.bodyText}>
            Your annual salary will be discussed with our HR department, and other benefits will be provided as per our
            company policies.
          </Text>

          <Text style={styles.bodyText}>
            Please sign and return this letter by {new Date().toLocaleDateString()} to accept this offer.
          </Text>

          <Text style={styles.signature}>Sincerely,</Text>

          <Text style={styles.signature}>[Your Name]</Text>

          {userDetails && userDetails.role && userDetails.role.role === "verifier" ? (
            <>
              <View style={{ flexDirection: 'row', marginBottom: 20, marginTop: 30 }}>

                <Image src="/assets/logo.png" style={styles.logo} />

                <View style={{ marginLeft: 20 }}>
                  <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Quick Support Services</Text>
                  <Text style={{ fontSize: 12 }}>56, Scheme No. 114 Vijay Nagar Indore </Text>
                  <Text style={{ fontSize: 12 }}>Madhya Pradesh 452001</Text>
                </View>
              </View>

              <Text style={styles.offerHeader} >APPLICATION FORM WAIVER – PLEASE READ CAREFULLY</Text>

              <Text style={styles.bodyText}>
                You shall perform with diligence such duties as the post you hold calls for and such other duties that may be
                assigned to you by the management depending upon the exigency of work.
              </Text>

              <Text style={styles.bodyText}>
                You will be on probation for a period of 1 month from the date of joining the company, in the expiry of which
                period you will be confirmed in your post provided your work and conduct are found to be satisfactory. The
                company has the right to extend your probation period for another one month if need be. You shall continue to be
                on probation unless confirmed in writing by the management.
              </Text>

              <Text style={styles.bodyText}>
                During probation, the period of notice required for cessation of employment is 15 days on either side. In case
                of unsatisfactory work or conduct during the probationary period, your services are liable to be terminated at
                any time without notice or without assigning any reasons thereto. In the case of a confirmed employee, the period
                of notice will be 01 months from the date of resignation and after completing the notice period, the F&F amount
                will be credited within 45 days.
              </Text>

              <Text style={styles.bodyText}>
                If an employee fails to give notice, the company has the right to without payment due to the employee up to a
                maximum amount equaling the employee’s ordinary rate of pay for the period of notice. The management reserves the
                right to waive the notice period in case of resignation depending on the merit of the individual case or may insist
                on a notice period in lieu of salary. However, no compensation is payable to you if termination/resignation is
                made due to unsatisfactory work or conduct.
              </Text>

              <Text style={styles.bodyText}>
                You shall not accept other employment with any other concern in any capacity while you are employed in Quick
                Support Services.
              </Text>

              <Text style={styles.bodyText}>
                Your appointment is subject to satisfactory reference checks, documents provided by you, and clearance from any
                secrecy/service agreements that you may have executed, which could have a bearing on your working with us.
              </Text>

              <Text style={styles.bodyText}>
                You will be eligible for leave, you can apply only 01 EL in a month, which is not carried forward after 02 months,
                and if you take more leave other than EL company has the right to deduct that day’s salary without intimation, if
                you take leave then the company has the right to deduct that day’s salary.
              </Text>

              <Text style={styles.bodyText}>
                Only Sunday will be counted as a holiday other than this company will inform you if any government holiday comes.
              </Text>

              <Text style={styles.bodyText}>
                Need to check all due diligence before publishing the report which is your responsibility.
              </Text>

              <Text style={styles.bodyText}>
                You never apply for a Job in any department within 1 year, which company/Bank is impaneled with Quick Support
                Services and after 01 year you need the NOC form the agency.
              </Text>

              <Text style={styles.bodyText}>
                All other provisions will be according to the service rules of the company. In the absence of any particular rule,
                the relevant statutory Act shall apply.
              </Text>

              <Text style={styles.bodyText}>
                The Company reserves the right to change or modify any rule that it might deem fit for the benefit of the organization
                without any prior intimation.
              </Text>

              <Text style={styles.bodyText}>
                At any stage of the application or selection process, if any information provided by you, is found to be false,
                untruthful, or misleading, Quick Support Services. has all rights to take necessary action including termination.
              </Text>

              <Text style={styles.signature}>
                Employee Signature
              </Text>

              <Text style={{ marginTop: 30, fontSize: 12 }}>Note – Please share Aadhar Card, PAN Card , 10th 12th and Graduate Mark sheet , Salary Bank Passbook.  </Text>

            </>
          ) : (
            <>
              <Text style={{ fontSize: 12, marginBottom: 20, marginTop: 20 }}> Important Instructions : - </Text>
              <Text style={styles.bodyText}>
                Field executives should be formally dressed and polite during all visits (Pre/Post)
              </Text>

              <Text style={styles.bodyText}>
                The executive must wear a face mask during all visits, if possible, try to sanitize his hand in front of the customer.
              </Text>

              <Text style={styles.bodyText}>
                The field executive must take a selfie in front of the customer's house. This is a mandatory requirement.
              </Text>

              <Text style={styles.bodyText}>
                In each untraceable address, Audio or Video recordings are a must when FE does the profile checks or talks to Local people.
              </Text>

              <Text style={styles.bodyText}>
                Don’t misbehave with customers during the visit and if any customer misbehaves with you then you will immediately inform the agency.
              </Text>

              <Text style={styles.bodyText}>
                Don’t do any kind of financial transaction or demand money from the customer and if any kind of incident happens during the visit/the field then you need to inform the agency and if the agency found any involvement of yours, the agency has the right to take appropriate action and agency will not be responsible for that.
              </Text>

              <Text style={styles.bodyText}>
                Be insure before publishing the report to agency that all details is correct as per your end.
              </Text>

              <Text style={styles.bodyText}>
                Don’t share any kind of information (customer’s details) with anyone.
              </Text>

              <Text style={styles.bodyText}>
                You have to do the work given by us; you cannot give this work to any other person.
              </Text>

              <Text style={styles.signature}>
                I agree to the above terms, and I will strictly abide by them.
              </Text>

              <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                <Text style={{ fontSize: 12 }}>Date:</Text>
                <Text style={{ fontSize: 12 }}>Name and Signature</Text>
              </View>
            </>
          )}

        </View>
      </Page>
    </Document>
  );

  const getUsers = async (page) => {
    try {
      setIsLoading(true);
      const data = await makeApiRequest('get', `/Allusers?page=${page + 1}&searchTerm=${Search}&limit=${rowsPerPage}`);
      setUserData(data.data.data);
      setUserLength(data.data.totalUser);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUsersToBeExport = async () => {
    try {
      const data = await makeApiRequest('get', `/Allusers?excelData=true`);
      const usersData = data.data.data;
      setUsersToBeExport(usersData);
    } catch (error) {
      console.log(error);
    }
  };

  const NewUserCreate = () => {
    navigate('/dashboard/adduser');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const EditUserDetails = (id) => {
    navigate(`/dashboard/edituserdata?id=${id}`);
  };

  const DeleteUser = (user, index) => {
    handleCloseMenu(index);
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await delteFunction('/deleteusers', userToDelete);
      const updatedUserData = userData.filter((user) => user._id !== userToDelete);
      setUserData(updatedUserData);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Delete error:', error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleOpenMenu = (event, index) => {
    const newPopoverOpen = [...popoverOpen];
    newPopoverOpen[index] = event.currentTarget;
    setPopoverOpen(newPopoverOpen);
  };

  const handleCloseMenu = (index) => {
    const newPopoverOpen = [...popoverOpen];
    newPopoverOpen[index] = null;
    setPopoverOpen(newPopoverOpen);
  };

  const calculateSerialNumber = (index) => page * rowsPerPage + index + 1;

  useEffect(() => {
    getUsers(page);
  }, [page, Search, rowsPerPage]); // eslint-disable-line

  useEffect(() => {
    getUsersToBeExport();
  }, []); // eslint-disable-line



  useEffect(() => {
    const generatePdf = async () => {
      const pdfBlob = await pdf(<MyDocument />).toBlob();
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        setPdfBase64(base64String);
      };
      reader.readAsDataURL(pdfBlob);
    };
    generatePdf();
  }, [userDetails]);

  const sendPdfToBackend = async () => {
    setIsLoading(true);
    const data = {
      pdfBase64,
      email: userDetails.email,
      subject: 'Offer Letter',
      text: 'kindly acknowledge offer letter',
    };
    try {
      setIsLoading(true);
      // Send the PDF Base64 data to the backend via an API call
      const response = await makeApiRequest('post', '/sendOfferLetter', data);
      toast.success(response?.data?.message);
      setIsModalOpen(false);
      getUsers(page);
    } catch (error) {
      console.log('error', error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title> Users | Quick Support </title>
        </Helmet>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>

          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={NewUserCreate}>
            New User
          </Button>
        </Stack>

        <Grid className="mb-3">
          <TextField
            type="search"
            autoComplete="off"
            id="search"
            label="Search"
            value={Search}
            onChange={(e) => {
              setPage(0);
              SetSearch(e.target.value);
            }}
          />
        </Grid>

        <TableContainer component={Paper} className="TableParent">
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow className="TableheadStyle">
                <TableCell className="HeadCellStyle">S.No</TableCell>
                <TableCell align="right" className="HeadCellStyle">
                  Name
                </TableCell>
                <TableCell align="right" className="HeadCellStyle">
                  Address
                </TableCell>
                <TableCell align="right" className="HeadCellStyle">
                  Number
                </TableCell>
                <TableCell align="right" className="HeadCellStyle">
                  City
                </TableCell>
                <TableCell align="right" className="HeadCellStyle">
                  Role
                </TableCell>

                {['admin', 'super-admin'].includes(userInfo?.role.role.toLowerCase()) ? (
                  <TableCell align="right" className="HeadCellStyle">
                    Action
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {userData?.map((item, index) => (
                    <TableRow className="TableRowStyle" key={item._id}>
                      <TableCell component="th" scope="row" className="textCentre">
                        {calculateSerialNumber(index)}
                      </TableCell>
                      <TableCell className="textCentre">
                        {item.name
                          ? item.name
                            .split(' ')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')
                          : ''}
                      </TableCell>
                      <TableCell className="textCentre">
                        {' '}
                        {item.address && item.address.charAt(0).toUpperCase() + item.address.slice(1)}
                      </TableCell>
                      <TableCell className="textCentre">{item.mobilenumber}</TableCell>
                      <TableCell className="textCentre">
                        {item.city && item.city.charAt(0).toUpperCase() + item.city.slice(1)}
                      </TableCell>
                      <TableCell className="textCentre">
                        {item?.role?.role && typeof item?.role?.role === 'string'
                          ? item?.role?.role.charAt(0).toUpperCase() + item?.role?.role.slice(1)
                          : ''}
                      </TableCell>
                      {['admin', 'super-admin'].includes(userInfo?.role.role.toLowerCase()) &&
                        item?.role?.role.toLowerCase() !== 'super-admin' ? (
                        <TableCell className="textCentre">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, index)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>

                          <Popover
                            open={Boolean(popoverOpen[index])}
                            anchorEl={popoverOpen[index]}
                            onClose={() => handleCloseMenu(index)}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            <MenuItem onClick={() => EditUserDetails(item._id)}>
                              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                              Edit
                            </MenuItem>
                            <MenuItem sx={{ color: 'error.main' }} onClick={() => DeleteUser(item._id, index)}>
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>
                            {item?.offerLetter !== 'sent' && (
                              <MenuItem onClick={() => triggerOffereLetter(item._id, index)}>
                                <Iconify icon={'icon-park-outline:agreement'} sx={{ mr: 2 }} />
                                Offer Letter
                              </MenuItem>
                            )}
                          </Popover>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}

                  {userData?.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={6} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
          labelRowsPerPage="Rows per page"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
                    
      {usersToBeExport.length > 0 ? <DynamicExcelUser data={usersToBeExport} /> : <div><span>Preparing excel data</span>  <Loader size={20} /></div>}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="pdf-modal"
        aria-describedby="pdf-modal-description"
        className="pdfModalParent"
        fullWidth // Expand to full width
        maxWidth="md" // Set maximum width
      >
        <DialogContent
          sx={{
            width: '100%', // Full width for the content
            maxWidth: '100%', // Match the dialog's width
            p: 2, // Padding for content
            borderRadius: '8px',
          }}
        >

          {/* {isLoading ? (
            <Loader />
          ) : (
            <> */}
              <PDFViewer width="100%" height="400">
                <MyDocument />
              </PDFViewer>


              <div style={{ textAlign: 'center', margin: '16px 0' }}>
                {offerLetterLoading ? (
            <CircularProgress size={24} />
          ) : (
                <Button variant="contained" style={{ margin: 'auto' }} onClick={sendPdfToBackend}>
                  Send Offer Letter via email
                </Button>
                )}
              </div>
            {/* </>
          )} */}
        </DialogContent>
      </Dialog >
    </>
  );
};

export default Forms;
