import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { MenuItem, IconButton, Stack, Typography, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Modal } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import makeApiRequest from '../../api/commonApi';
import delteFunction from '../../api/deleteFunction';
import Loader from '../Loader';
import Iconify from '../iconify';

const ViewContentDocument = () => {
  const navigate = useNavigate();
  const { type } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [popoverOpen, setPopoverOpen] = useState([]);
  const [userData, setUserData] = useState([]);
  const [totalcase, setTotalcase] = useState(0);
  const [Search, SetSearch] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userDetails, SetUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState({});
  const [formData, setFormData] = useState({
    casename: '',
  });

  const isSubtypeVaid = (casename) => {
    return casename.trim() !== '';
  }

  const UserDetails = async (page) => {
    try {
      setIsLoading(true);
      const data = await makeApiRequest('get', `/${type}?limit=${rowsPerPage}&page=${page + 1}&searchTerm=${Search}`);
      setUserData(data.data.data);
      setTotalcase(data.data.TotalCase);
      console.log('User list at user component == >  ', data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const NewUserCreate = () => {
    navigate('/dashboard/adduser');
  };

  const EditUserDetails = async (id , index) => {
    try {
      const roleToEdit = userData.find((user) => user._id === id);
      console.log('roleToEdit===', roleToEdit.casename);
      setFormData({ casename: roleToEdit.casename });
      setEditingUser(roleToEdit);
      setModalOpen(true);
      handleCloseMenu(index);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const DeleteUser = (user, index) => {
    handleCloseMenu(index);
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!isSubtypeVaid(formData.casename)) {
      toast.error('Please enter valid  casetype.');
      return;
    }

    try {
      if (type === 'document-case') {
        await makeApiRequest('put', `/case-document/${editingUser._id}`, formData);
      } else {
        await makeApiRequest('put', `/case-field/${editingUser._id}`, formData);
      }
      toast.success('Subtype updated successfully!');
      handleCloseModal();
      UserDetails();
    } catch (error) {
      console.error('API error:', error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      if (type === 'document-case') {
        await delteFunction(`/case-document`, userToDelete);
      } else {
        await delteFunction(`/case-field`, userToDelete);
      }
      const updatedUserData = userData.filter((user) => user._id !== userToDelete);
      setUserData(updatedUserData);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Delete error:', error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleOpenMenu = (event, index) => {
    const newPopoverOpen = [...popoverOpen];
    newPopoverOpen[index] = event.currentTarget;
    setPopoverOpen(newPopoverOpen);
  };

  const handleCloseMenu = (index) => {
    const newPopoverOpen = [...popoverOpen];
    newPopoverOpen[index] = null;
    setPopoverOpen(newPopoverOpen);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const calculateSerialNumber = (index) => page * rowsPerPage + index + 1;

  useEffect(() => {
    UserDetails(page);
  }, [userDetails, Search, page, rowsPerPage]);


  return (
    <>
      <div>
        <Helmet>
          <title> Types | Quick Support </title>
        </Helmet>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {type === 'document-case' ? (
            <Typography variant="h4" gutterBottom>
              Document Subtypes
            </Typography>
          ) : (
            <div>
              <Typography variant="h4" gutterBottom>
                Profile Subtypes
              </Typography>
            </div>
          )}

          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={NewUserCreate}>
            New User
          </Button> */}
        </Stack>

        <Grid className="mb-3">
          <TextField
            type="search"
            autoComplete="off"
            id="search"
            label="Search"
            value={Search}
            onChange={(e) => {
              setPage(0)
              SetSearch(e.target.value)
            }}
          />
        </Grid>
        <div className="p-2">
          <Link to={`/dashboard/type`}>
            <ArrowBackIcon />
          </Link>
        </div>

        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalOpen}>
            <div>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  minWidth: 300,
                  outline: 'none',
                  borderRadius: 4,
                }}
              >
                <form onSubmit={handleFormSubmit}>
                  <TextField
                    fullWidth
                    label="Edit Subtype"
                    variant="outlined"
                    name="casename"
                    value={formData.casename}
                    required
                    onChange={(event) => handleFormChange('casename', event.target.value)}
                  />

                  <Button variant="contained" onClick={handleCloseModal} sx={{ mt: 2 }}>
                    Cancel
                  </Button>

                  <Button variant="contained" color="primary" type="submit" sx={{ mt: 2, ml: 2 }}>
                    Update
                  </Button>
                </form>
              </Box>
            </div>
          </Fade>
        </Modal>

        <TableContainer component={Paper} className="TableParent">
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow className="TableheadStyle">
                <TableCell className="HeadCellStyle">S.No</TableCell>
                <TableCell align="right" className="HeadCellStyle">
                  Subtype Name
                </TableCell>

                <TableCell align="right" className="HeadCellStyle">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {userData.map((item, index) => (
                    <TableRow className="TableRowStyle" key={item._id}>
                      <TableCell component="th" scope="row" className="textCentre">
                        {calculateSerialNumber(index)}
                      </TableCell>
                      <TableCell className="textCentre">
                        {item.casename
                          ? item.casename
                            .split(' ')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')
                          : ''}
                      </TableCell>

                      <TableCell className="textCentre">
                        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, index)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>

                        <Popover
                          open={Boolean(popoverOpen[index])}
                          anchorEl={popoverOpen[index]}
                          onClose={() => handleCloseMenu(index)}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              width: 140,
                              '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                              },
                            },
                          }}
                        >
                          <MenuItem onClick={() => EditUserDetails(item._id , index)}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                          </MenuItem>
                          <MenuItem sx={{ color: 'error.main' }} onClick={() => DeleteUser(item._id , index)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            Delete
                          </MenuItem>
                        </Popover>
                      </TableCell>
                    </TableRow>
                  ))}

                  {userData.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={6} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalcase}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
          labelRowsPerPage="Rows per page"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this sub-type ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewContentDocument;
