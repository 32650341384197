import React, { useState, useEffect } from 'react';
import { Button, Grid, IconButton, InputAdornment, Paper, TextField, } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/form.css';
import CloseIcon from '@mui/icons-material/Close';
import makeApiRequest from '../../api/commonApi';
import Loader from '../Loader';
import Iconify from '../iconify';

const AddFrom = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState('');
  const [userRole, setUserRole] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [bankList, setBankList] = useState([])
  const [managerOfBank, setmanagerOfBank] = useState('')
  const [showBank, setShowBank] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    address: '',
    number: '',
    city: '',
  });

  const getUserRole = async () => {
    try {
      setIsLoading(true);
      const userData = await makeApiRequest('get', '/role');
      console.log('user role are here ', userData.data.data);
      setUserRole(userData.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllBanks = async () => {
    try {
      const response = await makeApiRequest('get', '/bank');
      setBankList(response.data.data)
      console.log('banks at user page ', response.data.data)
    } catch (error) {
      console.error('Failed to fetch banks:', error);
    }
  };



  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };



  const isMobileNumberValid = (mobileNumber) => {
    const mobileNumberRegex = /^[0-9]{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const backtoMain = () => {
    navigate('/dashboard/user');
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    const roleName = userRole.filter((item) => item._id === event.target.value)[0]

    if ((roleName && roleName.role === 'rcu-manager') || (roleName && roleName.role === 'backend-executive')) {
      setShowBank(true)
    } else {
      setShowBank(false);
    }
  };


  const handleChangeBank = (event) => {
    setmanagerOfBank(event.target.value)
    console.log('selected role of user ', event.target.value);
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        address: formData.address,
        city: formData.city,
        mobilenumber: formData.number,
        role,
      };

      if (!formData.name.trim()) {
        toast.error('Please enter user name');
        return;
      }

      if (!isEmailValid(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
      }

      if (!formData.password.trim()) {
        toast.error("Please enter password");
        return;
      }

      if (!formData.address.trim()) {
        toast.error('Please enter address ');
        return;
      }

      if (!formData.city) {
        toast.error('Please enter city ');
        return;
      }

      if (!isMobileNumberValid(formData.number)) {
        toast.error('Please Enter valid number');
        return;
      }

      if (!role) {
        toast.error('Please select a role');
        return;
      }

      if (managerOfBank) {
        data.managerOfBank = managerOfBank;
      }

      try {
        setIsLoading2(true);
        const response = await makeApiRequest('post', '/register', data);
        console.log(response);
        if (response.status === 201) {
          toast.success('User added successfully!');
          navigate('/dashboard/user');
        } else {
          toast.error('Failed to add user.');
          console.log('error');
        }
      } catch (error) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      } finally {
        setIsLoading2(false);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserRole();
    getAllBanks()
  }, []);

  return (
    <>
      <Helmet>
        <title> AddUser | Quick Support </title>
      </Helmet>
      {isLoading ? (
        <div className=" d-flex justify-content-center align-items-center ">
          <Loader />
        </div>
      ) : (
        <Grid>
          <Paper elevation={20} className="paperStyle">
            <div className="CrossIconParent">
              <CloseIcon onClick={backtoMain} className="iconCross" />
            </div>
            <Grid align="center">
              <h2 className="headerStyle">Add User</h2>
            </Grid>
            <form className="row" onSubmit={onSubmit}>
              <Grid>
                <TextField
                  label="User Name"
                  variant="standard"
                  fullWidth
                  type="text"
                  name="name"
                  autoComplete="off"
                  className="my-2"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  label="User Email"
                  fullWidth
                  variant="standard"
                  type="text"
                  name="email"
                  autoComplete="off"
                  className="my-2"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <TextField
                  label="Password"
                  fullWidth
                  variant="standard"
                  type={showPassword ? 'text' : 'password'}
                  required
                  name="password"
                  autoComplete="off"
                  className="my-2"
                  value={formData.password}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Address"
                  fullWidth
                  variant="standard"
                  type="text"
                  required
                  name="address"
                  autoComplete="off"
                  className="my-2"
                  value={formData.address}
                  onChange={handleInputChange}
                />
                <TextField
                  label="City"
                  fullWidth
                  variant="standard"
                  type="text"
                  required
                  name="city"
                  autoComplete="off"
                  className="my-2"
                  value={formData.city}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Contact Number"
                  fullWidth
                  variant="standard"
                  type="number"
                  required
                  name="number"
                  autoComplete="off"
                  className="my-2"
                  value={formData.number}
                  onChange={handleInputChange}
                />
                <Grid item xs={12}>
                  <div className="dropdown mt-2">
                    <select className="form-select" value={role} onChange={handleRoleChange}>
                      <option value="">Select Role</option>
                      {userRole
                        .filter((classData) => classData.role !== 'super-admin')
                        .map((classData) => (
                          <option key={classData._id} value={classData._id}>
                            {classData.role && classData.role.charAt(0).toUpperCase() + classData.role.slice(1)}
                          </option>
                        ))}
                    </select>
                  </div>
                </Grid>


                {showBank && (
                  <Grid item xs={12}>
                    <div className="dropdown mt-2">
                      <select className="form-select" value={managerOfBank} onChange={handleChangeBank}>
                        <option value="">Select Bank</option>
                        {bankList.map((list) => (
                          <option key={list._id} value={list._id}>
                            {list.bankname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}

                <div className="text-center my-2 mt-4">
                  {isLoading2 ? (
                    <div className=" d-flex justify-content-center align-items-center ">
                      <Loader />
                    </div>
                  ) : (
                    <Button variant="contained" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </Grid>
            </form>
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default AddFrom;
