import React, { useState } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/form.css';
import { Helmet } from 'react-helmet-async';
import CloseIcon from '@mui/icons-material/Close';
import makeApiRequest from '../../api/commonApi';

const CreateType = () => {
  const navigate = useNavigate();
  const [role , setRole] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    branch: '',
    email: '',
    address: '',
  });

  const [type, setType] = useState('');


  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  console.log(handleRoleChange)
  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const backtoMain = () => {
    navigate('/dashboard/type');
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        bankname: formData.name,
        bankemail: formData.email,
        address: formData.address,
        branch: formData.branch,
      };

      if (!isEmailValid(formData.email)) {
        toast.error('Please enter a valid email address');
        return;
      }

      try {
        const response = await makeApiRequest('post', '/bank', data);
        console.log(response);
        if (response.status === 201) {
          toast.success('Bank added successfully!');
          navigate('/dashboard/banklist');
        } else {
          toast.error('Failed to add user.');
          console.log('error');
        }
      } catch (error) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title> AddType | Quick Support </title>
      </Helmet>
      <Grid>
        <Paper elevation={20} className="paperStyle">
          <div className="CrossIconParent">
            <CloseIcon onClick={backtoMain} className="iconCross" />
          </div>
          <Grid align="center">
            <h2 className="headerStyle">Add Typee</h2>
          </Grid>

          <form className="row" onSubmit={onSubmit}>
            <Grid>
            <Grid item xs={12}>
                <div className="dropdown mt-2">
                  <select className="form-select" value={type} onChange={handleRoleChange}>
                    <option value="">Select  Type</option>
                    <option>Demo 1</option>
                    <option>Demo 2</option>
                    <option>Demo 3</option>
                  </select>
                </div>
              </Grid>
              <div className="text-center my-2 mt-4">
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </div>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </>
  );
};

export default CreateType;