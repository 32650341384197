import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';



const WhatApp = () => {

  const { userInfo } = useSelector((state) => state.user);
  console.log('user details at profile component', userInfo);


  const redirectWhatsApp = () => {
    const phoneNumber = '+911236547895'; // Replace with the desired phone number
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  }

  const dataa = `Hello,\nThis is a custom message with a new line.\nThank you! \n https://pixabay.com/images/search/website/ \n city ${userInfo.city}   `;

  return (
    <div>
      <h2>Hello, Welcome to  WhatsApp component</h2>
      <Button onClick={redirectWhatsApp}>
        <WhatsAppIcon />
      </Button>
      <h3>Intereact with  user at Whatsapp </h3>
      <ReactWhatsapp number="911236547895" message={dataa} > <Button variant="outlined">Send</Button>  </ReactWhatsapp>

    </div>
  )
}

export default WhatApp;