import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import VerifierCases from './pages/VerifierCases';
import Page404 from './pages/Page404';
import Forms from './pages/Forms'
import AddFrom from './components/admin/AddForm'
import EditUserData from './components/admin/EditUserData';
import StepData from './pages/showForm/step';
import AddBankForm from './components/admin/AddBankForm';
import BankList from './components/admin/BankList';
import EditBankDetails from './components/admin/EditBankDetails';
import UpdateStepData from './pages/updateDaynamicFrom/updatestep';
// import CreateRole from './components/admin/CreateRole';
import Reports from './components/admin/Reports';
import ProfilePage from './components/admin/ProfilePage';
import EditProfile from './components/admin/EditProfile';
import WhatApp from './components/admin/WhatApp';
// Request
import RequestDetails from './pages/Request/RequestDetails';
import EditRequest from './pages/Request/EditRequest';
import AllRequest from './pages/Request/AllRequest';
import Type from './components/admin/Type';
import CreateType from './components/admin/CreateType';
import CaseFormsList from './pages/CaseForms';
import Subtypelist from './pages/case-assignment/subtypelist';
import CaseFormViwer from './pages/showcaseform/caseformview';
import CaseFormBuilder from './pages/CaseForm/caseformbuilder';
import CaseFormViewerUpdater from './pages/updatecaseform/updatecaseform';
import Testing from './pages/testing';
import PdfGenerator from './pages/PdfGenerator';
import ViewContentDocument from './components/admin/ViewContentDocument';
import Showsubcasedetails from './pages/CaseReport/Createreort';
import FormBuilder from './pages/DaynamicForm/FormBuilder';
import UpdateForm from './pages/updateDaynamicFrom/UpdateForm';
import ForgetForm from './sections/auth/login/ForgetForm';
import Changepassword from './sections/@dashboard/app/Changepassword';
// import { element } from 'prop-types';
// ----------------------------------------------------------------------
const mainRoutes = [
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: 'app', element: <DashboardAppPage /> },
      { path: 'verifierCases/:cases', element: <VerifierCases /> },
      { path: 'user', element: <UserPage /> },
      { path: 'changepassword', element: <Changepassword /> },
      { path: 'products', element: <ProductsPage /> },
      { path: 'blog', element: <BlogPage /> },
      { path: 'forms', element: <Forms /> },
      { path: 'adduser', element: <AddFrom /> },
      { path: 'edituserdata', element: <EditUserData /> },
      { path: 'add-form', element: <FormBuilder /> },
      { path: 'view-form/:bankId', element: <StepData /> },
      { path: 'addbank', element: <AddBankForm /> },
      { path: 'banklist', element: <BankList /> },
      { path: 'editbank', element: <EditBankDetails /> },
      // { path: 'createrole', element: <CreateRole /> },
      { path: 'update-form/:bankId', element: <UpdateForm /> },

      { path: 'report/:caseId', element: <Reports /> },
      { path: 'profile', element: <ProfilePage /> },
      { path: 'editprofile', element: <EditProfile /> },
      { path: 'whatapp', element: <WhatApp /> },
      { path: 'type', element: <Type /> },
      { path: 'createtype', element: <CreateType /> },
      // Request
      { path: 'formData/:loanId', element: <RequestDetails /> },
      { path: 'editrequest/:loanId', element: <EditRequest /> },
      { path: 'allrequest/:cases', element: <AllRequest /> },
      { path: 'requestDetails/:loanId', element: <RequestDetails /> },
      { path: 'requestEdit/:loanId', element: <EditRequest /> },

      // subtype case form
      { path: 'caseform/:type', element: <CaseFormBuilder /> },
      { path: ':type/Case-view-form/:Id', element: <CaseFormViwer /> },
      { path: 'Case-forms/:type', element: <CaseFormsList /> },
      { path: 'update-case-form/:type/:Id', element: <CaseFormViewerUpdater /> },
      { path: 'testing', element: <Testing /> },
      { path: 'generatePdf', element: <PdfGenerator /> },
      { path: 'view-sub-type-details/:type', element: <ViewContentDocument /> },

      // testing
      { path: 'subtypeformlist/:caseId', element: <Subtypelist /> },
      { path: 'reportgenerate/:caseId', element: < Showsubcasedetails /> },


    ],
  },
  {
    element: <SimpleLayout />,
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/loghttps://meet.google.com/gvt-ohyt-fdgin',
    element: <Navigate to="/dashboard/app" replace />,
  },
  {
    path: '*',
    element: <Navigate to="/404" />,
  },
]

const routePermissions = {
  admin: {
    // Admin can access all routes
    allowAll: true,
  },
  'super-admin': {
    // Super-admin can access all routes
    allowAll: true,
  },
  'backend-executive': {
    '/dashboard/user': false,
    '/dashboard/adduser': false,
    '/dashboard/edituserdata': false,
    '/dashboard/addbank': false,
  },
  verifier: {
    // Verifier-specific permissions
    '/dashboard/myCases': true,
    '/dashboard/user': false,
    '/dashboard/adduser': false,
    '/dashboard/report': false,
    '/dashboard/banklist': false,
    '/dashboard/addbank': false,
    '/dashboard/forms': false,
    '/dashboard/allrequest': false,
  },
  'rcu-manager': {
    // RUC Manager-specific permissions
    // Define permissions for RUC Manager here if needed
    '/dashboard/user': false,
    '/dashboard/forms': false,
    '/dashboard/banklist': false,
  },
  'agency-manager': {
    // Agency Manager-specific permissions
    // Define permissions for Agency Manager here if needed
  },
};

const isRouteAllowed = (route, userRole) => {
  const rolePermissions = routePermissions[userRole];

  if (!rolePermissions) {
    // Handle unknown roles here (e.g., deny access)
    return false;
  }

  const routePath = `/dashboard/${route.path}`;

  if (rolePermissions.allowAll) {
    // Role is allowed to access all routes
    return true;
  }


  // Check if the current route is explicitly allowed or denied for the role
  return rolePermissions[routePath] !== false;
};


export default function Router() {
  const { userInfo } = useSelector((state) => state.user);
  // console.log("userInfo ===>>", userInfo)

  // Filter routes based on user roles
  const filterRoutes = mainRoutes.map((route) => {
    if (route.children && userInfo) {
      const filteredChildren = route.children.filter((childRoute) => isRouteAllowed(childRoute, userInfo?.role?.role?.toLowerCase()));
      return { ...route, children: filteredChildren };
    }
    return route;
  })

  const routes = useRoutes(filterRoutes);
  return routes;
}