import React, { useState } from 'react'
import { Container, Typography, TextField, Button, Grid, InputAdornment, IconButton } from '@mui/material';
import makeApiRequest from '../../../api/commonApi';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Iconify from '../../../components/iconify';

const Changepassword = () => {
    const navigate = useNavigate()
    const [oldPassword, setOldpassword] = useState('')
    const [newpassword, setNewpassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const changePassowrd = async (e) => {

        e.preventDefault();
        try {
            // Password validation
            if (!newpassword || newpassword < 4) {
                setPasswordError('Password must be at least 8 character long');
                return;
            }

            if (newpassword === confirmPassword) {
                const Response = await makeApiRequest('post', '/auth/changePassword', { old_Password: oldPassword, new_Password: newpassword });

                if (Response.status === 200) {
                    toast.success(Response.data.message)
                    navigate('/dashboard/app')
                }

            } else {
                toast.error("Password and confirm password should be same.")
            }

        } catch (error) {

            if (error.response && [401, 400, 404].includes(error.response.status)) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error(error?.message);
            }
        }

    }

    return (
        <>
            <Container maxWidth="sm">
                <Typography variant="h4" align="center" gutterBottom>
                    Change Password
                </Typography>
                {/* <form > */}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            id="oldPassword"
                            label="Old Password"
                            name="oldPassword"
                            onChange={(e) => setOldpassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            onChange={(e) => setNewpassword(e.target.value)}
                            error={Boolean(passwordError)}
                            helperText={passwordError}
                            id="newPassword"
                            label="New Password"
                            name="newPassword"
                            type={showPassword ? 'text' : 'password'}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            onChange={(e) => setConfirmPassword(e.target.value)}

                            id="confirmPassword"
                            label="Confirm New Password"
                            name="confirmPassword"
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" fullWidth variant="contained" color="primary" onClick={changePassowrd}>
                            Change Password
                        </Button>
                    </Grid>
                </Grid>
                {/* </form> */}
            </Container>
        </>
    )
}

export default Changepassword