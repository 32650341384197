import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Tooltip,
  TextField,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import Iconify from '../../components/iconify';
import './FormBuilder.css';
import makeApiRequest from '../../api/commonApi';
import MainSidebar from '../sidebar/Sidebar';

const FormBuilder = () => {
  const history = useNavigate();
  const stepname = '1';
  const [openPopup, setOpenPopup] = useState(false);
  const [fields, setFields] = useState([{ type: 'input-text', label: 'Text Field', FieldAccess: [] }]);
  const [formName, setFormName] = useState('');
  const [Banks, setBanks] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState('');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [newLabel, setNewLabel] = useState('');
  const [fieldIndex, setFieldIndex] = useState(-1);
  const [placeholderDialog, setPlaceHolderDialog] = useState(false);
  const [newPlaceHolder, setNewPlaceholder] = useState('');
  const [PlaceHolderIndex, setPlaceHolderIndex] = useState(-1);
  const [openDemoModal, setOpenDemoModal] = useState(false);
  const [optionName, setOptionName] = useState('');
  const [selectedValues, setSelectedValues] = useState('');
  const [index, setIndex] = useState(0);
  const [optionIndex, setOptionIndex] = useState(0);
  // const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    makeApiRequest('get', '/role-for-create-product')
      .then((response) => {
        const roleOptions = response.data.data?.map((role) => role);
        setUserRoles(roleOptions);
      })
      .catch((error) => {
        console.error('Error fetching roles:', error);
      });
  }, []);

  const handleOpenDialogChekBox = (index, selectedCheckboxValues) => {
    setSelectedValues(selectedCheckboxValues);
    setFieldIndex(index);
    setShowDialog(true);
  };

  const handleCloseDialogChekBox = () => {
    setShowDialog(false);
    setTimeout(() => {
      setSelectedValues([]);
      setFieldIndex(-1);
    }, 100);
  };

  const handleOpenDialog = (index, label) => {
    setFieldIndex(index);
    setNewLabel(label);
    setDialogOpen(true);
  };

  // placeholder
  const handelOpenForPlaceHolder = (index, placeholder) => {
    setPlaceHolderIndex(index);
    setNewPlaceholder(placeholder);
    setPlaceHolderDialog(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // placeholder
  const handleCloseDialogPlaceholder = () => {
    setPlaceHolderDialog(false);
  };

  // old
  // const handleLabelChange = () => {
  //   if (newLabel) {
  //     // cheking inn currnt step state
  //     const isUniqueLabelInCurrentStep = fields.every((field, index) => {
  //       return index === fieldIndex || field.label !== newLabel;
  //     });
  //     // if not in cuuent step (fields state) then cheking in localstoreage
  //     const allFields = JSON.parse(localStorage.getItem('layouts'))?.steps.flatMap((step) => step.fields) || [];
  //     const isUniqueLabelAcrossAllSteps = allFields.every((field) => field.label !== newLabel);
  //     // if not in localstorage and not in field state then add new field
  //     if (isUniqueLabelInCurrentStep && isUniqueLabelAcrossAllSteps) {
  //       handleFieldLabelChange(fieldIndex, newLabel);
  //       handleCloseDialog();
  //     } else {
  //       toast.error('Field name should be unique.');
  //     }
  //   }
  // };

  // new
  const handleLabelChange = () => {
    if (newLabel) {
      // Checking in current step state
      const isUniqueLabelInCurrentStep = fields.every((field, index) => index === fieldIndex || field.label !== newLabel);
  
      // If not in current step (fields state), then add new field
      if (isUniqueLabelInCurrentStep) {
        handleFieldLabelChange(fieldIndex, newLabel);
        handleCloseDialog();
      } else {
        toast.error('Field name should be unique.');
      }
    }
  };
  

  // placeholder
  const handlePlaceholderChange = () => {
    if (newPlaceHolder) {
      handleFieldPlaceholderChange(PlaceHolderIndex, newPlaceHolder);
      handleCloseDialogPlaceholder();
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const field = event.dataTransfer.getData('field');
    setFields((prevFields) => [...prevFields, { type: field, label: field }]);
  };

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await makeApiRequest('get', '/bank');
        if (response.status === 200) {
          setBanks(response.data.data);
        }
      } catch (error) {
        console.error('Failed to fetch banks:', error);
      }
    };

    fetchBanks();
  }, []);
  // const allFieldname = []
  // console.log("alll fielddd---", allFieldname);
  const handleFieldLabelChange = (index, newLabel) => {
    const updatedFields = [...fields];
    updatedFields[index].label = newLabel;
    updatedFields[index].FieldAccess = fields[index]?.FieldAccess || userRoles.map((item) => item.role);
    setFields(updatedFields);
    // updatedFields.push(allFieldname)
  };

  const handleFieldPlaceholderChange = (index, newPlaceholder) => {
    const updatedFields = [...fields];
    updatedFields[index].placeholder = newPlaceholder;
    setFields(updatedFields);
  };

  const handleAddOption = (index) => {
    const updatedFields = [...fields];
    const options = updatedFields[index].options || [];
    updatedFields[index].options = [...options, 'New Option'];
    setFields(updatedFields);
  };

  const handleUpdateOptionName = () => {
    const updatedFields = [...fields];
    if (updatedFields[index].options) {
      updatedFields[index].options[optionIndex] = optionName;
    } else {
      updatedFields[index].options = [optionName];
    }
    setIndex('');
    setOptionIndex('');
    setFields(updatedFields);
    setOpenDemoModal(false);
    setOptionName('');
  };
  // checkbox
  const handleCheckboxChange = (fieldIndex, value) => {
    const updatedFields = [...fields];

    if (updatedFields[fieldIndex].FieldAccess.includes(value)) {
      updatedFields[fieldIndex].FieldAccess = updatedFields[fieldIndex].FieldAccess.filter(
        (selectedValue) => selectedValue !== value
      );
    } else {
      updatedFields[fieldIndex].FieldAccess = [...updatedFields[fieldIndex].FieldAccess, value];
    }

    setFields(updatedFields);
    setSelectedValues(updatedFields[fieldIndex].FieldAccess);
  };

  // old
  // const handleSaveLayout = async () => {
  //   if (!selectedBankId) {
  //     toast.error('Please select a bank before saving.');
  //     return;
  //   }
  //   try {
  //     const currentStepFields = fields?.map((field) => ({ ...field }));

  //     currentStepFields.forEach((field, index) => {
  //       if (field.type === 'checkbox') {
  //         field.FieldAccess = field.FieldAccess?.map((value) => ({ type: value }));
  //       }
  //     });

  //     const layoutToSave = {
  //       bankId: selectedBankId,
  //       steps: [{ stepNumber: stepname, fields: currentStepFields }],
  //     };

  //     const existingLayoutData = JSON.parse(localStorage.getItem('layouts')) || { steps: [] };

  //     const existingStepDataIndex = existingLayoutData.steps.findIndex((stepData) => stepData.stepNumber === stepname);
  //     if (existingStepDataIndex !== -1) {
  //       existingLayoutData.steps[existingStepDataIndex] = { stepNumber: stepname, fields: currentStepFields };
  //     } else {
  //       existingLayoutData.steps.push({ stepNumber: stepname, fields: currentStepFields });
  //     }

  //     localStorage.setItem('layouts', JSON.stringify(existingLayoutData));

  //     if (stepname === '1') {
  //       const response = await makeApiRequest('post', '/bankformlayout', {
  //         bankId: selectedBankId,
  //         steps: existingLayoutData.steps,
  //       });
  //       if (response.status === 201) {
  //         setOpenPopup(true);
  //       } else {
  //         console.error('Error saving layout:', response.data.message);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error saving layout:', error);
  //   }
  // };

  // new 
  const handleSaveLayout = async () => {
    if (!selectedBankId) {
      toast.error('Please select a bank before saving.');
      return;
    }
  
    try {
      const currentStepFields = fields.map((field) => ({ ...field }));
  
      // If the field type is 'checkbox', update the FieldAccess property
      currentStepFields.forEach((field) => {
        if (field.type === 'checkbox') {
          field.FieldAccess = field.FieldAccess?.map((value) => ({ type: value }));
        }
      });
  
      const layoutToSave = {
        bankId: selectedBankId,
        steps: [{ stepNumber: stepname, fields: currentStepFields }],
      };
  
      const response = await makeApiRequest('post', '/bankformlayout', {
        bankId: selectedBankId,
        steps: layoutToSave.steps,
      });
  
      if (response.status === 201) {
        // Update the local storage with the current layout
        // localStorage.setItem('layouts', JSON.stringify(layoutToSave));
        setOpenPopup(true);
      } else {
        console.error('Error saving layout:', response.data.message);
      }
    } catch (error) {
      console.error('Error saving layout:', error);
    }
  };
  

  const handleClosePopup = () => {
    setOpenPopup(false);
    localStorage.removeItem('layouts');
    // window.location.reload();
    history('/dashboard/forms');
  };

  const handleResetForm = () => {
    setFields([]);
    setOpenPopup(false);
  };

  useEffect(() => {
    handleResetForm();
  }, [stepname]);

  const handelBankSelect = async (event) => {
    const selectedBankId = event.target.value;
    setSelectedBankId(selectedBankId);
  };

  const handleInputChange = (field, value) => {
    setFormName((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  // old
  // useEffect(() => {
  //   handleResetForm();
  //   const savedLayout = localStorage.getItem('layouts');
  //   if (savedLayout) {
  //     const data = JSON.parse(savedLayout);
  //     if (data.steps.length >= stepname) {
  //       const stepData = data.steps[stepname - 1];
  //       setFields(
  //         stepData?.fields?.map((field) => {
  //           return {
  //             ...field,
  //             // FieldAccess: field.FieldAccess || []
  //             // FieldAccess: []
  //           };
  //         })
  //       );
  //     }
  //   }
  // }, [stepname]);
  // new
  // useEffect(() => {
  //   handleResetForm();
  //   const savedLayout = localStorage.getItem('layouts');
  //   if (savedLayout) {
  //     const data = JSON.parse(savedLayout);
  //     if (data.steps.length >= stepname) {
  //       const stepData = data.steps[stepname - 1];
  //       // Set fields directly from the stored layout
  //       setFields(stepData?.fields || []);
  //     }
  //   }
  // }, [stepname]);
  

  const dropdownvalue = (index, optionIndex, option) => {
    setIndex(index);
    setOptionIndex(optionIndex);
    setOpenDemoModal(true);
    setOptionName(option);
    // if (!openDemoModal) { return dropdownfield; }
  };
  const handleDeleteOption = (fieldIndex, optionIndex) => {
    const updatedFields = [...fields];
    const options = updatedFields[fieldIndex].options || [];
    options.splice(optionIndex, 1);
    updatedFields[fieldIndex].options = options;
    setFields(updatedFields);
  };

  const renderFields = () => {
    if (fields.length === 0) {
      return <div className="text-center">Drag fields here</div>;
    }
    return fields?.map((field, index) => (
      <div key={index} className="form-field row position-relative">
        <div className="col-9">
          <label htmlFor="inputfileds">{field.label}</label>
          {renderInput(field, index, field.label)}
        </div>

        <div className="corner-icons col-3  mt-2 ">
          {/* edit icon */}
          <Tooltip title="Edit" arrow>
            <Iconify
              icon={'eva:edit-fill'}
              className="buttonspointer , editButtonspointer"
              onClick={(event) => {
                event.stopPropagation();
                handleOpenDialog(index, field.label);
              }}
              sx={{ mr: 2 }}
            />
          </Tooltip>

          {/* delete */}
          <Tooltip title="Delete" arrow>
            <Iconify
              icon={'eva:trash-2-outline'}
              sx={{ mr: 2 }}
              onClick={() => handleDeleteField(index)}
              className="buttonspointer , deleteButtonspointer"
            />
          </Tooltip>
          {/* accesbility */}
          {field.label &&
          field.label !== 'input-text' &&
          field.label !== 'input-number' &&
          field.label !== 'input-dropdown' &&
          field.label !== 'input-image' &&
          field.label !== 'input-audio' &&
          field.label !== 'input-date' ? (
            <Tooltip title="Accessibility" arrow>
              <LockPersonIcon
                onClick={(event) => {
                  event.stopPropagation();
                  handleOpenDialogChekBox(index, field.FieldAccess);
                }}
                className="buttonspointer, accesbilityButtonspointer"
              />
            </Tooltip>
          ) : null}
          {/* label */}

          {/* placeholder */}

          <Dialog open={placeholderDialog} onClose={handleCloseDialogPlaceholder}>
            <DialogTitle>Edit placeholder</DialogTitle>
            <DialogContent>
              <input type="text" value={newPlaceHolder} onChange={(e) => setNewPlaceholder(e.target.value)} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialogPlaceholder} color="primary">
                Cancel
              </Button>
              <Button onClick={handlePlaceholderChange} color="primary">
                Save placeholder
              </Button>
            </DialogActions>
          </Dialog>

          {/* layout -> field.fieldAcess  */}
        </div>
      </div>
    ));
  };

  const renderInput = (field, index, label) => {
    switch (field.type) {
      case 'input-text':
        return (
          <input
            type="text"
            name={label}
            disabled
            placeholder={field.placeholder || ''}
            className="form-control"
            onChange={(event) => handleInputChange(label, event.target.value)}
          />
        );
      case 'input-number':
        return (
          <input
            type="number"
            disabled
            name={label}
            placeholder={field.placeholder || ''}
            className="form-control"
            onChange={(event) => handleInputChange(label, event.target.value)}
          />
        );
      case 'input-image':
        return (
          <input
            type="file"
            name={label}
            disabled
            placeholder={field.placeholder || ''}
            className="form-control"
            onChange={(event) => handleInputChange(label, event.target.value)}
          />
        );
      case 'input-audio':
        return (
          <input
            type="file"
            name={label}
            disabled
            placeholder={field.placeholder || ''}
            className="form-control"
            onChange={(event) => handleInputChange(label, event.target.value)}
          />
        );
      case 'input-date':
        return (
          <input
            type="date"
            name={label}
            disabled
            placeholder={field.placeholder || ''}
            className="form-control"
            onChange={(event) => handleInputChange(label, event.target.value)}
          />
        );
      case 'input-dropdown':
        return (
          <div key={index} className="form-field">
            <select
              className="form-control"
              placeholder={field.placeholder || ''}
              name={label}
              onChange={(event) => handleInputChange(label, event.target.value)}
            >
              {field.options?.map((option, optionIndex) => (
                <option key={optionIndex} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {field.label.toLowerCase() !== 'input-dropdown' && (
              <div>
                <button className="btn btn-sm btn-success ms-2" onClick={() => handleAddOption(index)}>
                  Add Options
                </button>
                {field.options?.map((option, optionIndex) => (
                  <div key={optionIndex} className="d-flex align-items-center">
                    <button
                      className="btn btn-sm btn-secondary ms-2 mt-1"
                      onClick={() => {
                        dropdownvalue(index, optionIndex, option);
                      }}
                    >
                      Edit <b>{option}</b>
                    </button>
                    <button
                      className="btn btn-sm btn-danger ms-2 mt-1"
                      onClick={() => {
                        handleDeleteOption(index, optionIndex);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  const formContainerClass = openPopup ? 'form-container animated shake' : 'form-container';
  const renderButtons = () => {
    return (
      <button onClick={handleSaveLayout} className="btn firstbtncle">
        Save
      </button>
    );
  };

  return (
    <>
      <div className="m-3">
        <Link to={`/dashboard/forms`}>
          <ArrowBackIcon />
        </Link>
      </div>
      {/* sidebar */}
      <div className="d-flex">
        <div className="w-25 ">
          {/* <CaseSidebar /> */}
          <MainSidebar />
        </div>
      </div>
      {/* form */}
      <div className="container mainforbuilder ">
        <div className="d-flex flex-column align-items-center justify-content-center">
            <>
              <select onChange={handelBankSelect} required className="form-select mt-3 w-50">
                <option>Select Bank Name</option>
                {Banks &&
                  Banks?.map((bank, index) => (
                    <option key={index} value={bank._id} selected={selectedBankId === bank._id}>
                      {bank.bankname} - {bank.branch}
                    </option>
                  ))}
              </select>
              {/* {!selectedBankId && <small style={{ color: 'red', textAlign: 'left' }}>Please select bank.</small>} */}
            </>
         

          {/* catching input field from sidebar */}
          <div
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
            className={`${formContainerClass}  w-75 p-4 bg-light mt-2 mainFrom`}
          >
            {renderFields()}
            <Dialog open={openPopup} onClose={handleClosePopup}>
              <DialogTitle>Form Saved</DialogTitle>
              <DialogContent>Your form has been saved successfully.</DialogContent>
              <DialogActions>
                <Link to="/dashboard/forms">
                  <Button onClick={handleClosePopup} color="primary" autoFocus>
                    OK
                  </Button>
                </Link>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center gap-5 mt-3">
          <div className="d-flex align-items-center justify-content-center gap-5 mt-3">
            <button onClick={handleResetForm} className="btn btn-danger">
              Reset Form
            </button>

            {renderButtons()}
          </div>
        </div>

        <Dialog
          open={openDemoModal}
          onClose={() => {
            setOptionName('');
            setOpenDemoModal(false);
          }}
        >
          <DialogTitle>Update Option</DialogTitle>
          <DialogContent>
            {/* Add your modal content here */}
            <p>Enter option name</p>
            <TextField
              id="outlined-basic"
              value={optionName}
              onChange={(e) => setOptionName(e.target.value)}
              label="Option Name"
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOptionName('');
                setOpenDemoModal(false);
              }}
              color="primary"
            >
              Close
            </Button>
            <Button
              disabled={!optionName.trim()}
              onClick={() => {
                handleUpdateOptionName();
              }}
              color="primary"
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Edit Label</DialogTitle>
          <DialogContent>
            <input type="text" value={newLabel} onChange={(e) => setNewLabel(e.target.value)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleLabelChange} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={showDialog} onClose={handleCloseDialogChekBox}>
          <DialogTitle>Field should be access by</DialogTitle>
          <DialogContent>
            {userRoles.map((option) => {
              return (
                option.role !== 'super-admin' &&
                option.role !== 'admin' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedValues?.includes(option.role)}
                        onChange={() => handleCheckboxChange(fieldIndex, option.role)}
                        value={option.role}
                      />
                    }
                    label={option.role}
                    sx={{ flexGrow: 1, m: 0 }}
                    key={option._id}
                  />
                )
              );
            })}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseDialogChekBox} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default FormBuilder;
