import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { InputLabel, MenuItem, Select, Dialog,  DialogTitle, DialogActions,  Button, FormControl, FormControlLabel, Radio, RadioGroup, LinearProgress, CircularProgress} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import makeApiRequest from '../../api/commonApi';

const Subtypelist = () => {
  const { caseId } = useParams();

  // acordien
  const [expanded, setExpanded] = useState(null);
  // verifier section
  const [verifierlist, setVerifierlist] = useState([]);
  const [verifier, setVerifier] = useState("");
  // dyanamic form section
  const [isLoading, setIsLoading] = useState(true);

  const [openPopup, setOpenPopup] = useState(false);
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState('');
  const [bank, setBank] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [savedForms, setSavedForms] = useState([]);
  const [profilesavedForms, setprofileSavedForms] = useState([]);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  useEffect(() => {
    const VerfierList = async () => {
      try {
        const verisresList = await makeApiRequest('get', '/rolesByUsers');

        if (verisresList.status === 200) {
          setVerifierlist(verisresList.data.data);
        } else {
          console.error('Failed to fetch layouts');
        }
      } catch (error) {
        console.error('Error fetching layouts:', error);
      }
    };

    VerfierList();
  }, []);

  const handelselectverfier = (veriID, veriName, casename, caseType) => {
    setVerifier(veriID)
    handleInputChange(`${casename}verifier`, veriID, userRoles, casename, caseType);
  };
  ;


  const stepname = '1';



  const dataInit = async () => {
    const response = await makeApiRequest('get', `/formdata/${caseId}`);
    const data = response.data.data;
    setFormData(data)
  }
  useEffect(() => {
    dataInit()
  }, [])

  // Fetch layouts on component mount
  useEffect(() => {
    const fetchLayouts = async () => {
      try {
        const rolesList = await makeApiRequest('get', '/role');

        if (rolesList.status === 200) {
          const roleOptions = rolesList.data.data?.map((role) => role.role);
          setUserRoles(roleOptions);
        } else {
          console.error('Failed to fetch role');
        }
      } catch (error) {
        console.error('Error fetching role:', error);
      }
    };

    fetchLayouts();
  }, []);

  // document
  const handleLoadLayout = async (layoutId) => {
    setIsLoading(true);
    try {
      const response = await makeApiRequest('get', `/caseformlayout/${layoutId}`);

      const loadedLayout = response?.data.data;

      setBank(loadedLayout?.bankId);
      setSelectedBankId(loadedLayout.caseId?._id || '');
      const loadedFields = loadedLayout.steps.find((step) => step.stepNumber === stepname).fileds;
      setFields(loadedFields);
    } catch (error) {
      console.error('Error loading layout:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // profile
  const handelProfileShowdataID = async (formId, caseName) => {
    setIsLoading(true);
    try {
      const response = await makeApiRequest('get', `/case-profile-formlayout/${formId}`);

      const loadedLayout = response?.data.data;
      const subTypeVerifier = formData.find(item => item.fieldname === `${caseName}verifier`);
      setVerifier(subTypeVerifier?.value);
      setBank(loadedLayout?.bankId);
      setSelectedBankId(loadedLayout.caseId?._id || '');
      const loadedFields = loadedLayout.steps.find((step) => step.stepNumber === stepname).fileds;
      setFields(loadedFields);
    } catch (error) {
      console.error('Error loading layout:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch layout on selected bank or step change
  useEffect(() => {
    const fetchLayout = async () => {
      if (selectedBankId && stepname) {
        await handleLoadLayout(selectedBankId);
      }
    };

    fetchLayout();
  }, [selectedBankId, stepname]);

  const handleInputChange = (fieldname, value, FieldAccess, casename, casetype) => {
    const updatedFormData = {
      fieldname,
      value,
      FieldAccess,
      casename,
      casetype,
    };

    setFormData((prevFormData) => {
      const index = prevFormData.findIndex((item) => {
        if (item.casename && item.casetype) {
          return (
            item.fieldname === updatedFormData.fieldname &&
            item.casename === updatedFormData.casename &&
            item.casetype === updatedFormData.casetype
          );
        }
        return item.fieldname === updatedFormData.fieldname;
      });

      if (index !== -1) {
        // Update the existing item
        prevFormData[index].value = updatedFormData.value;
      } else {
        // Add the new item to the array
        prevFormData.push(updatedFormData);
      }

      return [...prevFormData];

    });
  };

  const handleSaveData = async () => {
    try {
      if (stepname === '1') {
        const dataToSave = {
          formData,
        };
        await makeApiRequest('post', '/formdata', { formData: dataToSave, bankId: bank });
        setOpenPopup(true);
        setExpanded(null);
      }
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  };
  // const [VerfierOpenPopup, setVerfierOpenPopup] = useState(false);
  const VerfierhandleSaveData = async () => {
    try {
      if (stepname === '1') {
        const dataToSave = {
          formData,
        };
        await makeApiRequest('post', '/formdata', { formData: dataToSave, bankId: bank });
        // setVerfierOpenPopup(true);
        toast.success('Case Assigned Successfully.');
      }
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  };

  // const VerfierOpenPopuphandleClosePopup = () => {
  //   setVerfierOpenPopup(false);
  //   localStorage.removeItem('layouts');
  // };

  const handleClosePopup = () => {
    setOpenPopup(false);
    localStorage.removeItem('layouts');
  };

  const handleImageUpload = async (label, event, FieldAccess, casename, casetype) => {
    try {
      const file = event.target.files[0];
      // avoid video file
      if (file.type.startsWith('video/')) {
        toast.error('video not allow');
        return;
      }
      // set size of file
      if (file.size > 5 * 1024 * 1024) {
        toast.error('please upload file under 5 mb');
        return;
      }

      const data = new FormData();
      data.append('file', file);
      data.append('upload_preset', 'acpienfk');

      await axios
        .post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`, data, {
          onUploadProgress: (progressEvent) => {
            // ((currend uploaded / total size )*100 )
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadProgress(progress);
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const imageURL = response.data.url;
            handleInputChange(label, imageURL, FieldAccess, casename, casetype);
          }
        });
    } catch (error) {
      console.log('image upload error', error);
    } finally {
      setUploadProgress(0);
    }
  };

  const Profilecase = 'Profile';
  const Documnetcase = 'Document';

  // Render the form fields
  const renderFields = (casename, casetype) => {
    return fields.map((field, index) => (
      <div key={index} className="form-field">
        <label htmlFor="input-filed">{field.label}</label>
        {renderInput(field, index, field.label, casename, casetype)}
      </div>
    ));
  };

  useEffect(() => {
    handleInputChange('case_id', caseId, userRoles);
  }, []);

  // Render input based on field type
  const renderInput = (field, index, label, casename, casetype) => {

    const matchingItem = formData.find(field =>
      field.casename?.toLowerCase().trim() === casename?.toLowerCase().trim() &&
      field.casetype?.toLowerCase().trim() === casetype?.toLowerCase().trim() &&
      field.fieldname?.toLowerCase().trim() === label?.toLowerCase().trim()
    );
    let value = null;
    if (matchingItem) {
      value = matchingItem.value;
    }
    switch (field.type) {
      case 'input-text':
        return (
          <input
            type="text"
            name={label}
            placeholder={field.placeholder || ''}
            className="form-control inputTextSize"
            value={value}
            onChange={(event) => {
              handleInputChange(label, event.target.value, field.FieldAccess, casename, casetype);
            }}
          />
        );
      case 'input-number':
        return (
          <input
            type="number"
            name={label}
            placeholder={field.placeholder || ''}
            className="form-control inputTextSize"
            value={value}
            onChange={(event) => handleInputChange(label, event.target.value, field.FieldAccess, casename, casetype)}
          />
        );
      case 'input-image':
        return (
          <>
            {value && <img width={'100px'} className='img-fluid' src={value} />}
            <input
              type="file"
              name={label}
              placeholder={field.placeholder || ''}
              className="form-control"
              onChange={(event) => {
                handleImageUpload(label, event, field.FieldAccess, casename, casetype);
              }}
            />
          </>
        );
      case 'input-audio':
        return (
          <>
            {value && <audio className="form-control" src={value} controls />}

            <input
              type="file"
              name={label}
              placeholder={field.placeholder || ''}
              className="form-control"
              onChange={(event) => {
                handleImageUpload(label, event, field.FieldAccess, casename, casetype);
              }}
            />
          </>
        );

      case 'input-dropdown':
        return (
          <div key={index} className="form-field dropdown-container">
            <select
              className="form-control"
              name={label}
              placeholder={field.placeholder || ''}
              value={value}
              onChange={(event) => handleInputChange(label, event.target.value, field.FieldAccess, casename, casetype)}
            >
              <option>----Please select value----</option>
              {field.options?.map((option, optionIndex) => (
                <option key={optionIndex} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );

      case 'input-date':
        return (
          <input
            type="date"
            value={value}
            name={label}
            className="form-control inputTextSize"
            onChange={(event) => handleInputChange(label, event.target.value, field.FieldAccess, casename, casetype)}
          />
        );
      case 'input-radio':
        return (
          <FormControl component="fieldset" key={index}>
            <RadioGroup
              name={label}
              className="p-2 inputTextSize"
              value={value}
              onChange={(event) =>
                handleInputChange('input-dropdown', event.target.value, field.FieldAccess, casename, casetype)
              }
            >
              {field.options?.map((option, optionIndex) => (
                <FormControlLabel key={optionIndex} value={option} control={<Radio />} label={<div>{option}</div>} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      default:
        return null;
    }
  };

  const formContainerClass = openPopup ? 'form-container animated shake' : 'form-container';


  useEffect(() => {
    const fetchSavedForms = async () => {
      try {
        const DocumetDataList = await makeApiRequest('get', '/caseformlayout');
        const ProfileDataList = await makeApiRequest('get', '/case-profile-formlayout');
        if (DocumetDataList.status === 200) {
          const data = DocumetDataList?.data?.data;
          const PtofileData = ProfileDataList?.data?.data;
          setSavedForms(data);
          setprofileSavedForms(PtofileData);
        } else {
          console.error('Failed to fetch saved forms');
        }
      } catch (error) {
        console.error('Error fetching saved forms:', error);
      }
    };

    fetchSavedForms();
  }, []);

  return (
    <>
      <div className="pb-4">
        <Link to={`/dashboard/requestDetails/${caseId}`}>
          <ArrowBackIcon />
        </Link>
      </div>

      {/* document */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h4" component="h4">
            Document
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="div" component="div">
            <>
              {uploadProgress > 0 && (
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  style={{ backgroundColor: 'lightgray' }}
                  classes={{ bar: 'custom-progress-bar' }}
                />
              )}
            </>
            {savedForms?.map((data, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleAccordionChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 2}-content`}
                  id={`panel${index + 2}-header`}
                  onClick={() => handleLoadLayout(data._id)}
                >
                  <Typography variant="div" component="div">{data?.caseId?.casename}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="div" component="div">
                    <div
                      className={`${formContainerClass} d-flex justify-content-center w-100 align-items-center text-center mainFrom`}
                    >
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <div
                          className="w-75 p-3"
                        >
                          {renderFields(data?.caseId?.casename, Documnetcase)}
                        </div>
                      )}
                    </div>

                    <Dialog open={openPopup} onClose={handleClosePopup}>
                      <DialogTitle>Data Saved.</DialogTitle>
                      {/* <DialogContent>Your data has been saved successfully.</DialogContent> */}
                      <DialogActions>
                        <Button onClick={handleClosePopup} color="primary" autoFocus>
                          OK
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {/* <Dialog open={VerfierOpenPopup} onClose={VerfierOpenPopuphandleClosePopup}>
                      <DialogTitle>Verifier initiated  Successfully.</DialogTitle>

                      <DialogActions>
                        <Button onClick={VerfierOpenPopuphandleClosePopup} color="primary" autoFocus>
                          OK
                        </Button>
                      </DialogActions>
                    </Dialog> */}

                    <div className="d-flex  justify-content-around align-items-center">
                      <div>
                        <button onClick={handleSaveData} className="btn btn-success mt-3">
                          Save Data
                        </button>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* Profile */}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="h4" component="h4">
            Profile
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="div" component="div">
            <>
              {uploadProgress > 0 && (
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  style={{ backgroundColor: 'lightgray' }}
                  classes={{ bar: 'custom-progress-bar' }}
                />
              )}
            </>
            {profilesavedForms?.map((data, index) => (
              <Accordion
                key={index}
                expanded={expanded === `profilePanel${index}`}
                onChange={handleAccordionChange(`profilePanel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`profilePanel${index}-content`}
                  id={`profilePanel${index}-header`}
                  onClick={() => handelProfileShowdataID(data._id, data?.caseId?.casename)}
                >
                  <Typography variant="div" component="div">{data?.caseId?.casename}</Typography>

                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className={`${formContainerClass} d-flex justify-content-center w-100 align-items-center text-center mainFrom`}
                  >
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <div
                        className="w-100 p-3"
                      >
                        <InputLabel>Select Verifier</InputLabel>
                        <Select
                          className="w-25"
                          label="vaihav"
                          placeholder="Verifier"
                          value={verifier}
                          onChange={(e) => {
                            const veriID = e.target.value;
                            const veriName = verifierlist.find((data) => data._id === veriID)?.name;
                            handelselectverfier(veriID, veriName, data?.caseId?.casename, Profilecase);
                          }}
                        >
                          {verifierlist?.map((data, index) => (
                            <MenuItem key={index} value={data._id}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <button onClick={VerfierhandleSaveData} className="btn btn-primary m-5">
                          Assign
                        </button>
                        {renderFields(data?.caseId?.casename, Profilecase)}
                      </div>
                    )}
                  </div>
                  <Dialog open={openPopup} onClose={handleClosePopup}>
                    <DialogTitle>Data Saved.</DialogTitle>
                    <DialogActions>
                      <Button onClick={handleClosePopup} color="primary" autoFocus>
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <div className="d-flex justify-content-around align-items-center">
                    <div>
                      <button onClick={handleSaveData} className="btn btn-success mt-3">
                        Save Data
                      </button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default Subtypelist;
