import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; 
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme, widthvalue }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  width: widthvalue,
  transition: 'width 0.3s',
  [theme.breakpoints.up('lg')]: {
    width: widthvalue,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  openNav: PropTypes.bool,
  isDesktop: PropTypes.bool,
  toggleNav: PropTypes.func,
};

export default function Header({ openNav,isDesktop, toggleNav }) {
  const widthvalue = `calc(100% - ${openNav && isDesktop ? NAV_WIDTH : 0 + 1}px)`;
  const location = useLocation()
  const [pathName, setPathName] = useState(location.pathname);
  const pagesWithSearchBar = ["/dashboard/app"]
  useEffect(() => {
    const updatedPath = location.pathname;
    setPathName(updatedPath);
  }, [pathName,location.pathname])
  return (
    <StyledRoot widthvalue={widthvalue}>
      <StyledToolbar>
        <IconButton
          onClick={toggleNav}
          sx={{
            mr: 1,
            color: 'var(--navbar-icon-color)',
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* {pagesWithSearchBar.includes(pathName) && <Searchbar />} */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
