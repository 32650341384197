import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import { Dialog, DialogContent, MenuItem, InputLabel, DialogActions, Select, Button, CircularProgress, Stack, Input, TextField, TextareaAutosize } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeApiRequest from '../../api/commonApi';

export default function Showsubcasedetails() {
  // const [expanded, setExpanded] = React.useState(false);
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  const { caseId } = useParams();
  const stepname = '1';

  const [formData, setFieldData] = useState([]);
  const [fields, setFields] = useState([]);
  const [showDialogs, setShowDialogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [verificationRemark, setVerificationRemark] = useState('');

  const handleFetchFormData = async () => {
    try {
      setIsLoading(true);
      const response = await makeApiRequest('get', `/formdata/${caseId}`);
      const data = response.data.data;
      setFieldData(data);
      const columns = Object.keys(data[0]).filter((fieldName) => fieldName !== 'FieldAccess');
      setFields(columns);

      setShowDialogs(new Array(data.length).fill(false));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchFormData();
  }, []);

  // open popup with index
  const toggleDialog = (rowIndex) => {
    const newShowDialogs = [...showDialogs];
    newShowDialogs[rowIndex] = !newShowDialogs[rowIndex];
    setShowDialogs(newShowDialogs);
  };

  const [filterdata, Setfilterdata] = useState([]);
  const [showLalabel, SetshowLalabel] = useState([]);
  const [subtypeStatus, setSubTypeStatus] = useState('');

  // Testing
  useEffect(() => {
    const uniqdata = async () => {
      const uniqueCasenameSet = new Set();

      await formData.forEach((item) => {
        if (item.casename) {
          uniqueCasenameSet.add(item.casename);
        }
      });

      const uniqueCasenameArray = Array.from(uniqueCasenameSet);

      SetshowLalabel(uniqueCasenameArray);
    };
    uniqdata();
  }, [formData]);

  const handleLoadLayout = (name) => {
    const bankingData = formData.filter((item) => item.casename === name);
    const filterString = `${name}STRINGTOBEREMOVE`;
    const statusData = formData.filter(item => item.fieldname === filterString);
    let status = '';
    let remark = '';
    if (statusData.length > 0) {
      status = statusData[0]?.value;
      remark = statusData[0]?.verificationRemark;
    }
    setVerificationRemark(remark);
    setSubTypeStatus(status);
    Setfilterdata(bankingData);
  };

  const [savedForms, setSavedForms] = useState([]);
  const [profilesavedForms, setprofileSavedForms] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    const fetchLayouts = async () => {
      try {
        const rolesList = await makeApiRequest('get', '/role-for-create-product');

        if (rolesList.status === 200) {
          const roleOptions = rolesList.data.data?.map((role) => role.role);
          setUserRoles(roleOptions);
        } else {
          console.error('Failed to fetch role');
        }
      } catch (error) {
        console.error('Error fetching role:', error);
      }
    };
    fetchLayouts();
  }, []);
  useEffect(() => {
    const fetchSavedForms = async () => {
      try {
        const DocumetDataList = await makeApiRequest('get', '/caseformlayout');
        const ProfileDataList = await makeApiRequest('get', '/case-profile-formlayout');
        if (DocumetDataList.status === 200) {
          const data = DocumetDataList?.data?.data;
          const PtofileData = ProfileDataList?.data?.data;
          setSavedForms(data);
          setprofileSavedForms(PtofileData);
        } else {
          console.error('Failed to fetch saved forms');
        }
      } catch (error) {
        console.error('Error fetching saved forms:', error);
      }
    };

    fetchSavedForms();
  }, []);

  const handleInputChange = (fieldname, value, FieldAccess) => {
    const updatedFormData = {
      fieldname,
      value,
      FieldAccess,
      verificationRemark,
    };

    setFieldData((prevFormData) => {
      const fieldIndex = prevFormData.findIndex((field) => field.fieldname === fieldname);

      if (fieldIndex !== -1) {
        const updatedFields = [...prevFormData];
        updatedFields[fieldIndex] = updatedFormData;
        return updatedFields;
      }

      return [...prevFormData, updatedFormData];
    });
  };

  const [CallPutmethode, setCallPutmthod] = useState('');
  const handleSaveData = async () => {
    try {
      if (stepname === '1') {
        const dataToSave = {
          formData,
        };
        if (CallPutmethode === false) {
          await makeApiRequest('post', '/formdata', { formData: dataToSave });
          toast.success('Report Status Save...');

          setExpanded(null);
          setVerificationRemark('');

          console.log('Form data saved successfully!');
        } else {
          axios
            .put(`${process.env.REACT_APP_SERVER_URL}/formdata/${caseId}`, dataToSave)
            .then((response) => {
              console.log('PUT request successful:', response.data);
              setExpanded(null);
              setVerificationRemark('');
              toast.success('Report update successful...');
            })
            .catch((error) => {
              console.error('PUT request error:', error);
            });
        }
      }
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  };
  const [hidedropdown, sethidedropdown] = useState(false);
  const handelselectResult = (value, name, verificationRemark) => {
    // console.log('v value, name, verificationRemark----', value, name, verificationRemark);

    const checkfieldisavalibleornot = formData.find((item) => item.fieldname === `${name}STRINGTOBEREMOVE`);
    if (checkfieldisavalibleornot) {
      // console.log('alreday have', `${name}STRINGTOBEREMOVE`);
      setCallPutmthod(true);
    } else {
      // console.log('NEW-==========---====----===', `${name}STRINGTOBEREMOVE`);
      setCallPutmthod(false);
    }
    handleInputChange(`${name}STRINGTOBEREMOVE`, value, userRoles, verificationRemark);
    handleInputChange(`OVER ALL RCU STATUS`, '', userRoles, verificationRemark);
    handleInputChange(`Case close Date`, '', userRoles);

    sethidedropdown(true);
  };
  return (
    <div>
      <>
        <div className="mb-3">
          <Link style={{ color: 'blue' }} to={`/dashboard/requestDetails/${caseId}`}>
            <ArrowBackIcon />
          </Link>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Report create
          </Typography>
          <Link to={`/dashboard/report/${caseId}`} style={{ textDecoration: 'none', color: 'black' }}>
            <Button variant="contained">Final Report</Button>
          </Link>
        </Stack>

        {isLoading ? (
          <div className=" d-flex justify-content-center align-items-center ">
            <CircularProgress />
          </div>
        ) : (
          <>
            {showLalabel?.map((data, index) => (
              <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary
                  onClick={() => handleLoadLayout(data)}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>{data}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div>
                      <div className="text-center">
                        <TableContainer component={Paper} className="TableParent text-center">
                          <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableRow className="TableheadStyle">
                              <TableCell className="HeadCellStyle">Field Name</TableCell>
                              <TableCell className="HeadCellStyle">Value</TableCell>
                            </TableRow>
                            {filterdata.map((item, rowIndex) => (
                              <TableRow key={rowIndex}>
                                {fields.map((fieldName) => (
                                  <TableCell key={fieldName} className="textCentre">
                                    {/* cloudinary word finding */}
                                    {item[fieldName].includes('cloudinary') ? (
                                      <>
                                        {/* image word finding */}
                                        {item[fieldName].includes('image') ? (
                                          <>
                                            <button className="btn firstbtncle " onClick={() => toggleDialog(rowIndex)}>
                                              View Image
                                            </button>
                                            <Dialog
                                              className="dialog-w-100"
                                              open={showDialogs[rowIndex]}
                                              onClose={() => toggleDialog(rowIndex)}
                                              fullWidth
                                              maxWidth="md"
                                            >
                                              <DialogContent>
                                                <img src={item[fieldName]} alt="images" className="img-fluid" />
                                              </DialogContent>
                                              <DialogActions>
                                                <Button onClick={() => toggleDialog(rowIndex)} color="primary">
                                                  Close
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                          </>
                                        ) : item[fieldName].includes('video') ? (
                                          <>
                                            <button className="btn firstbtncle " onClick={() => toggleDialog(rowIndex)}>
                                              View Media
                                            </button>
                                            <Dialog
                                              className="dialog-w-100"
                                              open={showDialogs[rowIndex]}
                                              onClose={() => toggleDialog(rowIndex)}
                                              fullWidth
                                              maxWidth="md"
                                            >
                                              <DialogContent className="text-center">
                                                <video controls width="90%">
                                                  <source src={item[fieldName]} type="video/mp4" />
                                                  <track kind="captions" src="" label="English" />{' '}
                                                </video>
                                              </DialogContent>
                                              <DialogActions>
                                                <Button onClick={() => toggleDialog(rowIndex)} color="primary">
                                                  Close
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                          </>
                                        ) : (
                                          item[fieldName]
                                        )}
                                      </>
                                    ) : item.fieldname.includes('verifier') ? (
                                      <>
                                        {item.casename && fieldName === 'fieldname' ? "Verifier" : `${item?.userData?.name}`}
                                      </>
                                    ) : (
                                      item[fieldName]
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </Typography>
                  <div className="mt-4">
                    {/* <InputLabel>Report Status</InputLabel> */}
                    {/* {!hidedropdown && ( */}
                    <TextareaAutosize
                      minRows={2}
                      variant="outlined"
                      label="Case Remark"
                      placeholder="Case Remark"
                      className="px-2 mx-5"
                      type="text"
                      value={verificationRemark}
                      onChange={(e) => setVerificationRemark(e.target.value)}
                    />
                    {/* )} */}
                    {/* {verificationRemark && ( */}
                    <div className="ms-5 mt-2">
                      {/* <Select
                        className="w-25"
                        onChange={(e) => {
                          handelselectResult(e.target.value, data);
                        }}
                      > */}
                      <TextField
                        select
                        variant="outlined"
                        className="w-25"
                        size="small"
                        name="ba"
                        label="Case Status"
                        fullWidth
                        value={subtypeStatus}
                        onChange={(e) => {
                          setSubTypeStatus(e.target.value);
                          handelselectResult(e.target.value, data, verificationRemark);
                        }}
                      >
                        <MenuItem value="Positive">Positive</MenuItem>
                        <MenuItem value="Negative">Negative</MenuItem>
                        <MenuItem value="CNV">CNV</MenuItem>
                        <MenuItem value="Credit Refer">Credit Refer</MenuItem>
                        <MenuItem value="Suspect">Suspect</MenuItem>
                        <MenuItem value="Caution">Caution</MenuItem>
                        <MenuItem value="Risk Alert">Risk Alert</MenuItem>
                        <MenuItem value="Fraud">Fraud</MenuItem>
                        <MenuItem value="Decline">Decline</MenuItem>
                        <MenuItem value="Recommended">Recommended</MenuItem>
                        <MenuItem value="Not Recommended">Not Recommended</MenuItem>
                      </TextField>
                      <button onClick={handleSaveData} className="btn btn-primary m-5">
                        save
                      </button>
                    </div>
                    {/* )} */}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </>
    </div>
  );
}
