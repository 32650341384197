// import React, { useEffect, useState } from 'react';
// import { PDFViewer, Document, Page, Text, View, pdf } from '@react-pdf/renderer';
// import Button from '@mui/material/Button';
// import makeApiRequest from '../api/commonApi';

// const name = "akalesh"
// const MyDocument = () => (
//     <Document>
//         <Page>
//             <View>
//                 <Text>Hello, this is your offer letter: {name}</Text>
//                 {/* Add more text and components as needed */}
//             </View>
//         </Page>
//     </Document>
// );



// const PdfGenerator = () => {
//     const [pdfBase64, setPdfBase64] = useState('');

//     useEffect(() => {
//         const generatePdf = async () => {
//             const pdfBlob = await pdf(<MyDocument />).toBlob();
//             const reader = new FileReader();

//             reader.onload = () => {
//                 const base64String = reader.result.split(',')[1]; // Extract base64 part
//                 setPdfBase64(base64String);
//             };

//             reader.readAsDataURL(pdfBlob);
//         };
        

//         generatePdf();
//     }, []);

//     const sendPdfToBackend = async () => {
//         const data = { pdfBase64, email: 'becreffouleubo-1183@yopmail.com', subject: 'Offer Letter', text: 'Please find your offer letter attached.' }
//         // Send the PDF Base64 data to the backend via an API call
//         const response = await makeApiRequest('post', '/sendOfferLetter', data);
//         console.log("pdf response", response);

//     };

//     return <>
//         <PDFViewer width="100%" height="400">
//             <MyDocument />
//         </PDFViewer>
//         <Button variant="contained" onClick={sendPdfToBackend}>
//             Send Offer Letter via email
//         </Button>
//     </>
// };
// export default PdfGenerator;



import React, { useEffect, useState } from 'react';
import { PDFViewer, Document, Page, Text, View, pdf } from '@react-pdf/renderer';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal'; // Import MUI Modal
import makeApiRequest from '../api/commonApi';

const name = "akalesh";

const MyDocument = () => (
  <Document>
    <Page>
      <View>
        <Text>Hello, this is your offer letter: {name}</Text>
        {/* Add more text and components as needed */}
      </View>
    </Page>
  </Document>
);

const PdfGenerator = () => {
  const [pdfBase64, setPdfBase64] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const generatePdf = async () => {
      const pdfBlob = await pdf(<MyDocument />).toBlob();
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(',')[1]; // Extract base64 part
        setPdfBase64(base64String);
      };

      reader.readAsDataURL(pdfBlob);
    };

    generatePdf();
    openModal()
  }, []);

  const sendPdfToBackend = async () => {
    const data = {
      pdfBase64,
      email: 'becreffouleubo-1183@yopmail.com',
      subject: 'Offer Letter',
      text: 'Please find your offer letter attached.',
    };
    // Send the PDF Base64 data to the backend via an API call
    const response = await makeApiRequest('post', '/sendOfferLetter', data);
    console.log("pdf response", response);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* <Button variant="contained" onClick={openModal}>
        Generate Offer Letter
      </Button> */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="pdf-modal"
        aria-describedby="pdf-modal-description"
      >
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', maxWidth: '800px', background: 'white', padding: '20px', borderRadius: '8px' }}>
          <PDFViewer width="100%" height="400">
            <MyDocument />
          </PDFViewer>
          <Button variant="contained" onClick={sendPdfToBackend}>
            Send Offer Letter via email
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default PdfGenerator;
