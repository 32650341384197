import { toast } from 'react-toastify';
import axios from "axios";

import { deleteToken, getToken } from '../utils/commonFunction';
import { logoutUser } from '../redux/user/userSlice';
import store from '../redux/store';
const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL

})
// Common function to make api request
const makeApiRequest = async (method, url, data = null, headers = {}) => {

  try {
    api.interceptors.request.use((config) => {
      config.headers = { ...config.headers, ...headers };
      return config;
    });

    if (getToken()) api.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
    else {
      deleteToken();
      store.dispatch(logoutUser())
    }

    if (!navigator.onLine) {
      throw new Error('Internet connection issue. Please check your connection.');
    }

    const response = await api[method](url, data);

    return response;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        console.error('Token has expired. Please log in again .');
        deleteToken();
        store.dispatch(logoutUser())
      } else if (error.response.data.message === "Bank with the same name and branch already exist") {
        toast.error('Bank with the same name and branch already exist');
      } else if (error.response.data.message === "case type cannot be deleted because there is a related form layout") {
        return toast.error('Bank cannot be deleted because there is a related form layout');
      } else {
        console.error('Request error:', error.response.data);
      }

    }
    else if (error.request) {
      console.error('No response received :', error.request);
    } else {
      console.error('Request setup error: ', error.message);
    }
    throw error;
  }
};

export default makeApiRequest;
