import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination, Container, Typography, IconButton, Chip, Popover, MenuItem, Grid, TextField } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Iconify from '../../components/iconify';
import makeApiRequest from '../../api/commonApi';
import DynamicExcelExport from '../../components/createExcelSheet/DynamicExcelExport';
import Loader from '../../components/Loader';
import { parseDate } from '../../utils/commonFunction';
import { useSelector } from 'react-redux';

export default function AllRequest() {
  const navigate = useNavigate();
  const { cases } = useParams();
  const initialCaseStatusFilter = cases === 'all' ? '' : cases;
  const initalbankfilter = cases === 'all' ? '' : cases;
  const [CaseStausfilter, setCaseStausfilter] = useState(initialCaseStatusFilter);
  const [Bankfilter, setBankfilter] = useState('')
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const { userInfo } = useSelector((state) => state.user);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [RequestData, setRequestData] = useState([]);
  const [bankdata, setbankData] = useState([])
  const [casesToBeExport, setCasesToBeExport] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState([]);
  const [search, SetSearch] = useState('');
  const [page, setPage] = useState(0);
  const [totalcase, Settotatalcase] = useState(0);
  const [loanId, setFormToDelete] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCasesLoading, setIsCasesLoading] = useState(false);
  // const [CaseStausfilter, setCaseStausfilter] = useState();
  const [caseType, SetcaseType] = useState('');
  const [DocumentcaseTypeList, SetDocumentcaseTypeList] = useState([]);
  const [ProfilecaseTypeList, SetProfilecaseTypeList] = useState([]);

  // subtypelist
  const CaseTypes = async () => {
    try {
      setIsLoading(true);
      const data = await makeApiRequest('get', `/document-case`);
      const data2 = await makeApiRequest('get', `/field-case`);
      SetDocumentcaseTypeList(data.data.data);
      SetProfilecaseTypeList(data2.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenMenu = (event, index) => {
    const newPopoverOpen = [...popoverOpen];
    newPopoverOpen[index] = event.currentTarget;
    setPopoverOpen(newPopoverOpen);
  };

  const handleCloseMenu = (index) => {
    const newPopoverOpen = [...popoverOpen];
    newPopoverOpen[index] = null;
    setPopoverOpen(newPopoverOpen);
  };

  // delete data
  const handelDeleteForm = (loanid, callback) => {
    callback();
    setFormToDelete(loanid);
    openDialog();
  };

  const handleConfirmDelete = async () => {
    closeDialog();
    try {
      setIsLoading(true);
      const response = await makeApiRequest('delete', `/formdata/${loanId}`);
      if (response.status === 200) {
        toast.success('Case deleted successfully!');
        RequestList();
      }
    } catch (error) {
      toast.error('Form deletion failed', error);
    } finally {
      setIsLoading(false);
    }
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  // show data
  const RequestList = async () => {
    try {
      setIsLoading(true);
      const data = await makeApiRequest(
        'get',
        `/formdata?limit=${rowsPerPage}&caseId=${search}&page=${page + 1
        }&status=${CaseStausfilter}&case_type=${caseType}&bankName=${Bankfilter}`
      );
      setRequestData(data.data.data);

      Settotatalcase(
        data.data.data.length === 0
          ? 0
          : search || CaseStausfilter
            ? data.data.totalDocuments
            : data.data.totalDataCount
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  const getCasesByStatus = async () => {
    try {
      setIsCasesLoading(true);
      const response = await makeApiRequest('get', `/formdata?status=${CaseStausfilter}&populate=true&limit=all&bankName=${Bankfilter}`);
      const dataToExport = response.data.data;
      setCasesToBeExport(dataToExport.reverse());
    } catch (error) {
      console.log(error);
    } finally {
      setIsCasesLoading(false);
    }
  };

  // row per page
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // index number
  const calculateSerialNumber = (index) => page * rowsPerPage + index + 1;

  const RequestDtails = (loanId) => {
    navigate(`/dashboard/requestDetails/${loanId}`);
  };

  const EditRequest = (loanId) => {
    navigate(`/dashboard/requestEdit/${loanId}`);
  };
  const viewReport = (loanId) => {
    navigate(`/dashboard/report/${loanId}`);
  };

  function getCaseIdValue(item) {
    const caseIdField = item?.formData?.find((field) => field.fieldname === 'case_id');
    if (!caseIdField) {
      return 'N/A';
    }
    return caseIdField?.value || '-';
  }
  function getProductValue(item) {
    const caseIdField = item?.formData?.find((field) => field.fieldname === 'product');
    if (!caseIdField) {
      return 'N/A';
    }
    return caseIdField?.value || '-';
  }
  function getApplicantName(item) {
    const caseIdField = item?.formData?.find((field) => field.fieldname === 'applicant name');
    if (!caseIdField) {
      return 'N/A';
    }
    return caseIdField?.value || '-';
  }


  function getTAT(value) {
    const caseStatus = getCaseStatus(value || 'N/A');
    if (caseStatus.toLowerCase() === "closed") {

      const tatValue = value?.TAT
      return tatValue;
    }

    const caseReceivedDateField = value?.formData?.find((field) => field.fieldname === 'Case Received Date');

    if (!caseReceivedDateField) {
      return 'N/A';
    }

    const currentDate = new Date();
    const caseReceivedDate = parseDate(caseReceivedDateField.value);

    if (caseReceivedDate instanceof Date && !isNaN(caseReceivedDate)) {
      const timeDifferenceMs = currentDate - caseReceivedDate;
      const daysDifference = Math.floor(timeDifferenceMs / (1000 * 3600 * 24));
      return daysDifference;
    }

    return 'N/A';
  }




  function getCaseStatus(item) {
    const caseIdField = item?.formData?.find((field) => field.fieldname === 'status');
    if (!caseIdField) {
      return 'N/A';
    }
    return caseIdField?.value?.charAt(0).toUpperCase() + caseIdField?.value?.slice(1) || '-';
  }

  useEffect(() => {
    RequestList();
    CaseTypes();
    /* eslint-disable */
  }, [search, page, rowsPerPage, CaseStausfilter, caseType, Bankfilter]);

  useEffect(() => {
    getCasesByStatus();
  }, [CaseStausfilter, Bankfilter]);

  //get bank
  const getBank = async () => {
    try {
      const response = await makeApiRequest('get', '/bank');
      if (response.status === 200) {
        setbankData(response.data.data)

      }
      if (response.status === 400) {
        console.log("error in bank")
        // toast.error('Bank with the same name and branch already exists.');
      } else {
        console.log('error');
      }

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBank()
  }, [])

  return (
    <>
    {console.log("{userInfo.role.role",userInfo.role.role)}
      <Container maxWidth="xl addingminhight">
        <Typography variant="h4" sx={{ mb: 5 }} className=" text-capitalize">
          {cases} Cases
        </Typography>

        <Grid className="d-flex justify-content-between" alignItems="center" mb={1}>
          <div className="pt-3">
            <TextField
              type="search"
              autoComplete="off"
              style={{ width: '290px' }}
              id="search"
              placeholder="Enter Case Id or Applicant Name"
              label="Search"
              value={search}
              onChange={(e) => {
                setPage(0);
                SetSearch(e.target.value);
              }}
            />
          </div>
          <div className="w-25">
            <TextField
              select
              variant="outlined"
              size="small"
              name="ba"
              label="Case Status"
              fullWidth
              onChange={(event) => {
                setPage(0);
                setCaseStausfilter(event.target.value);
              }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="received">Received</MenuItem>
              <MenuItem value="initiated">Initiated </MenuItem>
              <MenuItem value="verified">Verified</MenuItem>
              <MenuItem value="submitted">Submitted</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
              <MenuItem value="reopened">Reopened</MenuItem>
              <MenuItem value="reinitiated">Reinitiated</MenuItem>
              <MenuItem value="reverified">Reverified</MenuItem>
              <MenuItem value="resubmitted">Resubmitted</MenuItem>
            </TextField>
          </div>
          {userInfo.role.role === 'super-admin' &&

            <div className="w-25">
              <TextField
                select
                variant="outlined"
                size="small"
                name="ba"
                label="BankName"
                fullWidth
                onChange={(event) => {
                  setPage(0);
                  setBankfilter(event.target.value);
                }}
              >

                <MenuItem value="">All</MenuItem>
                {bankdata.map((data, index) => (
                  <MenuItem key={index} value={data?.bankname}>
                    {data?.bankname}
                  </MenuItem>))}
                {/* // <MenuItem value="Electronica Finance">Electronica Finance</MenuItem>
                // <MenuItem value="AU Bank">AU Bank </MenuItem>
                // <MenuItem value="Equitas Bank">Equitas Bank</MenuItem>
                // <MenuItem value="IDFC First Bank">IDFC First Bank</MenuItem>
                // <MenuItem value="SBI">SBI</MenuItem>
                // <MenuItem value="JM Finance">JM Finance</MenuItem>
                // <MenuItem value="Ummed Finance">Ummed Finance</MenuItem> */}
                {/* <MenuItem value="resubmitted">Resubmitted</MenuItem> */}
              </TextField>
            </div>}
        </Grid>

        <div className=" text-center addingminhight">
          <TableContainer component={Paper} className="TableParent">
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow className="TableheadStyle">
                  <TableCell className="HeadCellStyle">S.No</TableCell>
                  <TableCell align="right" className="HeadCellStyle">
                    Case Id
                  </TableCell>
                  <TableCell align="right" className="HeadCellStyle">
                    Applicant Name
                  </TableCell>
                  <TableCell align="right" className="HeadCellStyle">
                    Product
                  </TableCell>
                  <TableCell align="right" className="HeadCellStyle">
                    Branch
                  </TableCell>
                  <TableCell align="right" className="HeadCellStyle">
                    Bank Name
                  </TableCell>
                  <TableCell align="right" className="HeadCellStyle">
                    TAT
                  </TableCell>
                  <TableCell align="right" className="HeadCellStyle">
                    View
                  </TableCell>
                  <TableCell align="right" className="HeadCellStyle">
                    Status
                  </TableCell>

                  {userInfo.role.role !== 'rcu-manager' && <TableCell align="right" className="HeadCellStyle">
                    Action
                  </TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <Loader />
                ) : RequestData.length > 0 ? (
                  RequestData.map((item, index) => (
                    <React.Fragment key={item._id}>
                      <TableRow className="TableRowStyle">
                        <TableCell component="th" scope="row" className="textCentre">
                          {calculateSerialNumber(index)}
                        </TableCell>
                        <TableCell className="textCentre">{getCaseIdValue(item) || 'N/A'}</TableCell>
                        <TableCell className="textCentre">{getApplicantName(item) || 'N/A'}</TableCell>
                        <TableCell className="textCentre">{getProductValue(item) || 'N/A'}</TableCell>
                        <TableCell className="textCentre">{item?.bankId?.branch || 'N/A'}</TableCell>
                        <TableCell className="textCentre">{item?.bankId?.bankname || 'N/A'}</TableCell>
                        <TableCell className="textCentre">{getTAT(item)}</TableCell>

                        <TableCell className="textCentre" onClick={() => RequestDtails(getCaseIdValue(item) || 'N/A')}>
                          <button className="btn border border-1">View More</button>
                        </TableCell>
                        <TableCell className="textCentre">
                          <Chip label={getCaseStatus(item || 'N/A')} className="text-white" color="success" />
                        </TableCell>
                        {userInfo.role.role !== 'rcu-manager' && <TableCell className="textCentre">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, index)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                          <Popover
                            open={Boolean(popoverOpen[index])}
                            anchorEl={popoverOpen[index]}
                            onClose={() => handleCloseMenu(index)}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          >
                            <MenuItem onClick={() => EditRequest(getCaseIdValue(item))}>
                              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                              Edit
                            </MenuItem>
                            {
                              getCaseStatus(item) === "Closed" && <MenuItem onClick={() => viewReport(getCaseIdValue(item))}>
                                <Iconify icon={'iconoir:stats-report'} sx={{ mr: 2 }} />
                                View Report
                              </MenuItem>
                            }

                            {userInfo.role.role === 'super-admin' ? (<MenuItem
                              sx={{ color: 'error.main' }}
                              onClick={() => handelDeleteForm(getCaseIdValue(item), () => handleCloseMenu(index))}
                            >
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>) : (null)}
                          </Popover>
                        </TableCell>}
                      </TableRow>
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      <h6>No Data Found</h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={dialogOpen} onClose={closeDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>Are you sure you want to delete this case?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmDelete} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 250]}
            component="div"
            count={totalcase}
            rowsPerPage={rowsPerPage}
            page={page}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
            onPageChange={(event, newPage) => setPage(newPage)}
            labelRowsPerPage="Rows per page"
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        {/* {userInfo.role.role !== 'rcu-manager' &&  */}
        {isCasesLoading ? <div><span>Preparing excel data</span>  <Loader size={20} /></div> : casesToBeExport.length > 0 ? <DynamicExcelExport data={casesToBeExport} /> : null}
        {/* } */}
      </Container>
    </>
  );
}
