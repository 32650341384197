import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'

import makeApiRequest from '../../../api/commonApi';

import { TextField, Stack, Typography } from '@mui/material'
const ForgotForm = () => {
    const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [emailError, setEmailError] = useState('');
    const [email, setEmail] = useState('')
    const forgotapi = async () => {
        try {
            setEmailError('');
        
            // Email validation
            if (!email || !emailRegex.test(email)) {
              setEmailError('Please enter a valid email address.');
              return;
            }
            // const Response = await axios.post(`http://localhost:5001/auth/forgotPassword`, {
            //     email: email
            // })
            const Response = await makeApiRequest('post', '/auth/forgotPassword', { email: email });

            if (Response.status == '200') {
                toast.success(Response.data.message)
                navigate('/verifyotp')
            }

        } catch (error) {
            if (error.response && [401, 400, 404].includes(error.response.status)) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error(error?.message);
            }
        }

    }
    return (
        <>


            <Stack spacing={3}>
                <Typography variant="h4" gutterBottom sx={{ my: 3 }}>
                    Forgot password
                </Typography>
                <TextField
                    name="email"
                    label="Email address"
                      value={email}
                    onChange={(e) => setEmail(e.target.value)}
                      error={Boolean(emailError)}
                      helperText={emailError}
                    variant='standard'
                />
            </Stack>
            <LoadingButton className='mt-4' fullWidth size="large" type="submit" variant="contained" onClick={forgotapi} >
                Send Otp
            </LoadingButton >
        </>


    )
}

export default ForgotForm