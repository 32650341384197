import React from 'react';
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';
import Iconify from '../iconify';

function DynamicExcelExport({ data }) {

    const exportToExcel = () => {

        const formDataArray1 = [];
        const formDataArray2 = [];
        const formDataArray3 = [];
        const formDataArray4 = [];

        const uniqueFieldnames = new Set();
        // Loop through each formData array to collect unique fieldnames and case_ids
        data.forEach((formDataArray, arrayIndex) => {
            if (formDataArray.formData) {
                formDataArray.formData.forEach(formDataItem => {
                    const { fieldname, value, FieldAccess, casename, casetype, verificationRemark, userData } = formDataItem;

                    // Check the condition to include in formDataArray1
                    if (fieldname && value && FieldAccess && !casename && !casetype && !verificationRemark) {
                        formDataArray1.push({ fieldname, value });
                        uniqueFieldnames.add(fieldname);
                    } else if (fieldname && value && FieldAccess && casename && casetype && !verificationRemark) {
                        const uniqueKey = formDataArray.formData.find(item => item.fieldname === 'case_id')?.value;
                        if (casetype === 'Profile') {
                            formDataArray2.push({ fieldname: fieldname.replace('verifier', ''), value: userData?.name || value, FieldAccess, casename, casetype, case_id: uniqueKey });
                        } else {
                            formDataArray2.push({ fieldname, value, FieldAccess, casename, casetype, case_id: uniqueKey });
                        }
                    } else if (fieldname && value && FieldAccess && verificationRemark && !casename && !casetype) {
                        const uniqueKey = formDataArray.formData.find(item => item.fieldname === 'case_id')?.value;
                        formDataArray3.push({ fieldname, value, FieldAccess, verificationRemark, case_id: uniqueKey });
                    }
                });

                const uniqueKey = formDataArray.formData.find(item => item.fieldname === 'case_id')?.value;
                formDataArray4.push({ fieldname: "Bank Name", value: formDataArray?.bankId?.bankname || 'NA', case_id: uniqueKey });
                formDataArray4.push({ fieldname: "Branch Name", value: formDataArray?.bankId?.branch || 'NA', case_id: uniqueKey });
                formDataArray4.push({ fieldname: "TAT", value: formDataArray?.TAT, case_id: uniqueKey });
            }
        });


        // Convert the set of unique fieldnames to an array
        const fieldnamesArray1 = Array.from(uniqueFieldnames);
        fieldnamesArray1.splice(1, 0, "Bank Name", "Branch")
        // Create a workbook
        const wb = XLSX.utils.book_new();

        // Create a worksheet with column names from formDataArray1
        const ws1 = XLSX.utils.aoa_to_sheet([['S. No.', ...fieldnamesArray1]]);
        // Create a worksheet with column names for formDataArray2
        const ws2 = XLSX.utils.aoa_to_sheet([
            ['Case Id', 'Bank Name', 'Branch', 'Type', 'Subtype', 'Fieldname', 'Value', 'Status', 'Remark', 'Over All RCU Status', 'TAT', 'Case Close Date']
        ]);

        // Set column widths for ws1
        ws1['!cols'] = fieldnamesArray1.map(() => ({ wch: 20 }));

        // Set column widths for ws2
        ws2['!cols'] = [
            { wch: 15 }, // Case Id
            { wch: 15 }, // Bank Name
            { wch: 15 }, // Branch
            { wch: 15 }, // Type
            { wch: 15 }, // Subtype
            { wch: 20 }, // Fieldname
            { wch: 20 }, // Value
            { wch: 20 }, // Status
            { wch: 20 }, // Remark
            { wch: 20 }, // Over All RCU Status
            { wch: 10 }, // TAT
            { wch: 20 }  // Case Close Date
        ];

        // Iterate through formDataArray2 to populate data
        formDataArray2.forEach((dataItem, rowIndex) => {
            const { case_id, casetype, casename, fieldname, value } = dataItem;
            const getStatusAndRemarkField = formDataArray3.find(item => item.fieldname.replace('STRINGTOBEREMOVE', '') === casename && item.case_id === case_id);
            const getOverAllStatus = formDataArray3.find(item => item.fieldname === "OVER ALL RCU STATUS" && item.case_id === case_id);
            const getCaseCloseData = formDataArray3.find(item => item.fieldname === "Case close Date" && item.case_id === case_id);
            const getBankName = formDataArray4.find(item => item.fieldname === "Bank Name" && item.case_id === case_id);
            const getBranchName = formDataArray4.find(item => item.fieldname === "Branch Name" && item.case_id === case_id);
            const getTAT = formDataArray4.find(item => item.fieldname === "TAT" && item.case_id === case_id);

            // Add the row to the worksheet
            XLSX.utils.sheet_add_aoa(ws2, [[case_id, getBankName?.value, getBranchName?.value, casetype  || 'NA', casename  || 'NA', fieldname || 'NA', value || 'NA', getStatusAndRemarkField?.value || 'NA', getStatusAndRemarkField?.verificationRemark || 'NA', getOverAllStatus?.value || 'NA', getTAT?.value, getCaseCloseData?.value || 'NA']], { origin: rowIndex + 1, raw: false });
        });
        // Loop through each formDataArray1 item to populate data
        data.forEach((formDataArray, rowIndex) => {
            // Add a Serial Number in the first column
            const rowValues = [rowIndex + 1];

            // Iterate through fieldnamesArray1 to get values from data array
            fieldnamesArray1.forEach(fieldname => {
                const correspondingData = formDataArray.formData.find(item => item.fieldname === fieldname);
                if (fieldname === "Case Received By") {
                    rowValues.push(correspondingData ? correspondingData?.userData?.name : 'NA');
                } else if (fieldname === "Bank Name") {
                    const correspondingData = formDataArray.formData.find(item => item.fieldname === "case_id");
                    const caseId = correspondingData?.value || "";
                    const getBankName = formDataArray4.find(item => item.fieldname === "Bank Name" && item.case_id === caseId);
                    rowValues.push(getBankName?.value || 'NA');
                } else if (fieldname === "Branch") {
                    const correspondingData = formDataArray.formData.find(item => item.fieldname === "case_id");
                    const caseId = correspondingData?.value || "";
                    const getBranchName = formDataArray4.find(item => item.fieldname === "Branch Name" && item.case_id === caseId);
                    rowValues.push(getBranchName?.value || 'NA');
                } else {
                    rowValues.push(correspondingData ? correspondingData?.value : 'NA');
                }
            });

            // Add the row to the worksheet
            XLSX.utils.sheet_add_aoa(ws1, [rowValues], { origin: rowIndex + 1, raw: false });
        });



        // Append the worksheets to the workbook
        XLSX.utils.book_append_sheet(wb, ws1, 'Sheet 1');
        XLSX.utils.book_append_sheet(wb, ws2, 'Sheet 2');

        // Save the workbook to a file
        XLSX.writeFile(wb, 'All Cases.xlsx');

    };

    return (
        <Button variant="contained" startIcon={<Iconify icon="vscode-icons:file-type-excel" />} onClick={exportToExcel}>
            Export to Excel
        </Button>
    );
}

export default DynamicExcelExport;
