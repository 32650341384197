import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { useParams, Link, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MenuItem, CircularProgress } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import makeApiRequest from '../../api/commonApi';
import { compressImage } from '../../utils/commonFunction';

const EditRequest = () => {
  const history = useNavigate();
  const { loanId } = useParams();
  const [formData, setFormData] = useState([]);
  const [fields, setFields] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [verifierlist, setVerifierlist] = useState([]);

  const [selectedVerifier, setSelectedVerifier] = useState({ id: '', name: '' });
  useEffect(() => {
    const VerfierList = async () => {
      try {
        const verisresList = await makeApiRequest('get', '/rolesByUsers');

        if (verisresList.status === 200) {
          setVerifierlist(verisresList.data.data);
        } else {
          console.error('Failed to fetch layouts');
        }
      } catch (error) {
        console.error('Error fetching layouts:', error);
      }
    };

    VerfierList();
  }, []);
  const handelselectverfier = (veriID, veriName) => {
    // Handle the selected verifier (both ID and name)
    // setSelectedVerifier({ id: veriID, name: veriName });
    // handleInputChange('verifier', veriName, userRoles);
    // handleInputChange('verifier', veriID, userRoles);
  };
  const handleFetchFormData = async () => {
    try {
      setIsLoading(true);
      const response = await makeApiRequest('get', `/formdata/${loanId}`);
      const data = response.data.data;
      if (data.length > 0) {
        setFormData(data);
        const columns = Object.keys(data[0]).filter((fieldName) => fieldName !== 'FieldAccess');
        setFields(columns);
      } else {
        console.log('No form data found in the response.');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  // removing word
  // const updatedFormData = formData.map((item) => {
  //   if (item.fieldname.includes('STRINGTOBEREMOVE')) {
  //     item.fieldname = item.fieldname.replace('STRINGTOBEREMOVE', '');
  //   }
  //   return item;
  // });

  useEffect(() => {
    // setFormData(updatedFormData);
    handleFetchFormData();
  }, []);

  const handleInputChange = (fieldname, value) => {
    const updatedFormData = {
      fieldname,
      value,
    };

    setFormData((prevFormData) => {
      const fieldIndex = prevFormData.findIndex((field) => field.fieldname === fieldname);

      if (fieldIndex !== -1) {
        const updatedFields = [...prevFormData];
        updatedFields[fieldIndex] = updatedFormData;
        return updatedFields;
      }

      return [...prevFormData, updatedFormData];
    });
  };

  // image update option
  const handleImageUpload = async (fieldname, imageFile) => {
    try {
      // avoid video file
      if (imageFile.type.startsWith('video/')) {
        toast.error('video not allow');
        return;
      }
      if (imageFile.type.startsWith('image/')) {

        const compressedFile = await compressImage(imageFile);

        // Set size of compressed file
        if (compressedFile.size > 5 * 1024 * 1024) {
          toast.error('Please upload a file under 5 MB');
          return;
        }

        const data = new FormData();
        data.append('file', compressedFile);
        data.append('upload_preset', 'acpienfk');

        await axios
          .post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`, data)
          .then((response) => {
            if (response.status === 200) {
              const imageURL = response.data.url;
              handleInputChange(fieldname, imageURL);
            }
          });
      } else {

        const data = new FormData();
        data.append('file', imageFile);
        data.append('upload_preset', 'acpienfk');
        await axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`, data).then((response) => {

          const imageURL = response.data.url;
          handleInputChange(fieldname, imageURL);
        });
      }

    } catch (error) {
      console.log('image upload errror ', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await makeApiRequest('put', `/formdata/${loanId}`, { formData });
      if (response.status === 200) {
        toast.success(response.data.message);
        history('/dashboard/allrequest/all');
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="p-2 m-2">
        <Link to={`/dashboard/allrequest/all`}>
          <ArrowBackIcon />
        </Link>
      </div>
      <div className="text-center">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Container>
            <Grid container spacing={2}>
              {formData.map((item, dataIndex) => (
                <Grid key={dataIndex} item xs={12} sm={6} md={4} lg={3}>
                  <Paper style={{ padding: '10px' }}>
                    {!item.fieldname.includes('STRINGTOBEREMOVE') && item.fieldname !== "ReportImages" &&
                      <label htmlFor={item.fieldname}>{item.fieldname}</label>
                    }
                    {item.fieldname === 'status' ? (
                      <TextField
                        id={item.fieldname}
                        name={item.fieldname}
                        select
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={item.value || 'Received'}
                        onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                      >
                        <MenuItem value="received">Received</MenuItem>
                        <MenuItem value="initiated">Initiated </MenuItem>
                        <MenuItem value="verified">Verified</MenuItem>
                        <MenuItem value="submitted">Submitted</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                        <MenuItem value="reopened">Reopened</MenuItem>
                        <MenuItem value="reinitiated">Reinitiated</MenuItem>
                        <MenuItem value="reverified">Reverified</MenuItem>
                        <MenuItem value="resubmitted">Resubmitted</MenuItem>
                      </TextField>
                    ) : item.fieldname.includes('STRINGTOBEREMOVE') ? (
                      <>
                        <label htmlFor={item.fieldname}>{item.fieldname.replace('STRINGTOBEREMOVE', '')}</label>
                        <TextField
                          id={item.fieldname}
                          name={item.fieldname}
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={item.value || ''}
                          onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                        />
                        {/* <TextField
                          id={item.fieldname}
                          name={item.fieldname}
                          select
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={item.value || 'NEGATIVE'}
                          onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                        >
                          <MenuItem value="POSITIVE">POSITIVE</MenuItem>
                          <MenuItem value="NEGATIVE">NEGATIVE</MenuItem>
                        </TextField> */}
                      </>
                    )
                      :
                      item.fieldname === 'Case Received By' ? (
                        <TextField
                          id={item.fieldname}
                          name={item.fieldname}
                          variant="outlined"
                          disabled
                          size="small"
                          fullWidth
                          value={item?.userData?.name || ''}
                          onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                        />)
                        :
                        item.fieldname.includes('verifier') ? (
                          <TextField
                            id={item.fieldname}
                            name={item.fieldname}
                            select
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={item.value || 'N?A'}
                            onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                          >
                            {verifierlist?.map((data, index) => (
                              <MenuItem key={index} value={data._id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </TextField>)
                          :
                          item.fieldname === 'Case Received Date' ? (
                            <>
                              <input
                                type="date"
                                name="Case Received Date"
                                className="form-control m-1"
                                value={item.value}
                                onChange={(event) => {
                                  handleInputChange('Case Received Date', event.target.value);
                                }}
                              />
                            </>)
                            :
                            item.fieldname === 'Case close Date' ? (
                              <>
                                <input
                                  type="date"
                                  name="Case close Date"
                                  className="form-control m-1"
                                  value={item.value}
                                  onChange={(event) => {
                                    handleInputChange('Case close Date', event.target.value);
                                  }}
                                />
                              </>) : item.fieldname === 'OVER ALL RCU STATUS' ? (
                                <TextField
                                  id={item.fieldname}
                                  name={item.fieldname}
                                  variant="outlined"
                                  disabled
                                  size="small"
                                  fullWidth
                                  value={item.value || ''}
                                  onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                                />
                                // <TextField
                                //   id={item.fieldname}
                                //   name={item.fieldname}
                                //   select
                                //   variant="outlined"
                                //   size="small"
                                //   fullWidth
                                //   value={item.value || 'NEGATIVE'}
                                //   onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                                // >
                                //   {/* <MenuItem value="POSITIVE">POSITIVE</MenuItem> */}
                                //   {/* <MenuItem value="NEGATIVE">NEGATIVE</MenuItem> */}
                                // </TextField>
                              )
                              : item.fieldname === 'verifier' ? (
                                // <TextField
                                //   id={item.fieldname}
                                //   name={item.fieldname}
                                //   select
                                //   variant="outlined"
                                //   size="small"
                                //   fullWidth
                                //   value={item.value || 'NEGATIVE'}
                                //   onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                                // >
                                //   <MenuItem value="POSITIVE">POSITIVE</MenuItem>
                                //   <MenuItem value="NEGATIVE">NEGATIVE</MenuItem>
                                // </TextField>
                                <TextField
                                  id={item.fieldname}
                                  name={item.fieldname}
                                  select
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={item.value || 'N?A'}
                                  onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                                >
                                  {verifierlist?.map((data, index) => (
                                    <MenuItem key={index} value={data._id}>
                                      {data.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              ) : item.value.includes('cloudinary') ? (
                                <>
                                  {item.value.includes('image') ? (
                                    <>
                                      <img src={item.value} alt="imageslist" style={{ maxWidth: '100px' }} />
                                      <input
                                        type="file"
                                        // accept="image/*"
                                        onChange={(event) => handleImageUpload(item.fieldname, event.target.files[0])}
                                      />
                                    </>
                                  ) : item.value.includes('video') ? (
                                    <>
                                      <video controls width="90%">
                                        <source src={item.value} type="video/mp4" />
                                        <track kind="captions" src="" label="English" />{' '}
                                      </video>
                                      <input
                                        type="file"
                                        // accept="image/*"
                                        onChange={(event) => handleImageUpload(item.fieldname, event.target.files[0])}
                                      />
                                    </>
                                  ) : (
                                    <TextField
                                      id={item.fieldname}
                                      name={item.fieldname}
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      value={item.value || ''}
                                      onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                                    />
                                  )}
                                </>
                              ) : item.fieldname !== "ReportImages" ? (
                                <TextField
                                  id={item.fieldname}
                                  name={item.fieldname}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={item.value || ''}
                                  onChange={(event) => handleInputChange(item.fieldname, event.target.value)}
                                />
                              ) : null}
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <div className="text-center my-2 mt-4">
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Update
              </Button>
            </div>
            <ToastContainer />
          </Container>
        )}
      </div>
    </>
  );
};

export default EditRequest;
