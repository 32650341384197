
// export default UpdateForm;
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle,  DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Tooltip, TextField } from '@mui/material';
import { toast } from 'react-toastify';
// import "./FormBuilder.css";
import { Link, useParams, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import PropTypes from 'prop-types';
import makeApiRequest from '../../api/commonApi';
import MainSidebar from '../sidebar/Sidebar';
const UpdateForm = () => {
  // State variables
  const { bankId } = useParams();
  const history = useNavigate();
  const stepname = "1"

  const [bankname, setbankname] = useState('');

  const findbankname = async (req, res) => {
    try {
      const response = await makeApiRequest('get', `/bankformlayout/${bankId}`);
      setbankname(response.data.data.bankId.bankname);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    findbankname();
  }, []);

  // update dropdown states
  const [index, setIndex] = useState(0);
  const [optionIndex, setOptionIndex] = useState(0);
  const [dropdownfield, setdropdownfield] = useState('');
  const [openDemoModal, setOpenDemoModal] = useState(false);
  const [optionName, setOptionName] = useState('');
  // end

  // checkbox
  const [selectedValues, setSelectedValues] = useState([]);
  // const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    makeApiRequest('get', '/role-for-create-product')
      .then((response) => {
        const roleOptions = response.data.data.map((role) => role);
        setUserRoles(roleOptions);
      })
      .catch((error) => {
        console.error('Error fetching roles:', error);
      });
  }, []);

  const handleOpenDialogChekBox = (index, selectedCheckboxValues) => {
    setSelectedValues(selectedCheckboxValues);
    setFieldIndex(index);
    setShowDialog(true);
  };

  const handleCloseDialogChekBox = () => {
    setShowDialog(false);
    setTimeout(() => {
      setSelectedValues([]);
      setFieldIndex(-1);
    }, 100);
  };

  const handleCheckboxChange = (fieldIndex, value) => {
    const updatedFields = [...fields];

    if (updatedFields[fieldIndex].FieldAccess.includes(value)) {
      updatedFields[fieldIndex].FieldAccess = updatedFields[fieldIndex].FieldAccess.filter(
        (selectedValue) => selectedValue !== value
      );
    } else {
      updatedFields[fieldIndex].FieldAccess = [...updatedFields[fieldIndex].FieldAccess, value];
    }

    setFields(updatedFields);
    setSelectedValues(updatedFields[fieldIndex].FieldAccess);
  };

  // field name
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [newLabel, setNewLabel] = useState('');
  const [fieldIndex, setFieldIndex] = useState(-1);

  const [placeholderDialog, setPlaceHolderDialog] = useState(false);
  const [newPlaceHolder, setNewPlaceholder] = useState('');
  const [PlaceHolderIndex] = useState(-1);

  const handleOpenDialog = (index, label) => {
    setFieldIndex(index);
    setNewLabel(label);
    setDialogOpen(true);
  };


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // placeholder
  const handleCloseDialogPlaceholder = () => {
    setPlaceHolderDialog(false);
  };

  // const handleLabelChange = () => {
  //   if (newLabel) {
  //     handleFieldLabelChange(fieldIndex, newLabel);
  //     handleCloseDialog();
  //   }
  // };
  // old
  // const handleLabelChange = () => {
  //   if (newLabel) {
  //     // cheking inn currnt step state
  //     const isUniqueLabelInCurrentStep = fields.every((field, index) => {
  //       return index === fieldIndex || field.label !== newLabel;
  //     });
  // // if not in cuuent step (fields state) then cheking in localstoreage
  //     const allFields = JSON.parse(localStorage.getItem('layouts'))?.steps.flatMap((step) => step.fields) || [];
  //     const isUniqueLabelAcrossAllSteps = allFields.every((field) => field?.label !== newLabel);
  // // if not in localstorage and not in field state then add new field
  //     if (isUniqueLabelInCurrentStep && isUniqueLabelAcrossAllSteps) {
  //       handleFieldLabelChange(fieldIndex, newLabel);
  //       handleCloseDialog();
  //     } else {
  //       toast.error('Field name should be unique.');
  //     }
  //   }
  // };
  // new
  const handleLabelChange = () => {
    if (newLabel) {
      // Checking in current step state
      const isUniqueLabelInCurrentStep = fields.every((field, index) => index === fieldIndex || field.label !== newLabel);
  
      // If not in current step (fields state), then add new field
      if (isUniqueLabelInCurrentStep) {
        handleFieldLabelChange(fieldIndex, newLabel);
        handleCloseDialog();
      } else {
        toast.error('Field name should be unique.');
      }
    }
  };
  

  // placeholder
  const handlePlaceholderChange = () => {
    if (newPlaceHolder) {
      handleFieldPlaceholderChange(PlaceHolderIndex, newPlaceHolder);
      handleCloseDialogPlaceholder();
    }
  };

  // show form
  const [openPopup, setOpenPopup] = useState(false);
  const [fields, setFields] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState('');

  const handleDrop = (event) => {
    event.preventDefault();
    const field = event.dataTransfer.getData('field');
    setFields((prevFields) => [...prevFields, { type: field, label: field }]);
  };


  const handleFieldLabelChange = (index, newLabel) => {
    const updatedFields = [...fields];
    updatedFields[index].label = newLabel;
    updatedFields[index].FieldAccess = fields[index]?.FieldAccess || userRoles.map((item) => item.role)
    setFields(updatedFields);
  };
  const handleFieldPlaceholderChange = (index, newPlaceholder) => {
    const updatedFields = [...fields];
    updatedFields[index].placeholder = newPlaceholder;
    setFields(updatedFields);
  };
  const handleAddOption = (index) => {
    const updatedFields = [...fields];
    if (!updatedFields[index].options) {
      updatedFields[index].options = [];
    }
    updatedFields[index].options.push('New Option');
    setFields(updatedFields);
  };

  const handleUpdateOptionName = () => {
    const updatedFields = [...fields];
    if (updatedFields[index].options) {
      updatedFields[index].options[optionIndex] = optionName;
    } else {
      updatedFields[index].options = [optionName];
    }
    setdropdownfield('');
    setIndex('');
    setOptionIndex('');
    setFields(updatedFields);
    setOpenDemoModal(false);
    setOpenDemoModal(false);
    setOptionName('');
  };

  const handleDeleteOption = (fieldIndex, optionIndex) => {
    const updatedFields = [...fields];
    const options = updatedFields[fieldIndex].options || [];
    options.splice(optionIndex, 1);
    updatedFields[fieldIndex].options = options;
    setFields(updatedFields);
  };
  

  const handleDeleteField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleSaveLayout = async () => {
    try {
      const currentStepFields = fields.map((field) => ({ ...field }));
      currentStepFields.forEach(field => {
        if (field.type === 'checkbox') {
          field.FieldAccess = field.FieldAccess.map((value) => ({ type: value }));
        }
      });
      const layoutToSave = {
        bankId,
        steps: [["1", currentStepFields]],
      };

      // let allLayoutsData = localStorage.getItem('layouts');
      // if (allLayoutsData) {
      //   allLayoutsData = JSON.parse(allLayoutsData);
      //   // Check if the layout data already exists for the current step
      //   const existingStepData = allLayoutsData.steps.find((stepData) => stepData[0] === stepname);
      //   if (existingStepData) {
      //     // If the step data exists, update the fields
      //     existingStepData[1] = currentStepFields;
      //   } else {
      //     // If the step data doesn't exist, add it to the steps array
      //     allLayoutsData.steps.push(layoutToSave.steps[0]);
      //   }
      // } else {
      //   // If there is no existing layout data, initialize it with the current step data
      //   allLayoutsData = { steps: [layoutToSave.steps[0]] };
      // }

      // Save the updated layout data back to local storage
      // localStorage.setItem('layouts', JSON.stringify(allLayoutsData));

        // Make an API call to save the data for step 2
        const response = await makeApiRequest('put', `/bankformlayout/${bankId}`, {
          bankId,
          steps: layoutToSave.steps,
        }); 
        setOpenPopup(true);
        console.log('API Response:', response.data);
    } catch (error) {
      console.error('Error saving layout:', error);
    }
  };


  const handleResetForm = () => {
    setFields([]);
    setOpenPopup(false);
  };

  // useEffect(() => {
  //   handleResetForm();
  //   // Load data from local storage and set the fields when the component mounts
  //   const savedLayout = localStorage.getItem('layouts');
  //   if (savedLayout) {
  //     const data = JSON.parse(savedLayout);
  //     if (data.steps.length >= stepname) {
  //       const stepData = data.steps[stepname - 1];
  //       setFields(stepData[1]);
  //     }
  //   }
  // }, [stepname]);

  // show form
  // Handle loading a layout
  const handleLoadLayout = async () => {
    try {
      const response = await makeApiRequest('get', `/bankformlayout/${bankId}`);

      const loadedLayout = response.data.data;
      // Set the selected bank ID and form fields according to the loaded layout
      setSelectedBankId(loadedLayout.bankId?._id || '');
      const loadedFields = loadedLayout.steps.find((step) => step.stepNumber === "1").fileds;
      setFields(loadedFields);
    } catch (error) {
      console.error('Error loading layout:', error);
    }
  };
  useEffect(() => {
    handleLoadLayout();
    // eslint-disable-next-line
  }, [stepname]);

  // Fetch layout on selected bank or step change
  useEffect(() => {
    const fetchLayout = async () => {
      if (selectedBankId && stepname) {
        await handleLoadLayout(selectedBankId);
      }
    };

    fetchLayout();
    // eslint-disable-next-line
  }, [selectedBankId, stepname]);

  const handleClosePopup = () => {
    setOpenPopup(false);
    localStorage.removeItem('layouts');
    // window.location.reload();
    history('/dashboard/forms');
  };

  const renderFields = () => fields?.map((field, index) => (
    <div key={index} className="form-field row position-relative">
      <div className="col-9">
        <label htmlFor="inputfileds">{field.label}</label>
        {renderInput(field, index, field.label)}
      </div>


      <div className="corner-icons col-3">
        <Tooltip title="Edit" arrow>
          <EditIcon
            className="buttonspointer , editButtonspointer"
            onClick={(event) => {
              event.stopPropagation(); // Prevents the click event from propagating to parent elements
              handleOpenDialog(index, field.label);
            }}
          />
        </Tooltip>
        {/* placeholder */}

        {/* <AbcIcon
 className="buttonspointer"
 onClick={(event)=>{
  event.stopPropagation()
  handelOpenForPlaceHolder(index,field.placeholder)
 }}
/> */}
        {/* accesbility */}
        {/* {field.label && (field.label !== "input-text" && field.label !== "input-number" && field.label !== "input-dropdown" && field.label !== "input-image" && field.label !== "input-date") ? (
            <AccessibilityIcon
              onClick={(event) => {
                event.stopPropagation();
                handleOpenDialogChekBox(index, field.FieldAccess);
              }}
              className="buttonspointer"
            />
          ) : (null)
          } */}
        <Tooltip title="Delete" arrow>
          <DeleteIcon onClick={() => handleDeleteField(index)} className="buttonspointer , deleteButtonspointer" />
        </Tooltip>

        {field.label &&
          field.label !== 'input-text' &&
          field.label !== 'input-number' &&
          field.label !== 'input-dropdown' &&
          field.label !== 'input-image' &&
          field.label !== 'input-audio' &&
          field.label !== 'input-date' ? (
          <Tooltip title="Accessibility" arrow>
            <LockPersonIcon
              onClick={(event) => {
                event.stopPropagation();
                handleOpenDialogChekBox(index, field.FieldAccess);
              }}
              className="buttonspointer , accesbilityButtonspointer"
            />
          </Tooltip>
        ) : null}




        <Dialog open={placeholderDialog} onClose={handleCloseDialogPlaceholder}>
          <DialogTitle>Edit placeholder</DialogTitle>
          <DialogContent>
            <input type="text" value={newPlaceHolder} onChange={(e) => setNewPlaceholder(e.target.value)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogPlaceholder} color="primary">
              Cancel
            </Button>
            <Button onClick={handlePlaceholderChange} color="primary">
              Save placeholder
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  ));

  // Render input based on field type
  const renderInput = (field, index, label) => {
    switch (field.type) {
      case 'input-text':
        return (
          <input type="text" disabled name={label} placeholder={field.placeholder || ''} className="form-control" />
        );
      case 'input-number':
        return (
          <input type="number" disabled name={label} placeholder={field.placeholder || ''} className="form-control" />
        );
      case 'input-image':
        return (
          <input type="file" disabled name={label} placeholder={field.placeholder || ''} className="form-control" />
        );
        case 'input-audio':
          return (
            <input type="file" disabled name={label} placeholder={field.placeholder || ''} className="form-control" />
          );
      case 'input-date':
        return (
          <input type="date" disabled name={label} placeholder={field.placeholder || ''} className="form-control" />
        );

        case 'input-dropdown':
          return (
            <div key={index} className="form-field">
              <select
                className="form-control"
                placeholder={field.placeholder || ''}
                name={label}
              >
                {field.options?.map((option, optionIndex) => (
                  <option key={optionIndex} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {field.label.toLowerCase() !== 'input-dropdown' && (
                <div>
                  <button className="btn btn-sm btn-success ms-2" onClick={() => handleAddOption(index)}>
                    Add Options
                  </button>
                  {field.options?.map((option, optionIndex) => (
                    <div key={optionIndex} className="d-flex align-items-center">
                      <button   
                        className="btn btn-sm btn-secondary ms-2 mt-1"
                        onClick={() => {
                          dropdownvalue(index, optionIndex, option);
                        }}
                      >
                        Edit <b>{option}</b>
                      </button>
                      <button
                        className="btn btn-sm btn-danger ms-2 mt-1"
                        onClick={() => {
                          handleDeleteOption(index, optionIndex);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  ))} 
                </div>
              )}
            </div>
          );
        
      default:
        return null;
    }
  };


  const formContainerClass = openPopup ? 'form-container animated shake' : 'form-container';

  // update dropdown option

  const dropdownvalue = (index, optionIndex, option) => {
    setIndex(index);
    setOptionIndex(optionIndex);
    setOpenDemoModal(true);
    setOptionName(option);

    // if (!openDemoModal) { return dropdownfield; }
  };

  return (
    <div className='row' >
    <div className="col-3">
          {/* <CaseSidebar /> */}
          <MainSidebar />
        {/* </div> */}
      </div>
    <div className='col-9' >  
      <div
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
        className="form-builder container row align-items-center  "
      >
        <div className="text-center">
          <h5>{bankname}</h5>
        </div>
        <div className={`${formContainerClass} col-12 border-warning w-75 p-5  mainFrom`}>
          {renderFields()}
          <Dialog open={openPopup} onClose={handleClosePopup}>
            <DialogTitle>Layout updated</DialogTitle>
            <DialogContent>Your form has been updated successfully</DialogContent>
            <DialogActions>
              <Link to="/dashboard/forms">
                <Button onClick={handleClosePopup} color="primary" autoFocus>
                  OK
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
        </div>


        {/* dropdown popup */}
        <Dialog open={openDemoModal} onClose={() => setOpenDemoModal(false)}>
          <DialogTitle>Update Option</DialogTitle>
          <DialogContent>
            {/* Add your modal content here */}
            <p>Enter option name</p>
            <TextField
              id="outlined-basic"
              onChange={(e) => setdropdownfield(e.target.value)}
              label="Option Name"
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleUpdateOptionName();
              }}
              color="primary"
            >
              update
            </Button>
            <Button onClick={() => setOpenDemoModal(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <div className="d-flex align-items-center justify-content-center gap-5 mt-3">
          <button type='button' onClick={handleResetForm} className="btn btn-danger">
            Reset Form
          </button>

          <button type='button' onClick={handleSaveLayout} className="btn btn-primary">
          Update From
        </button>
        </div>

        {/* dropdown popup */}
        <Dialog open={openDemoModal} onClose={() => {
          setOptionName('');
          setOpenDemoModal(false)
        }}>
          <DialogTitle>Update Option</DialogTitle>
          <DialogContent>
            {/* Add your modal content here */}
            <p>Enter option name</p>
            <TextField id="outlined-basic" value={optionName} onChange={(e) => setOptionName(e.target.value)} label="Option Name" variant="outlined" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOptionName('');
              setOpenDemoModal(false)
            }} color="primary">
              Close
            </Button>
            <Button disabled={!optionName.trim()} onClick={() => { handleUpdateOptionName() }} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Edit Label</DialogTitle>
          <DialogContent>
            <input type="text" value={newLabel} onChange={(e) => setNewLabel(e.target.value)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleLabelChange} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showDialog} onClose={handleCloseDialogChekBox}>
          <DialogTitle>Field should be access by</DialogTitle>
          <DialogContent>
            {/* {options.map((option, index) => {
                return (
                  <label key={option._id} htmlFor="knj" className="p-2">
                    <input
                      type="checkbox"
                      value={option.role}
                      checked={dev && dev?.includes(option.role)}
                      onChange={() => handleCheckboxChange(fieldIndex, option.role)}
                      className="m-1"
                    />
                    {option.role}
                  </label>
                );
              })} */}
            {userRoles.map((option) => (option.role !== 'admin' && option.role !== 'super-admin') && <FormControlLabel
              control={
                <Checkbox
                  checked={selectedValues?.includes(option.role)}
                  onChange={() => handleCheckboxChange(fieldIndex, option.role)}
                  value={option.role}
                />
              }
              label={option.role}
              sx={{ flexGrow: 1, m: 0 }}
              key={option._id}
            />)}
          </DialogContent>
          {/* <DialogContent>
             <Button onClick={removeAllAcess} >Edit</Button>
            </DialogContent> */}
          <DialogActions>
            <Button onClick={handleCloseDialogChekBox} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      </div>
    </div>
  );
};



export default UpdateForm;
