import React, { useState } from 'react';
import { Button, Grid, Paper, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/form.css';
import { Helmet } from 'react-helmet-async';
import CloseIcon from '@mui/icons-material/Close';
import makeApiRequest from '../../api/commonApi';
import Loader from '../Loader';

const AddBankForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    branch: '',
    // email: '',
    address: '',
  });
  const isBankNameVaid = (name) =>{
    const brankRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/
    return brankRegex.test(name)
  }

  // const isEmailValid = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const isBranchNameVaid = (branch) =>{
    const branchRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/
    return branchRegex.test(branch)
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const backtoMain = () => {
    navigate('/dashboard/banklist');
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        bankname: formData.name,
        // bankemail: formData.email,
        address: formData.address,
        branch: formData.branch,
      };

      if(!isBankNameVaid(formData.name)){
        toast.error('Please enter a valid bank name');
        return;
      }

      // if (!isEmailValid(formData.email)) {
      //   toast.error('Please enter a valid email address');
      //   return;
      // }

      if(!isBranchNameVaid(formData.branch)){
        toast.error('Please enter a valid branch name');
        return;
      }

      try {
        setIsLoading(true);
        const response = await makeApiRequest('post', '/bank', data);
        if (response.status === 201) {
          toast.success('Bank added successfully!');
          navigate('/dashboard/banklist');
        }
        if (response.status === 400) {
          toast.error('Bank with the same name and branch already exists.');
        } else {
          console.log('error');
        }
      } catch (error) {
        console.log(error.response);
        toast.error(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title> AddBank | Quick Support </title>
      </Helmet>
      <Grid>
        <Paper elevation={20} className="paperStyle">
          <div className="CrossIconParent">
            <CloseIcon onClick={backtoMain} className="iconCross" />
          </div>
          <Grid align="center">
            <h2 className="headerStyle">Add Bank</h2>
          </Grid>

          <form className="row" onSubmit={onSubmit}>
            <Grid>
              <TextField
                label="Bank Name"
                variant="standard"
                fullWidth
                type="text"
                name="name"
                autoComplete="off"
                className="my-2"
                value={formData.name}
                onChange={handleInputChange}
                required
              />

              {/* <TextField
                label="Bank Email"
                fullWidth
                variant="standard"
                type="text"
                name="email"
                autoComplete="off"
                className="my-2"
                value={formData.email}
                onChange={handleInputChange}
                required
              /> */}

              <TextField
                label="Branch"
                fullWidth
                variant="standard"
                type="text"
                name="branch"
                autoComplete="off"
                className="my-2"
                value={formData.branch}
                onChange={handleInputChange}
                required
              />

              <TextField
                label="Address"
                fullWidth
                variant="standard"
                type="text"
                required
                name="address"
                autoComplete="off"
                className="my-2"
                value={formData.address}
                onChange={handleInputChange}
              />

              <div className="text-center my-2 mt-4">
                {isLoading ? (
                  <div className=" d-flex justify-content-center align-items-center ">
                    <Loader />
                  </div>
                ) : (
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                )}
              </div>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </>
  );
};

export default AddBankForm;
