import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import makeApiRequest from '../../api/commonApi';

const initialState = {
    userInfo: null,
    loading: false,
    error: null
};

const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async () => {
    const response = await makeApiRequest('get', '/me');
    return response.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logoutUser: (state) => {
            state.userInfo = null;
            state.loading = false;
            state.error = null;
        },
        resetPassword: (state, action) => {
            state.id = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.userInfo = action.payload.user;
                state.error = null;
            })
            .addCase(fetchUserInfo.rejected, (state, action) => {
                console.log('action error', action.error);
                state.loading = false;
                state.error = action.error.message;
            })
    }
});

export { fetchUserInfo };
export const { logoutUser } = userSlice.actions;
export const { resetPassword } = userSlice.actions

export default userSlice.reducer;