import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions,  Button, FormControl, FormControlLabel, Radio,  RadioGroup, CircularProgress} from '@mui/material';
// import "./FormBuilder.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import { useParams, useNavigate,Link } from 'react-router-dom';
import '../../assets/css/form.css';
import makeApiRequest from '../../api/commonApi';

const CaseFormViwer = () => {
  // State variables
  const { type, Id } = useParams();
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [openPopup, setOpenPopup] = useState(false);
  const [fields, setFields] = useState([]);
  const stepname = '1';

  const [selectedBankId, setSelectedBankId] = useState('');
  const [lodedBankName, setLoadedBankName] = useState('');
  // const [bank, setBank] = useState();
  // const [userRoles, setUserRoles] = useState([]);
  // const [caseIDValue, SetCaseIDValue] = useState('');
  // Fetch layouts on component mount
  // useEffect(() => {
  //   const fetchLayouts = async () => {
  //     try {
  //       const rolesList = await makeApiRequest('get', '/role');

  //       // if (rolesList.status === 200) {
  //       //   // const roleOptions = rolesList.data.data?.map((role) => role.role);
  //       //   // setUserRoles(roleOptions);
  //       // } else {
  //       //   console.error('Failed to fetch layouts');
  //       // }
  //     } catch (error) {
  //       console.error('Error fetching layouts:', error);
  //     }
  //   };

  //   fetchLayouts();
  // }, []);

  // Handle loading a layout
  const handleLoadLayout = async () => { 
    setIsLoading(true);

    try {
      const response = await makeApiRequest('get', `/${type}/${Id}`);

      const loadedLayout = response.data.data;

      setLoadedBankName(loadedLayout.caseId.casename);
      // setBank(loadedLayout.bankId);
      setSelectedBankId(loadedLayout.caseId?._id || '');
      const loadedFields = loadedLayout.steps.find((step) => step.stepNumber === stepname).fileds;
      setFields(loadedFields);
    } catch (error) {
      console.error('Error loading layout:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleLoadLayout();
  }, [stepname]);

  // Fetch layout on selected bank or step change
  useEffect(() => {
    const fetchLayout = async () => {
      if (selectedBankId && stepname) {
        await handleLoadLayout(selectedBankId);
      }
    };

    fetchLayout();
  }, [selectedBankId, stepname]);

  // const handleInputChange = (fieldname, value, FieldAccess) => {
  //   const updatedFormData = {
  //     fieldname,
  //     value,
  //     FieldAccess,
  //   };

  //   setFormData((prevFormData) => {
  //     const fieldIndex = prevFormData.findIndex((field) => field.fieldname === fieldname);

  //     if (fieldIndex !== -1) {
  //       const updatedFields = [...prevFormData];
  //       updatedFields[fieldIndex] = updatedFormData;
  //       return updatedFields;
  //     }

  //     return [...prevFormData, updatedFormData];
  //   });
  // };

  // const handleSaveData = async () => {
  //   console.log("test");
  //   try {
  //     if (caseIDValue.length === 0) {
  //       toast.error('Please Enter Case Id or Applicant Name.');
  //       return;
  //     }
            
  //     if (stepname === '1') {
  //       const dataToSave = {
  //         formData,
  //       };
  //       console.log('saved data', dataToSave);
  //       await makeApiRequest('post', '/formdata', { formData: dataToSave, bankId: bank });
  //       setOpenPopup(true);
  //       console.log('Form data saved successfully!');
  //     }
  //   } catch (error) {
  //     console.error('Error saving form data:', error);
  //   }
  // };

  const handleClosePopup = () => {
    setOpenPopup(false);
    localStorage.removeItem('layouts');
    history('/dashboard/Case-forms');
  };

  // Render the form fields
  const renderFields = () => {
    return fields.map((field, index) => (
      <div key={index} className="form-field">
        <label htmlFor="input-filed">{field.label}</label>
        {renderInput(field, index, field.label)}
      </div>
    ));
  }; 

  // Render input based on field type
  const renderInput = (field, index, label) => {
    switch (field.type) {
      case 'input-text':
        return (
          <input
            type="text"
            name={label}
            placeholder={field.placeholder || ''}
            className="form-control inputTextSize"
            // onChange={(event) => {
            //   handleInputChange(label, event.target.value, field.FieldAccess);
            //   SetCaseIDValue(event.target.value);
            // }}
          />
        );
      case 'input-number':
        return (
          <input
            type="number"
            name={label}
            placeholder={field.placeholder || ''}
            className="form-control inputTextSize"
            // onChange={(event) => handleInputChange(label, event.target.value, field.FieldAccess)}
          />
        );
      case 'input-image':
        return (
          <input
            type="file"
            name={label}
            placeholder={field.placeholder || ''}
            className="form-control"
            // onChange={(event) => {
            //   handleImageUpload(label, event, field.FieldAccess);
            // }}
          />
        );
      case 'input-audio':
        return (
          <input
            type="file"
            name={label}
            placeholder={field.placeholder || ''}
            className="form-control"
            // onChange={(event) => {
            //   handleImageUpload(label, event, field.FieldAccess);
            // }}
          />
        );

      case 'input-dropdown':
        return (
          <div key={index} className="form-field dropdown-container">
            <select
              className="form-control"
              name={label}
              placeholder={field.placeholder || ''}
              // onChange={(event) => handleInputChange(label, event.target.value, field.FieldAccess)}
            >
              <option>----Please select value----</option>
              {field.options?.map((option, optionIndex) => (
                <option key={optionIndex} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );

      case 'input-date':
        return (
          <input
            type="date"
            name={label}
            className="form-control inputTextSize"
            // onChange={(event) => handleInputChange(label, event.target.value, field.FieldAccess)}
          />
        );
      case 'input-radio':
        return (
          <FormControl component="fieldset" key={index}>
            <RadioGroup
              name={label}
              className="p-2 inputTextSize"
              // onChange={(event) => handleInputChange('input-dropdown', event.target.value, field.FieldAccess)}
            >
              {field.options?.map((option, optionIndex) => (
                <FormControlLabel key={optionIndex} value={option} control={<Radio />} label={<div>{option}</div>} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      default:
        return null;
    }
  };

  const formContainerClass = openPopup ? 'form-container animated shake' : 'form-container';
  return (
    <> 
    <div className='pb-4'>
          <Link to={`/dashboard/Case-forms/${type}`}>  
            <ArrowBackIcon />
          </Link>
        </div>
      <div className="form-builder  row align-items-center justify-content-center  ">
        <div className=" text-center">
          <h5>{lodedBankName}</h5>
        </div>
        <div className={`${formContainerClass} text-center w-75 p-5 mainFrom`}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              {/* {stepname && stepname === '1' ? (
                <>
                  <span>Case Id </span>
                  <input
                    type="text"
                    name="case_id"
                    className="form-control m-1"
                    onChange={(event) => {
                      handleInputChange('case_id', event.target.value, userRoles);
                      SetCaseIDValue(event.target.value);
                    }}
                  />
                </>
              ) : null} */}

              {renderFields()}
            </>
          )}
        </div>
      </div>
        <Dialog open={openPopup} onClose={handleClosePopup}>
          <DialogTitle>Data Saved</DialogTitle>
          <DialogContent>Your data has been saved successfully.</DialogContent>
          <DialogActions>
            <Button onClick={handleClosePopup} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      {/* <div className="d-flex  justify-content-around align-items-center">
        <button onClick={handleSaveData} disabled={caseIDValue.length === 0} className="btn btn-success mt-3">
          Save Data
        </button>
      </div> */}
    </>
  );
};

export default CaseFormViwer;
