import dayjs from "dayjs";
import imageCompression from 'browser-image-compression';

export function getToken() {
    return localStorage.getItem('token');
}
export function deleteToken() {
    localStorage.removeItem('token');
}

export function parseDate(dateString) {
    const formats = ['YYYY-MM-DD', 'MM/DD/YYYY', 'DD/MM/YYYY'];

    for (const format of formats) {
        const parsedDate = dayjs(dateString, { format });
        if (parsedDate.isValid()) {
            return parsedDate.toDate();
        }
    }

    // If none of the formats work, try to parse as 'DD/MM/YYYY'
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
        const parts = dateString.split('/');
        if (parts.length === 3) {
            const day = parts[0];
            const month = parts[1];
            const year = parts[2];
            const swappedDate = `${month}/${day}/${year}`;
            const parsedDate = dayjs(swappedDate, { format: 'DD/MM/YYYY' });
            if (parsedDate.isValid()) {
                return parsedDate.toDate();
            }
        }
    }

    return null;
}

export const compressImage = async (image) => {
    const options = {
        maxSizeMB: 1, // Max size in megabytes
        maxWidthOrHeight: 1920, // Max width or height of the image
        useWebWorker: true,
    };

    return await imageCompression(image, options);
};