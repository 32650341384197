import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import { Dialog, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { Link, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ReactWhatsapp from 'react-whatsapp';
import makeApiRequest from '../../api/commonApi';
import '../../assets/css/form.css';
import { parseDate } from '../../utils/commonFunction';
import { useSelector } from 'react-redux';

const RequestDetails = () => {

  const { loanId } = useParams();
  const [formData, setFieldData] = useState([]);

  const [fields, setFields] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [whatsappData, setWhatsappData] = useState({});
  const [reportImages, setReportImages] = useState([]);
  const [imageDialogs, setImageDialogs] = useState([]);
  const [reportImagesDialogs, setReportImagesDialogs] = useState([]);
  // const [dataToBeSent, setDataToBeSent] = useState('');
  const [TAT, setTAT] = useState('');
  const { userInfo } = useSelector((state) => state.user);

  const handleFetchFormData = async () => {
    try {
      setIsLoading(true);
      const response = await makeApiRequest('get', `/formdata/${loanId}`);
      const { data, TAT } = response.data;
      setTAT(TAT);
      const columns = Object.keys(data[0]).filter((fieldName) => fieldName !== 'FieldAccess');
      setFieldData(data);
      setFields(columns);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatedFormData = formData.map((item) => {
    if (item.fieldname?.includes('STRINGTOBEREMOVE')) {
      item.fieldname = item.fieldname.replace('STRINGTOBEREMOVE', '');
    }
    return item;
  });

  useEffect(() => {
    handleFetchFormData();
    setFieldData(updatedFormData);
  }, []);


  const [caseStatus, setcaseStatus] = useState('');

  useEffect(() => {
    formData.forEach((item) => {
      if (item?.fieldname === 'status') {
        setcaseStatus(item.value);
      }
      if (item?.fieldname === 'Case Received Date') {
        setDayvalue(item.value);
      }
    });
  }, [formData]);

  const setDayvalue = (value) => {
    const caseStatusField = formData?.find((field) => field.fieldname === 'status' && field.value === 'closed');
    if (!caseStatusField) {
      const currentDate = new Date();
      const caseReceivedDate = parseDate(value);
      let daysDifference = 'N/A'
      if (caseReceivedDate instanceof Date && !isNaN(caseReceivedDate)) {
        const timeDifferenceMs = currentDate - caseReceivedDate;
        daysDifference = Math.floor(timeDifferenceMs / (1000 * 3600 * 24));
      }
      setTAT(daysDifference);
    }
  };


  // useEffect(() => {
  //   const fieldsToInclude = [
  //     'case_id',
  //     'Visit Type',
  //     'Applicant Name',
  //     'Product',
  //     'Company Name',
  //     'Business Name',
  //     'Business Contact Number',
  //     'Business Address',
  //     'Applicant Contact Number',
  //   ];

  //   const data = fieldsToInclude.map((fieldName) => `${fieldName} - ${whatsappData[fieldName]}`).join('\n');

  //   setDataToBeSent(data);
  // }, [whatsappData]);



  const toggleImageDialog = (rowIndex) => {
    const newImageDialogs = [...imageDialogs];
    newImageDialogs[rowIndex] = !newImageDialogs[rowIndex];
    setImageDialogs(newImageDialogs);
  };

  const toggleReportImagesDialog = (rowIndex, images) => {
    setReportImages(images);
    const newReportImagesDialogs = [...reportImagesDialogs];
    newReportImagesDialogs[rowIndex] = !newReportImagesDialogs[rowIndex];
    setReportImagesDialogs(newReportImagesDialogs);
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-around px-1 m-2">
          <div className="me-auto">
            <Link to={`/dashboard/allrequest/all`}>
              <ArrowBackIcon />
            </Link>
          </div>
          {caseStatus === 'received' && userInfo.role.role !== 'rcu-manager' && (
            <Link to={`/dashboard/subtypeformlist/${loanId}`}>
              <Button variant="contained">Case Initiate</Button>
            </Link>
          )}
          {caseStatus === 'reopened' && userInfo.role.role !== 'rcu-manager' && (
            <Link to={`/dashboard/subtypeformlist/${loanId}`}>
              <Button variant="contained">Case Reinitiate</Button>
            </Link>
          )}
          {(caseStatus === 'verified' || caseStatus === 'reverified') && (
            <Link to={`/dashboard/reportgenerate/${loanId}`}>
              <Button className="mx-2" variant="contained">
                Report
              </Button>
            </Link>
          )}
          {/* {(caseStatus === 'initiated' || caseStatus === 'reinitiated') && VerifierName && (
            <ReactWhatsapp number={verifierMobileNumber} message={dataToBeSent} style={{ border: 'none' }}>
              <Button>
                Share details via
                <WhatsAppIcon className="ms-1" />
              </Button>
            </ReactWhatsapp>
          )} */}
        </div>
        {TAT !== '' && <div className="text-center text-success fw-bold mb-2">TAT - {TAT}</div>}
        <div className="text-center">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper} className="TableParent text-center">
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableRow className="TableheadStyle">
                  <TableCell className="HeadCellStyle">Field Name</TableCell>
                  <TableCell className="HeadCellStyle">Value</TableCell>
                </TableRow>
                {formData.map((item, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {fields.map((fieldName) => (
                      <TableCell key={fieldName} className="textCentre">
                        {item[fieldName]?.includes('cloudinary') ? (
                          <>
                            {item[fieldName]?.includes('image') ? (
                              <>
                                <button
                                  type="button"
                                  className="btn firstbtncle"
                                  onClick={() => toggleImageDialog(rowIndex)}
                                >
                                  View Image
                                </button>
                                <Dialog
                                  className="dialog-w-100"
                                  open={imageDialogs[rowIndex]}
                                  onClose={() => toggleImageDialog(rowIndex)}
                                  fullWidth
                                  maxWidth="md"
                                >
                                  <DialogContent>
                                    <img src={item[fieldName]} alt="images" className="img-fluid" />
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={() => toggleImageDialog(rowIndex)} color="primary">
                                      Close
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </>
                            ) : item[fieldName]?.includes('video') ? (
                              <>
                                <button
                                  type="button"
                                  className="btn firstbtncle"
                                  onClick={() => toggleImageDialog(rowIndex)}
                                >
                                  View Media
                                </button>
                                <Dialog
                                  className="dialog-w-100"
                                  open={imageDialogs[rowIndex]}
                                  onClose={() => toggleImageDialog(rowIndex)}
                                  fullWidth
                                  maxWidth="md"
                                >
                                  <DialogContent className="text-center">
                                    <video controls width="90%">
                                      <source src={item[fieldName]} type="video/mp4" />
                                      <track kind="captions" src="" label="English" />{' '}
                                    </video>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={() => toggleImageDialog(rowIndex)} color="primary">
                                      Close
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </>
                            ) : (
                              item[fieldName]
                            )}
                          </>
                        ) : item.fieldname === 'Case Received By' ? (
                          <>
                            {fieldName === 'fieldname' ? item[fieldName] : `${item?.userData?.name}`}
                          </>
                        ) : item.fieldname?.includes('verifier') ? (
                          <>
                            {item.casename && fieldName === 'fieldname' ? `${item[fieldName].replace('verifier', ' Verifier')} - ${item.casename} (${item.casetype})` : `${item?.userData?.name} - ${item.casename} (${item.casetype})`}
                          </>
                        ) : item.fieldname === "ReportImages" ? (
                          <>
                            {fieldName === 'fieldname' ? `Report Images` : (
                              <button type="button" className="btn firstbtncle" onClick={() => toggleReportImagesDialog(rowIndex, item[fieldName])}>
                                View Images
                              </button>
                            )}

                          </>
                        ) : (
                          item.casename
                            ? (typeof item[fieldName] === 'string' && item[fieldName] !== 'ReportImages') && item[fieldName]
                              .split(' ')
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                              .join(' ')
                            + (item[fieldName] ? ` - ${item.casename} (${item.casetype})` : '')
                            : (typeof item[fieldName] === 'string' && item[fieldName] !== 'ReportImages') && item[fieldName]
                              .split(' ')
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                              .join(' ') + (item.verificationRemark && fieldName === 'value' && item.fieldname.trim().toLowerCase() !== "over all rcu status" && item.fieldname.trim().toLowerCase() !== "case close date" ? ` ( ${item.verificationRemark})` : '')
                        )}
                      </TableCell>
                    ))}
                    <Dialog
                      className="dialog-w-100"
                      open={reportImagesDialogs[rowIndex]}
                      onClose={() => toggleReportImagesDialog(rowIndex, [])}
                      fullWidth
                      maxWidth="md"
                    >
                      <DialogContent style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {reportImages.map((imageUrl, index) => (
                          <img key={index} src={imageUrl} alt={`Report Image ${index + 1}`} className="img-fluid mt-3" style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' }}/>
                        ))}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => toggleReportImagesDialog(rowIndex, [])} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableRow>
                ))}

              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestDetails;
