import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

// routes
import Router from "./routes"
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from './pages/LoginPage';
import { fetchUserInfo } from './redux/user/userSlice';
import ForgotForm from './sections/auth/login/ForgetForm';
import { LoginForm } from './sections/auth/login';
import Otpverify from './sections/auth/login/Otpverify';
import ResetPassword from './sections/auth/login/ResetPassword';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const { userInfo, loading } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch])

  // if (loading) {
  //   return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', margin: 'auto' }}>
  //     <CircularProgress size={50} />
  //   </div>)
  // }

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Adjust the height to your needs
              }}
            >
              <CircularProgress size={50} />
            </div>
          ) : // Display Router or LoginPage based on user info
            userInfo ? (
              <Router />
            ) : (
              
              <Routes>
                <Route path="/" element={<LoginPage />} >
                  <Route index element={<LoginForm />} />
                  <Route path='forgotpassword' element={<ForgotForm />} />
                  <Route path='verifyotp' element={<Otpverify />} />
                  <Route path='resetpassword' element={<ResetPassword />} />
                  <Route path='*' element={<Navigate to="/" />} />

                </Route>
              </Routes>

            )}
        </ThemeProvider>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </BrowserRouter>
    </HelmetProvider>
  );
}