import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { toast } from 'react-toastify'
import { TextField, Stack, Typography } from '@mui/material'
import makeApiRequest from '../../../api/commonApi';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../redux/user/userSlice';

const Otpverify = () => {
  const [otp, setOtp] = useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const verifyotp = async () => {
    try {
      const Response = await makeApiRequest('post', '/auth/otpVerify', { otp: otp });

      // const Response = await axios.post(`http://localhost:5001/auth/otpVerify`, { otp: otp }
      if (Response.status == '200') {
        // console.log(Response.userId)
        toast.success(Response.data.message)
        dispatch(resetPassword(Response.data.userId))
        navigate(`/resetpassword`)
      }

    } catch (error) {
      if (error.response && [401, 400, 404].includes(error.response.status)) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.message);
      }
    }

  }

  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h4" gutterBottom sx={{ my: 3 }}>
          Verify otp
        </Typography>
        <TextField
          name="Otp"
          label="Otp"
          onChange={(e) => setOtp(e.target.value)}

          variant='standard'
        />
      </Stack>
      <LoadingButton className='mt-4' fullWidth size="large" type="submit" variant="contained" onClick={verifyotp} >
        Verify Otp
      </LoadingButton >
    </>
  )
}

export default Otpverify