import React, { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { MenuItem, IconButton, Stack, Typography, TablePagination, CircularProgress,  Grid, TextField} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate } from 'react-router-dom';
import makeApiRequest from '../api/commonApi';
import Iconify from '../components/iconify';
import '../assets/css/table.css';

const Forms = () => {
  const history = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [savedForms, setSavedForms] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bankLength, setBankLength] = useState(0);
  const [formToDelete, setFormToDelete] = useState('');
  const [openPopoverMap, setOpenPopoverMap] = useState({});
  const [Search, SetSearch] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const fetchSavedForms = async () => {
      try {
        const response = await makeApiRequest('get', `/bankformlayout?page=${page + 1}&searchTerm=${Search}&limit=${rowsPerPage}`);
        if (response.status === 200) {
          const data = response?.data?.data;
          setSavedForms(data);
          setBankLength(response?.data?.totalFormLayouts);
        } else {
          console.error('Failed to fetch saved forms');
        }
      } catch (error) {
        console.error('Error fetching saved forms:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSavedForms();
  }, [page, Search, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const calculateSerialNumber = (index) => {
    return page * rowsPerPage + index + 1;
  };

  const handelDeleteForm = (formId) => {
    setFormToDelete(formId);
    openDialog();
  };
  const handelUpdateForm = (formId) => {
    history(`/dashboard/update-form/${formId}`);
  };

  const handleConfirmDelete = async () => {
    closeDialog();
    try {
      const response = await makeApiRequest('delete', `/bankformlayout/${formToDelete}`);
      if (response.status === 200) {
        // Remove the deleted form from the savedForms array
        setSavedForms(savedForms.filter((form) => form._id !== formToDelete));
        // window.alert('Form deleted successfully');
        toast.success('Form deleted successfully!');
      }
    } catch (error) {
      // window.alert('Form deletion failed', error);
      toast.error(error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [showField, SetShowfield] = useState(false);

  useEffect(() => {
    const userDetails = async () => {
      try { 
        const MyDetails = await makeApiRequest('get', '/me');
        if (MyDetails.status === 200) {
          const Role = MyDetails.data.user.role.role;
          if (Role === 'admin' || Role === 'super-admin') {
            SetShowfield(true); 
          }
        } else {
          console.error('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching layouts:', error);
      }
    };
    userDetails();
  }, []);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenMenu = (event, index) => {
    setOpenPopoverMap({ ...openPopoverMap, [index]: event.currentTarget });
  };

  const handleCloseMenu = (index) => {
    setOpenPopoverMap({ ...openPopoverMap, [index]: null });
  };

  return (
    <>
      <div>
        <Dialog open={dialogOpen} onClose={closeDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this form?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Forms
          </Typography>
          {showField && (
            <Link to="/dashboard/add-form">
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                New Form
              </Button>
            </Link>
          )}
        </Stack>
        <Grid className="mb-3">
          <TextField
            type="search"
            autoComplete="off"
            id="search"
            label="Search"
            value={Search}
            onChange={(e) => {
              setPage(0);
              SetSearch(e.target.value)
            }}
          />
        </Grid>
        <div className="text-center">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow className="TableheadStyle">
                    <TableCell className="HeadCellStyle">S.No</TableCell>
                    <TableCell className="HeadCellStyle">Bank Name</TableCell>
                    <TableCell className="HeadCellStyle">Total Field</TableCell>
                    {showField && <TableCell className="HeadCellStyle">Action</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {savedForms.map((layout, index) => (
                    <TableRow className="TableRowStyle" key={layout._id}>
                      <TableCell component="th" scope="row" className="textCentre">
                        {calculateSerialNumber(index)}
                      </TableCell>
                      <TableCell className="textCentre">
                        {layout.bankId ? (
                          // <Link to={`/dashboard/view-form/${layout._id}`} style={{ textDecoration: 'none', color: "black" }} >{layout.bankId.bankname}</Link>
                          <Link to={`/dashboard/view-form/${layout._id}`}>{layout.bankId.bankname}</Link>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>

                      <TableCell className="textCentre">{layout.totalInputFields + 3}</TableCell>

                      {showField && (
                        <TableCell className="textCentre">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, index)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>

                          <Popover
                            open={Boolean(openPopoverMap[index])}
                            anchorEl={openPopoverMap[index]}
                            onClose={() => handleCloseMenu(index)}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            <MenuItem onClick={() => handelUpdateForm(layout._id)}>
                              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                              Edit
                            </MenuItem>
                            <MenuItem sx={{ color: 'error.main' }} onClick={() => handelDeleteForm(layout._id)}>
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>
                          </Popover>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}

                  {/* To show message if data is not presnet there any  */}
                  {savedForms.length === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={6} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}

                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"  
          count={bankLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
          labelRowsPerPage="Rows per page"
          // labelPagination="Page"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer /> */}
          <TablePagination
          rowsPerPageOptions={[5, 10, 15,25]}
          component="div"
          count={bankLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default Forms;
