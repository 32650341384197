import React, { useState, useEffect } from 'react';
import { Button, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import CloseIcon from '@mui/icons-material/Close';
import makeApiRequest from '../../api/commonApi';
import Iconify from '../iconify/Iconify';
import Loader from '../Loader';

const EditProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    address: '',
    city: '',
    mobilenumber: '',
  });

  const [validationError, setValidationError] = useState(false);

  const isUserNameVaid = (name) =>{
    const userNameRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/
    return userNameRegex.test(name)
  }

  const isAddressVaid = (address) =>{
    const addressRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/
    return addressRegex.test(address)
  }

  const isCityVaid = (city) =>{
    const cityRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/
    return cityRegex.test(city)
  }

  useEffect(() => {
    const getAllDetailsById = async () => {
      try {
        setIsLoading(true);
        const details = await makeApiRequest('get', `/userbyId/${id}`);
        setUserData(details.data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getAllDetailsById();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const backtoMain = () => {
    navigate('/dashboard/user');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!isUserNameVaid(userData.name)){
      toast.error('Please enter a valid name and do not enter unwanted space.');
      return;
    }
    
    if(!isAddressVaid(userData.address)){
      toast.error('Please enter a valid address and do not enter unwanted space.');
      return;
    }

    if(!isCityVaid(userData.city)){
      toast.error('Please enter a valid city and do not enter unwanted space.');
      return;
    }

    if (!/^\d{10}$/.test(userData.mobilenumber)) {
      setValidationError(true);
      return;
    }


    try {
      setIsLoading2(true);
      await makeApiRequest('put', `/updateusers/${id}`, userData);
      console.log('Profile Updated successfully!');
      toast.success('Details Updated  successfully!');
      backtoMain();
    } catch (error) {
      console.log(error);
      console.log('Something gone wrong!');
    } finally {
      setIsLoading2(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> EditUser | Quick Support </title>
      </Helmet>
      {isLoading ? (
        <div className=" d-flex justify-content-center align-items-center ">
          <Loader />
        </div>
      ) : (
        <Grid className="p-3">
          <Paper elevation={20} className="paperStyle">
            <div className="CrossIconParent">
              <CloseIcon onClick={backtoMain} className="iconCross" />
            </div>
            <Grid align="center">
              <h2 className="headerStyle">Edit User Details</h2>
            </Grid>

            <div className="container">
              <form className="row mb-3" onSubmit={handleSubmit}>
                <Grid>
                  <TextField
                    label="Name"
                    variant="standard"
                    value={userData.name}
                    fullWidth
                    type="text"
                    name="name"
                    autoComplete="off"
                    className="my-2"
                    onChange={handleInputChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid>
                  <TextField
                    label="Address"
                    variant="standard"
                    value={userData.address}
                    fullWidth
                    type="text"
                    name="address"
                    autoComplete="off"
                    className="my-2"
                    onChange={handleInputChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid>
                  <TextField
                    label="City"
                    variant="standard"
                    value={userData.city}
                    fullWidth
                    type="text"
                    name="city"
                    autoComplete="off"
                    className="my-2"
                    onChange={handleInputChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid>
                  <TextField
                    label="Number"
                    variant="standard"
                    value={userData.mobilenumber || ''}
                    fullWidth
                    type="text"
                    name="mobilenumber"
                    autoComplete="off"
                    className="my-2"
                    required
                    onChange={handleInputChange}
                    error={validationError}
                    helperText={validationError ? 'Mobile number must be 10 digits' : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <div className="text-center my-2 mt-4">
                  {isLoading2 ? (
                    <div className=" d-flex justify-content-center align-items-center ">
                      <Loader />
                    </div>
                  ) : (
                    <Button variant="contained" type="submit" className="m-2">
                      Update
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default EditProfile;