import React, { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { MenuItem, IconButton, Stack, Typography, TablePagination, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import makeApiRequest from '../api/commonApi';
import Iconify from '../components/iconify';
import '../assets/css/table.css';

function createData(name, steps, bank, entry, protein) {
  return { name, steps, bank, entry, protein };
}


const CaseFormsList = () => {
  const { type } = useParams();
  const history = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [savedForms, setSavedForms] = useState([]); 
  const [savedFormsLength, setSavedFormsLength] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formToDelete, setFormToDelete] = useState('');
  const [openPopoverMap, setOpenPopoverMap] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  
  const fetchSavedForms = async (page) => {
    setIsLoading(true);
    try {
      const response = await makeApiRequest('get',`/${type}?page=${page + 1}&limit=${rowsPerPage}`);
      if (response.status === 200) {
        const data = response.data.data;
        setSavedFormsLength(response.data.totalFormLayouts);
        setSavedForms(data);
      } else {
        console.error('Failed to fetch saved forms');
      }
    } catch (error) {
      console.error('Error fetching saved forms:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedForms(page);    /* eslint-disable */
  }, [page, rowsPerPage]);

  const handelDeleteForm = (formId) => {
    setFormToDelete(formId); // Store the form ID
    openDialog(); // Open the dialog
  };
  const handelUpdateForm = (formId) => {
    history(`/dashboard/update-case-form/${type}/${formId}`);
  };

  const handleConfirmDelete = async () => {
    closeDialog();
    try {
      const response = await makeApiRequest('delete',`/${type}/${formToDelete}`);
      if (response.status === 200) {
        setSavedForms(savedForms.filter((form) => form._id !== formToDelete));
        toast.success('Form deleted successfully!');
      }
    } catch (error) {
      // window.alert('Form deletion failed', error);
      toast.error(error);

    }
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const SendToCreateForm = `/dashboard/caseform/${type}`;

  const handleOpenMenu = (event, index) => {
    // Update the open state for the popover of the specific row
    setOpenPopoverMap({ ...openPopoverMap, [index]: event.currentTarget });
  };

  const handleCloseMenu = (index) => {
    // Update the open state for the popover of the specific row to close
    setOpenPopoverMap({ ...openPopoverMap, [index]: null });
  };

  return (
    <>
      <div>
        <Dialog open={dialogOpen} onClose={closeDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this form?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {type === 'case-profile-formlayout' ? (
            <Typography variant="h4" gutterBottom>
              Profile Subtypes
            </Typography>
          ) : (
            <div>
              <Typography variant="h4" gutterBottom>
                Document Subtypes
              </Typography>
            </div>
          )}


          <Link to={SendToCreateForm}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Form
            </Button>
          </Link>
        </Stack>
        <div className="p-2">
          <Link to={`/dashboard/type`}>
            <ArrowBackIcon />
          </Link>
        </div>
        <div className="text-center d- justify-content-center align-items-center">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow className="TableheadStyle">
                    <TableCell className="HeadCellStyle">S.No</TableCell>

                    <TableCell className="HeadCellStyle">Subtype Name</TableCell>
                    <TableCell className="HeadCellStyle">Total Field</TableCell>
                    <TableCell className="HeadCellStyle">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {savedForms?.map((layout, index) => (
                    <TableRow className="TableRowStyle" key={layout._id}>
                      <TableCell component="th" scope="row" className="textCentre">
                        {index + 1}
                      </TableCell>
                      <TableCell className="textCentre">
                        {layout.caseId ? (
                          <Link to={`/dashboard/${type}/Case-view-form/${layout._id}`}>{layout.caseId.casename}</Link>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>

                      <TableCell className="textCentre">{layout.totalInputFields}</TableCell>
                      <TableCell className="textCentre">
                        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, index)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>

                        <Popover
                          // open={Boolean(open)}
                          // anchorEl={open}
                          // onClose={handleCloseMenu}
                          open={Boolean(openPopoverMap[index])}
                          anchorEl={openPopoverMap[index]}
                          onClose={() => handleCloseMenu(index)}
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              width: 140,
                              '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                              },
                            },
                          }}
                        >
                          <MenuItem onClick={() => handelUpdateForm(layout._id)}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                          </MenuItem>
                          <MenuItem sx={{ color: 'error.main' }} onClick={() => handelDeleteForm(layout._id)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            Delete
                          </MenuItem>
                        </Popover>
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={savedFormsLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default CaseFormsList;